import { Stack } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

export function FeedbackColumn({ children }: PWC) {
    return (
        <Stack gap={1} flexGrow={1} flexShrink={0} flexBasis={0}>
            {children}
        </Stack>
    );
}
