import { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Divider, Tab, Tabs } from '@customink/pigment-react';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { SharingDialogInvite } from './SharingDialogInvite';
import { SharingDialogAsCopy } from './SharingDialogAsCopy';
import { ButtonsSendEmailSharing } from './components/ButtonsSendEmailSharing';
import { ButtonsSendCopy } from './components/ButtonsSendCopy';
import { SharingState } from './types';

interface SharingDialogProps {
    designName: string;
    open: boolean;
    onClose: EmptyFunction;
}

type SharingDialogTab = 'invite' | 'copy';

const initialSharingState: SharingState = {
    emailPermission: 'comment',
    linkPermission: 'comment',
    emailRecipients: [],
    emailInputValue: '',
    emailMessage: 'Hey! Please help me review this design and leave feedback. Thank you!',
    copyMessage: "Hey! I'm sharing a copy of my design with you.",
    emailInputErrorMessage: '',
    copyInputErrorMessage: '',
};

export function SharingDialog({ designName, open, onClose }: SharingDialogProps) {
    const [activeTab, setActiveTab] = useState<SharingDialogTab>('invite');
    const [sharingState, setSharingState] = useState<SharingState>(initialSharingState);
    const amendState = useCallback(
        (changes: Partial<SharingState>) => setSharingState((prev) => ({ ...prev, ...changes })),
        [],
    );

    const isInviteTab = activeTab === 'invite';
    const isCopyTab = activeTab === 'copy';

    const isEmailFieldEmpty = sharingState.emailRecipients.length === 0 && sharingState.emailInputValue.length === 0;

    return (
        <Dialog title={`Share ${designName}`} open={open} onClose={onClose} mobileVariant="fullScreen">
            <DialogContent>
                <Tabs
                    variant="scrollable"
                    allowScrollButtonsMobile
                    value={activeTab}
                    onChange={(_, newValue: SharingDialogTab) => setActiveTab(newValue)}
                >
                    <Tab label="Share" value="invite" />
                    <Tab label="Share a Copy" value="copy" />
                </Tabs>
                <Divider sx={{ mb: 3 }} />
                {isInviteTab && <SharingDialogInvite sharingState={sharingState} amendState={amendState} />}
                {isCopyTab && <SharingDialogAsCopy sharingState={sharingState} amendState={amendState} />}
            </DialogContent>
            <DialogActions>
                {isCopyTab && <ButtonsSendCopy sharingState={sharingState} amendState={amendState} onClose={onClose} />}
                {isInviteTab && isEmailFieldEmpty && <Button onClick={onClose}>Done</Button>}
                {isInviteTab && !isEmailFieldEmpty && (
                    <ButtonsSendEmailSharing sharingState={sharingState} amendState={amendState} onClose={onClose} />
                )}
            </DialogActions>
        </Dialog>
    );
}
