import { useHasPassword } from '~/adapters/services/profiles/profile/hooks';
import { Loader } from '~/components/Loader/loader';
import { Box, Button, Dialog, DialogContent } from '@customink/pigment-react';
import { LabelValueRow } from '~/pages/Settings/components/LabelValueTable';
import { EditPassword } from '~/pages/Settings/tabs/Profile/EditPassword';
import { useCallback, useState } from 'react';
import { sendPasswordModalEvent } from '~/pages/Settings/tabs/Profile/profileTracking';
import { pushSuccessNotification } from '~/adapters/notistack/notistack';

export function PasswordChangeProfiles() {
    const { data: hasPassword, isLoading: isProfilesDataLoading } = useHasPassword();
    const [dialogPassword, setDialogPassword] = useState(false);

    const openEditPassword = useCallback(() => {
        sendPasswordModalEvent('Open');
        setDialogPassword(true);
    }, []);
    const closeEditPassword = useCallback(() => {
        sendPasswordModalEvent('Close');
        setDialogPassword(false);
    }, []);

    const markEdited = useCallback(() => {
        setDialogPassword(false);
        pushSuccessNotification('Your password has been changed.');
    }, []);

    if (isProfilesDataLoading) {
        return <Loader title="Loading..." devLabel="Profiles User detail API" />;
    }

    if (hasPassword === undefined) {
        return <Box mb={3}>No User Profile has been found for this Account.</Box>;
    }

    return (
        <>
            <LabelValueRow
                label="Password"
                value={hasPassword ? '*************' : 'Set a password for your account'}
                action={
                    <Button onClick={openEditPassword} variant="secondary">
                        {hasPassword ? 'Change Password' : 'Create Password'}
                    </Button>
                }
            />
            <Dialog
                open={dialogPassword}
                onClose={closeEditPassword}
                title={hasPassword ? 'Change Password' : 'Create Password'}
                width="sm"
                mobileVariant="fullScreen"
            >
                <DialogContent sx={{ display: 'flex', pt: 2 }}>
                    <EditPassword onEdit={markEdited} onClose={closeEditPassword} />
                </DialogContent>
            </Dialog>
        </>
    );
}
