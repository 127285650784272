import { useMutation, useQuery } from '@tanstack/react-query';
import { useAdminRepository } from './rest';

export const ImpersonatedUserKey = 'impersonated-user';

export function useImpersonatedUserDetail(email: string) {
    const { getImpersonatedUserDetail } = useAdminRepository();

    return useQuery({
        queryKey: [ImpersonatedUserKey],
        queryFn: () => getImpersonatedUserDetail({ email }),
    });
}

export function useCopyPasswordReset() {
    const { copyResetPasswordLink } = useAdminRepository();

    return useMutation({
        mutationFn: (id: number) => copyResetPasswordLink({ id }),
    });
}

export function useSendPasswordReset() {
    const { sendResetPasswordLink } = useAdminRepository();

    return useMutation({
        mutationFn: (id: number) => sendResetPasswordLink({ id }),
    });
}
