import { useAuth } from '~/contexts/Auth/AuthContext';
import { AdminProfile } from '~/pages/Settings/tabs/Profile/AdminProfile';
import { UserProfile } from '~/pages/Settings/tabs/Profile/UserProfile';
import { usePage } from '~/adapters/browser/usePage';
import { Title } from '~/pages/Settings/title';

export function ProfileTab() {
    const { isImpersonatedExperience } = useAuth();
    usePage({ title: Title, reportPath: '/accounts/profile' });

    return isImpersonatedExperience ? <AdminProfile /> : <UserProfile />;
}
