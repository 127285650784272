import { Box, Carousel } from '@customink/pigment-react';
import { CarouselProductImage } from '~/components/Image/CarouselProductImage';

interface FeedbackPreviewProps {
    previews: string[];
}
export function FeedbackPreview({ previews }: FeedbackPreviewProps) {
    const slides = previews.map((uri) => (
        <CarouselProductImage alt="Product preview" src={uri} key={uri} initialAspectRatio="0.85 / 1" />
    ));

    return (
        <Box sx={{ flexGrow: 1, overflow: 'hidden', img: { borderRadius: 2 } }}>
            <Carousel slides={slides} sliderHeight="auto" loop={false} data-testid="feedback carousel" />
        </Box>
    );
}
