import Rollbar from 'rollbar';
import { Config } from '~/adapters/config/config';

const environment = import.meta.env.MODE;
const enabled = environment === 'staging' || environment === 'production';
const viteRelease = Config.VITE_RELEASE;

export const rollbarConfig: Rollbar.Configuration = {
    accessToken: import.meta.env.ACCOUNTS_ROLLBAR_ACCES_KEY,
    autoInstrument: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled,
    environment,
    hostSafeList: [environment === 'staging' ? 'www-master.staging.customink.com' : 'customink.com'],
    payload: {
        client: {
            javascript: {
                code_version: viteRelease,
                guess_uncaught_frames: true,
                source_map_enabled: true,
            },
        },
        code_version: viteRelease,
        environment,
    },
};

const rollbarInstance = new Rollbar(rollbarConfig);

/**
 * Logs an error in the development console when Rollbar is disabled
 *
 */
const getProxy = () => {
    const methods = ['critical', 'debug', 'error', 'info', 'log', 'warn', 'warning'] as const;

    const handler = {
        get(_target: any, logMethod: (typeof methods)[number]) {
            if (!methods.includes(logMethod)) {
                return undefined;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (message?: string, details?: any) => {
                const options = [`%c rollbar ${logMethod}`, 'color: gray; font-weight: lighter;', message];
                if (details) {
                    options.push(details);
                }
                console.log(...options); // eslint-disable-line no-console
            };
        },
    };

    return new Proxy(Rollbar, handler);
};

export const rollbar = enabled ? rollbarInstance : getProxy();
