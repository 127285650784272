import React from 'react';
import { MenuItem as PigmentMenuItem } from '@mui/material';

/**
 * MenuItem wrapper that ensures typechecking of `value` passed as prop. <br />
 * T must be subset of string.
 * Expected use:
 *
 * ```tsx
 * const MyTypedMenuItem = TypedMenuItem<Constraint>;
 * <MyTypedMenuItem value="this string can only be of type Constraint" />
 * ```
 * @param props
 * @constructor
 */
export function TypedMenuItem<T extends string>(props: React.ComponentProps<typeof PigmentMenuItem> & { value: T }) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <PigmentMenuItem {...props} />;
}
