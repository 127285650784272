import { Alert, AlertTitle, Container } from '@customink/pigment-react';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { FlagsTable } from './FlagsTable';

export function SignalerFlags() {
    return (
        <Container maxWidth="xl">
            <Alert severity="info" sx={{ mb: 4 }}>
                <AlertTitle>Signaler</AlertTitle>
                You can modify the feature flags by using the buttons below. To reset a feature flag, please manually
                delete the corresponding cookie through your browser’s developer tools.
            </Alert>

            <ErrorBoundary errorMessage="An error occurred while retrieving your signaler feature flags">
                <FlagsTable />
            </ErrorBoundary>
        </Container>
    );
}
