import React, { useCallback, useState } from 'react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { ArrowDownIcon, Button } from '@customink/pigment-react';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import { MenuItem } from './MenuItem';
import MobileNavigationModal from './MobileNavigationModal';

interface MobileNavigationProps {
    menu: Array<MenuItem>;
}

const SelectStyledButton = styled(Button)(() => ({
    color: designTokens.color.gray.slateDefault.value,
    backgroundColor: 'transparent',
    '&:focus-visible': {
        color: designTokens.color.gray.slateDefault.value,
        // TODO remove constant https://customink.monday.com/boards/5878886849/views/128588597/pulses/6382144319
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:active': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    border: '1px solid',
    borderColor: designTokens.color.gray.limestone.value,
    fontWeight: 400,
}));

const MobileNavigation: React.FC<MobileNavigationProps> = ({ menu }) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const showDialog = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const hideDialog = useCallback(() => {
        setDialogOpen(false);
    }, []);

    return (
        <>
            <SelectStyledButton
                sx={{ flexGrow: 1, justifyContent: 'space-between' }}
                variant="link"
                endIcon={<ArrowDownIcon />}
                onClick={showDialog}
                fullWidth
            >
                {menu.find((m) => m.selected)?.label}
            </SelectStyledButton>
            <MobileNavigationModal menu={menu} open={dialogOpen} onClose={hideDialog} />
        </>
    );
};

export default MobileNavigation;
