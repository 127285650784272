import type { NavLinkProps as OriginalNavLinkProps } from 'react-router-dom';
import { NavLink as OriginalNavLink } from 'react-router-dom';
import { usePreservedQueryParamLink } from '~/adapters/browser/usePreservedQueryParamLink';

type LinkProps = OriginalNavLinkProps & {
    to: string;
};

export function NavLink({ to, ...rest }: LinkProps) {
    const newTo = usePreservedQueryParamLink(to);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <OriginalNavLink to={newTo} {...rest} />;
}
