import { FC, forwardRef } from 'react';
import { CustomContentProps, SnackbarContent, SnackbarProvider } from 'notistack';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Alert } from '@mui/material';
import { NotificationVariant } from './notistack';

const SnackBarAlert = styled(Alert)(({ theme }) => ({
    width: 450,
    borderRadius: theme.shape.borderRadius,
    borderWidth: 2,
}));

type AlertSnackbarContentProps = CustomContentProps & {
    // variant exists on CustomContentProps, but we want to narrow it down
    variant: NotificationVariant;
};

const AlertSnackbarContent = forwardRef<HTMLDivElement, AlertSnackbarContentProps>(({ message, variant }, ref) => (
    <SnackbarContent ref={ref} role="alert">
        <SnackBarAlert severity={variant} variant="outlined">
            {message}
        </SnackBarAlert>
    </SnackbarContent>
));

export const NotistackProvider: FC = () => (
    <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        Components={{
            success: AlertSnackbarContent,
            error: AlertSnackbarContent,
            info: AlertSnackbarContent,
            warning: AlertSnackbarContent,
        }}
    />
);
