import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { EnabledQueryOption } from '~/adapters/TanStackQuery/types';
import { GetOrderProps, GetOrdersProps, useOrdersRepository } from './rest';

export const useInfiniteOrders = (props: GetOrdersProps) => {
    const { getOrdersForAccount } = useOrdersRepository();

    return useInfiniteQuery({
        queryKey: ['infinite-orders', props],
        queryFn: ({ pageParam }) => getOrdersForAccount({ pageParam, ...props }),
        initialPageParam: 1,
        getNextPageParam: (lastData) => (lastData.hasMorePages ? lastData.currentPage + 1 : undefined),
        placeholderData: keepPreviousData,
    });
};

export const useOrderDetails = (props: GetOrderProps, { enabled }: EnabledQueryOption = {}) => {
    const { getOrderDetails } = useOrdersRepository();

    return useQuery({
        queryKey: ['order', props],
        queryFn: () => getOrderDetails(props),
        staleTime: 50000,
        enabled,
    });
};
