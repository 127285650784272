export interface MoneyProps {
    value: number;
}

function moneyFormat(price: number): string {
    return `$${parseFloat(`${price}`).toFixed(2)}`;
}

export function Money({ value }: MoneyProps) {
    return <>{moneyFormat(value)}</>;
}
