import { useCallback } from 'react';
import { Alert, Box, Button } from '@customink/pigment-react';
import { pushApiErrorNotification, pushSuccessNotification } from '~/adapters/notistack/notistack';
import {
    useCopyPasswordReset,
    useImpersonatedUserDetail,
    useSendPasswordReset,
} from '~/adapters/services/profiles/admin/hooks';
import { useAuthUser } from '~/contexts/Auth/AuthContext';
import { copyToClipboard } from '~/adapters/browser/clipboard/copyToClipboard';
import { Loader } from '~/components/Loader/loader';
import { LabelValueRow } from '../../components/LabelValueTable';

export const PasswordChangeLinks = () => {
    const { email } = useAuthUser();
    const { data: impersonatedUserData, isLoading: isImpersonatedUserDataLoading } = useImpersonatedUserDetail(email);

    const { mutateAsync: copyResetPassword } = useCopyPasswordReset();
    const { mutateAsync: sendResetPassword } = useSendPasswordReset();

    const copyPasswordReset = useCallback(async (id: number) => {
        try {
            const response = await copyResetPassword(id);
            await copyToClipboard(response.link);
            pushSuccessNotification('Password reset link copied.');
        } catch (e) {
            pushApiErrorNotification('There was an problem generating password reset link.');
        }
    }, []);

    const sendPasswordReset = useCallback(async (id: number) => {
        try {
            await sendResetPassword(id);
            pushSuccessNotification('Password reset sent.');
        } catch (e) {
            pushApiErrorNotification('There was an error sending password reset link.');
        }
    }, []);

    if (isImpersonatedUserDataLoading) {
        return <Loader title="Loading..." devLabel="Profiles User detail API" />;
    }
    if (impersonatedUserData === null) {
        return <Box mb={3}>No login method has been found for this account.</Box>;
    }
    if (impersonatedUserData === undefined) {
        return <Alert severity="error">There was a problem fetching impersonated login method.</Alert>;
    }

    return (
        <LabelValueRow
            label="Password"
            action={
                <>
                    <Button variant="secondary" onClick={() => copyPasswordReset(impersonatedUserData.id)}>
                        Copy reset password link
                    </Button>
                    <Button
                        variant="secondary"
                        sx={{ ml: 1 }}
                        onClick={() => sendPasswordReset(impersonatedUserData.id)}
                    >
                        Send reset password link
                    </Button>
                </>
            }
        />
    );
};
