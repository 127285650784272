import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

export type ModalStateContextType = {
    isAnyTutorialOpen: boolean;
    setIsOrderOptionsMenuTutorialOpen: Dispatch<SetStateAction<boolean>>;
};

export const ModalStateContext = createContext<ModalStateContextType>({
    isAnyTutorialOpen: false,
    setIsOrderOptionsMenuTutorialOpen: () => {},
});

export const ModalStateProvider = ({ children }: PWC) => {
    const [isOrderOptionsMenuTutorialOpen, setIsOrderOptionsMenuTutorialOpen] = useState(false);

    const isAnyTutorialOpen = isOrderOptionsMenuTutorialOpen;

    const providerValue = useMemo(
        () => ({ isAnyTutorialOpen, setIsOrderOptionsMenuTutorialOpen }),
        [isOrderOptionsMenuTutorialOpen],
    );

    return <ModalStateContext.Provider value={providerValue}>{children}</ModalStateContext.Provider>;
};

/**
 * Exposes a global state in which we can register opened modal elements.
 * We need to use it in some cases where an opened modal disrupts another one, such as Tutorial vs. Menu
 */
export const useModalStateContext = () => useContext(ModalStateContext);
