import { useCallback, useEffect, useState } from 'react';
import { SelectChangeEvent, Slide } from '@mui/material';
import { ScreenReadersOnly, Select } from '@customink/pigment-react';
import { useLinks } from '~/contexts/LinkContext';
import { SectionContent } from '~/components/Layout/SectionContent';
import { SelectedBottomBar } from '~/components/BottomBar/SelectedBottomBar';
import { PageControlsMount } from '~/components/PageHeader/PageControls';
import { ColumnRowMobileToggle } from '~/components/PageHeader/ColumnRowMobileToggle';
import { usePage } from '~/adapters/browser/usePage';
import { Title } from '~/pages/shared/title';
import { trackEvent } from '~/adapters/tracking/tracking';
import { TypedMenuItem } from '~/adapters/designSystem/TypedMenuItem';
import { gaCategoryNew } from '~/pages/SavedDesigns/tracking';
import { useInfiniteDesigns } from '~/adapters/services/accounts/designs/hooks';
import { useFlattenPaginatedData } from '~/adapters/services/shared/useFlattenPaginatedData';
import { DesignSortBy } from '~/adapters/services/accounts/designs/types';
import { useAccount } from '~/contexts/UserAccount/UserAccountContext';
import { CardListLoader } from '~/components/Layout/CardListLoader';
import { PageSort } from '~/components/PageHeader/PageSort';
import { DesignCardList } from './DesignCardList';
import { CartNotification } from './components/CartNotification';

const DesignsSortByMenuItem = TypedMenuItem<DesignSortBy>;

const mobile2ColumnsKey = 'accounts-ui-is-mobile-columns-view';

export function SavedDesigns() {
    const links = useLinks();

    const [showMobile2columns, setShowMobile2columns] = useState(localStorage.getItem(mobile2ColumnsKey) === 'true');
    useEffect(() => {
        localStorage.setItem(mobile2ColumnsKey, showMobile2columns.toString());
    }, [showMobile2columns]);

    const [sort, setSort] = useState<DesignSortBy>('createdAt-desc');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    usePage({ title: Title, reportPath: '/accounts/saved_designs' });

    const { id: accountId } = useAccount();
    const { fetchNextPage, hasNextPage, isFetching, isLoading, data } = useInfiniteDesigns({ sort, accountId });
    const designs = useFlattenPaginatedData(data, 'items');

    const [selectedCards, setSelectedCards] = useState(() => new Set<string>());
    const setIsSelectedForOneCard = useCallback((cardId: string, isSelected: boolean) => {
        setSelectedCards((prevSelectedCards) => {
            const newSelectedCards = new Set(prevSelectedCards);
            // the set operations are idempotent, potential duplicate calls are safe
            if (isSelected) {
                newSelectedCards.add(cardId);
            } else {
                newSelectedCards.delete(cardId);
            }
            return newSelectedCards;
        });
    }, []);

    const clearSelectedCards = useCallback(() => {
        setSelectedCards(new Set<string>());
    }, []);

    const handleSelectChange = useCallback((e: SelectChangeEvent<unknown>) => {
        setSort(e.target.value as DesignSortBy);
        trackEvent({
            category: gaCategoryNew,
            action: 'Designs Sort by',
            label: e.target.value as DesignSortBy,
        });
    }, []);

    const showAddToCartSnackbar = useCallback((message: string) => {
        setSnackbarOpen(true);
        setSnackbarMessage(message);
    }, []);

    const handleAddToCartSnackbarClose = useCallback(() => {
        setSnackbarOpen(false);
    }, []);

    if (isLoading) {
        return <CardListLoader />;
    }

    return (
        <SectionContent>
            <ScreenReadersOnly>
                <h2>Saved Designs</h2>
            </ScreenReadersOnly>
            <CartNotification open={snackbarOpen} onClose={handleAddToCartSnackbarClose} message={snackbarMessage} />

            <DesignCardList
                designs={designs}
                designLabLink={links.designLabLink}
                loading={isFetching}
                onLoadMore={fetchNextPage}
                hasMorePages={hasNextPage}
                selectedCards={selectedCards}
                setSelectedForOneCard={setIsSelectedForOneCard}
                showAddToCartSnackbar={showAddToCartSnackbar}
                showMobile2columns={showMobile2columns}
            />
            <PageControlsMount>
                <ColumnRowMobileToggle setShowColumns={setShowMobile2columns} showColumns={showMobile2columns} />
                <PageSort>
                    <Select data-testid="designs filter" fullWidth value={sort} onChange={handleSelectChange}>
                        <DesignsSortByMenuItem value="createdAt-desc">Newest</DesignsSortByMenuItem>
                        <DesignsSortByMenuItem value="createdAt-asc">Oldest</DesignsSortByMenuItem>
                        <DesignsSortByMenuItem value="updatedAt-desc">Date Modified</DesignsSortByMenuItem>
                        <DesignsSortByMenuItem value="name-asc">Design Name: A-Z</DesignsSortByMenuItem>
                        <DesignsSortByMenuItem value="name-desc">Design Name: Z-A</DesignsSortByMenuItem>
                    </Select>
                </PageSort>
            </PageControlsMount>
            <Slide direction="up" in={selectedCards.size > 0} mountOnEnter>
                <SelectedBottomBar
                    selectedCids={selectedCards}
                    allDesigns={designs}
                    showSnackbar={showAddToCartSnackbar}
                    clearSelectedCards={clearSelectedCards}
                />
            </Slide>
        </SectionContent>
    );
}
