import { CardContent, Tooltip, Typography } from '@customink/pigment-react';
import { styled } from '@mui/material';
import { useMemo } from 'react';
import { format } from 'date-fns';

const CardContentTypography = styled(Typography)(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    display: 'block',
}));

interface DesignCardContentProps {
    productName: string;
    productColor: string;
    updatedAt: string;
}

export function DesignCardContent({ productName, productColor, updatedAt }: DesignCardContentProps) {
    const parsedDate = useMemo(() => new Date(updatedAt), [updatedAt]);

    return (
        <CardContent sx={{ pt: 1 }}>
            {productName && <CardContentTypography variant="uiTextDefault">{productName}</CardContentTypography>}
            {productColor && <CardContentTypography variant="uiTextSmall">{productColor}</CardContentTypography>}
            <Tooltip title={parsedDate.toLocaleString()} placement="top">
                <CardContentTypography variant="uiTextSmall">
                    Last saved on {format(parsedDate, 'MMM d, yyyy')}
                </CardContentTypography>
            </Tooltip>
        </CardContent>
    );
}
