import { Card, CardContent, Stack } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

export const DesignDetailCard = ({ children }: PWC) => (
    <Card>
        <CardContent sx={{ mt: 0, pt: 3 }}>
            <Stack direction="column" spacing={1} alignItems="flex-start">
                {children}
            </Stack>
        </CardContent>
    </Card>
);
