import { useEffect } from 'react';
import { trackPageView } from './tracking';

export function useTrackPage(title: string, trackPath: string, accountId: number | null, accountCompoundId?: string) {
    useEffect(() => {
        if (accountCompoundId === undefined) {
            return;
        }
        trackPageView(title, trackPath, accountId, accountCompoundId);
    }, [trackPath, accountCompoundId, title, accountId]);
}
