import { ArrowDownIcon, Button, CheckIcon, Divider, ListItem, PlusIcon } from '@customink/pigment-react';
import Menu from '@customink/pigment-react/lib/components/Menu';
import MenuList from '@customink/pigment-react/lib/components/MenuList';
import { Skeleton } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useUserAccounts } from '~/adapters/services/accounts/users/hooks';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { useMenu } from '~/components/Menu/useMenu';
import CreateTeamWizard from '~/components/MultiAccountManagement/Wizard/CreateTeamWizard';
import { navigationWidth } from '~/components/Navigation/styles';

const AccountSelector: React.FC = () => {
    const { account: currentAccount, persistCurrentAccountId } = useUserAccount();
    const { data: accounts, isLoading } = useUserAccounts();
    const sortedAccounts = useMemo(
        () => accounts?.sort((a, b) => Number(a.personal) - Number(b.personal)) ?? [],
        [accounts],
    );

    const [isWizardOpen, setIsWizardOpen] = useState(false);

    const handleOpenWizard = useCallback(() => setIsWizardOpen(true), []);
    const handleCloseWizard = useCallback(() => setIsWizardOpen(false), []);

    const menu = useMenu();

    if (isLoading) {
        return (
            <Skeleton>
                <Button fullWidth>Create Team</Button>
            </Skeleton>
        );
    }

    if (sortedAccounts.length <= 1) {
        return (
            <>
                <CreateTeamWizard open={isWizardOpen} onClose={handleCloseWizard} />
                <Button onClick={handleOpenWizard} startIcon={<PlusIcon />} fullWidth variant="secondary">
                    Create Team
                </Button>
            </>
        );
    }

    return (
        <>
            <CreateTeamWizard open={isWizardOpen} onClose={handleCloseWizard} />
            <Button fullWidth variant="secondary" onClick={menu.handleOpen} endIcon={<ArrowDownIcon />}>
                <span
                    style={{ marginRight: 'auto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                    {currentAccount.displayName}
                </span>
            </Button>
            <Menu open={menu.open} onClose={menu.handleClose} anchorEl={menu.anchorEl}>
                <MenuList
                    sx={{
                        width: navigationWidth,
                    }}
                    disablePadding
                >
                    {sortedAccounts.map((account) => (
                        <ListItem
                            key={account.id}
                            endIcon={account.id === currentAccount.id ? <CheckIcon fontSize="small" /> : undefined}
                            onClick={() => {
                                persistCurrentAccountId(account.personal ? 'personal' : account.id);
                                menu.handleClose();
                            }}
                        >
                            {account.displayName}
                        </ListItem>
                    ))}
                    <Divider
                        sx={{
                            marginY: '8px',
                        }}
                    />
                    <ListItem
                        variant="hyperlink"
                        startIcon={<PlusIcon />}
                        onClick={() => {
                            menu.handleClose();
                            handleOpenWizard();
                        }}
                    >
                        Create Team
                    </ListItem>
                </MenuList>
            </Menu>
        </>
    );
};

export default AccountSelector;
