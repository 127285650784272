import { Box, Grid, Stack } from '@customink/pigment-react';
import { OrderDetails } from '~/adapters/services/accounts/orders/types';
import { Money } from '~/components/Money/money';
import { InfoText } from '../../../components/InfoText';
import { OrderLabel } from './OrderLabel';

function translatePaymentTypes(paymentType: string) {
    switch (paymentType) {
        case 'PAYMENT_WALLET':
            return 'Payment Wallet';
        default:
            return paymentType;
    }
}

export function OrderInvoiceInfo({ orderDetails }: { orderDetails: OrderDetails }) {
    return (
        <Grid container>
            <Grid item sm={12} md={4}>
                <OrderLabel>Shipping Address:</OrderLabel>
                <InfoText>{orderDetails.shippingDetail.address.address1}</InfoText>
                {orderDetails.shippingDetail.address.address2 && (
                    <InfoText>{orderDetails.shippingDetail.address.address2}</InfoText>
                )}
                <InfoText>
                    {orderDetails.shippingDetail.address.city}, {orderDetails.shippingDetail.address.state}{' '}
                    {orderDetails.shippingDetail.address.zip}
                </InfoText>
                <InfoText>{orderDetails.shippingDetail.address.country}</InfoText>
            </Grid>
            <Grid item sm={12} md={4}>
                <Stack spacing={2}>
                    <div>
                        <OrderLabel>Billing Address:</OrderLabel>
                        <InfoText>{orderDetails.payment.address.address1}</InfoText>
                        {orderDetails.payment.address.address2 && (
                            <InfoText>{orderDetails.payment.address.address2}</InfoText>
                        )}
                        <InfoText>
                            {orderDetails.payment.address.city}, {orderDetails.payment.address.state}{' '}
                            {orderDetails.payment.address.zip}
                        </InfoText>
                        <InfoText>{orderDetails.payment.address.country}</InfoText>
                        {orderDetails.payment.address.organization && (
                            <InfoText>{orderDetails.payment.address.organization}</InfoText>
                        )}
                    </div>
                    <div>
                        <OrderLabel>Payment Type:</OrderLabel>
                        <InfoText>{translatePaymentTypes(orderDetails.payment.paymentMethod)}</InfoText>
                    </div>
                </Stack>
            </Grid>
            <Grid item sm={12} md={4}>
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <OrderLabel>Subtotal:</OrderLabel>
                            <InfoText>
                                <Money value={Number(orderDetails.payment.subTotal)} />
                            </InfoText>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <OrderLabel>Service Charges:</OrderLabel>
                            <InfoText>
                                <Money value={Number(orderDetails.payment.serviceCharges)} />
                            </InfoText>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <OrderLabel>Service Discounts:</OrderLabel>
                            <InfoText>
                                -<Money value={Number(orderDetails.payment.serviceDiscounts)} />
                            </InfoText>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <OrderLabel>Tax:</OrderLabel>
                            <InfoText>
                                <Money value={Number(orderDetails.payment.tax)} />
                            </InfoText>
                        </Box>
                    </Stack>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <OrderLabel>Order Total:</OrderLabel>
                        <InfoText>
                            <Money value={Number(orderDetails.payment.totalPrice)} />
                        </InfoText>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
}
