import { getEnv } from '~/adapters/config/env';

export function buyNow(compositeId: string) {
    return `${getEnv('ACCOUNTS_RFE_URL')}/checkout/buy_now/${compositeId}`;
}

export function checkout() {
    return `${getEnv('ACCOUNTS_RFE_URL')}/checkout/summary`;
}

export const reorderLink = (mostRecentOrderId: number): string => {
    return `${getEnv('ACCOUNTS_RFE_URL')}/checkout/reorder?fulfillment_order_id=${mostRecentOrderId}`;
};

export const internalBuyLink = (
    designId: string,
    compositeId: string,
    mostRecentOrderId: number | undefined,
): string => {
    return mostRecentOrderId
        ? `${getEnv(
              'ACCOUNTS_RFE_URL',
          )}/admin/internal_checkouts/create_reorder_ap_order?design_id=${designId}&reorder=${mostRecentOrderId}`
        : `${getEnv('ACCOUNTS_RFE_URL')}/admin/designs/${compositeId}`;
};
