import { FC, MouseEvent, useCallback } from 'react';
import { trackEvent } from '~/adapters/tracking/tracking';
import setLocation from '~/adapters/browser/setLocation';
import { ResponsiveMenu } from '~/components/Menu/ResponsiveMenu';
import { ResponsiveMenuItem } from '~/components/Menu/ResponsiveMenuItem';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { Design } from '~/adapters/services/accounts/designs/types';
import { useFundraiserLink, useGroupOrdersLink } from '../links';
import { gaCategoryOld } from '../tracking';

type AddToCartMenuItemProps = {
    handleClose: EmptyFunction;
    design: Design;
};

type CardMenuProps = AddToCartMenuItemProps & { anchorElement: HTMLElement | null };

export const OrderOptionsMenu: FC<CardMenuProps> = ({ anchorElement, handleClose, design }) => {
    const cid = design.compositeId;

    const groupOrdersLink = useGroupOrdersLink(cid);
    const fundRaisingLink = useFundraiserLink(cid);
    const navigateToGroupOrders = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            handleClose();
            e.preventDefault();
            trackEvent({ category: gaCategoryOld, action: 'Link Click', label: 'Start Group Order' });
            setLocation(groupOrdersLink);
        },
        [handleClose, setLocation, groupOrdersLink],
    );

    const navigateToFundraiser = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            handleClose();
            e.preventDefault();
            trackEvent({ category: gaCategoryOld, action: 'Link Click', label: 'Start a Fundraiser' });
            setLocation(fundRaisingLink);
        },
        [handleClose, setLocation, fundRaisingLink],
    );

    return (
        <ResponsiveMenu
            handleClose={handleClose}
            anchorElement={anchorElement}
            title="Order Options"
            menuAriaLabel="view-order-options-menu"
        >
            <ResponsiveMenuItem
                onClick={navigateToGroupOrders}
                label={design.usedInGroupOrderForm ? 'Manage Group Order' : 'Set up Group Order'}
                description="Easily collect sizes, addresses, and payments from your group."
            />
            <ResponsiveMenuItem
                onClick={navigateToFundraiser}
                label="Start a Fundraiser"
                description="Raise money and awareness for anything you care about."
            />
        </ResponsiveMenu>
    );
};
