import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Box } from '@customink/pigment-react';

export const BoxVisibleBelowMd = styled(Box)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        display: 'none',
    },
}));

export const BoxVisibleAboveMd = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'block',
    },
}));
