import React, { ComponentProps } from 'react';
import type { LinkProps as OriginalLinkProps } from 'react-router-dom';
import { Link as OriginalLink } from 'react-router-dom';
import { Button } from '@customink/pigment-react';
import { usePreservedQueryParamLink } from '~/adapters/browser/usePreservedQueryParamLink';

type LinkProps = OriginalLinkProps & {
    to: string;
};

// in order to be used as button component, MUI requires it to accept refs (and the ref must be passed down, not ignored)
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(({ to, ...rest }: LinkProps, ref) => {
    const newTo = usePreservedQueryParamLink(to);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <OriginalLink to={newTo} {...rest} ref={ref} />;
});

type ButtonLinkProps = LinkProps & ComponentProps<typeof Button>;

/**
 * Looks like a button, behaves like a link (backed by the router).
 * See https://mui.com/material-ui/guides/routing/#button for more details.
 */
export function ButtonLink({ ...linkProps }: ButtonLinkProps) {
    return (
        // The `component` prop is not supported by Pigment, but it *is* there by MUI, and we *can* use it.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Button
            component={Link}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...linkProps}
        />
    );
}
