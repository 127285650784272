import React from 'react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Box, Divider, Stack, Typography } from '@customink/pigment-react';
import AccountSelector from '~/components/MultiAccountManagement/AccountSelector';
import { navigationWidth } from '~/components/Navigation/styles';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';
import { MenuItem } from './MenuItem';
import { NavLink } from '../Link/Navlink';

const NavigationOptionLink = styled(Typography, { shouldForwardProp: (prop) => prop !== 'isActive' })<{
    isActive: boolean;
}>(({ theme, isActive }) => ({
    transition: '0.35s',
    display: 'block',
    height: '50px',
    lineHeight: '50px',
    fontSize: theme.typography.fontSize,
    color: isActive ? theme.palette.blue.main : theme.palette.grey[800],
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.fontSize * 1.1,
    },
    letterSpacing: isActive ? '0.2px' : '0px',
    fontWeight: isActive ? 'bold' : 'normal',
    '&:hover': {
        backgroundColor: isActive ? 'initial' : theme.palette.grey[200],
    },
}));

const NavigationItem: React.FC<MenuItem> = ({ label, action, link, endIcon, external = false, selected, onClick }) => {
    const content = (
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'start', gap: 1 }}>
            <span>{label}</span>
            {endIcon}
        </Stack>
    );

    const handleClick = async (event: React.MouseEvent) => {
        if (onClick) {
            event.preventDefault();
            await onClick();
        }
    };

    return (
        <li>
            {external ? (
                <a href={link} data-action={action} onClick={handleClick}>
                    <NavigationOptionLink isActive={selected}>{content}</NavigationOptionLink>
                </a>
            ) : (
                <NavLink to={link} data-action={action} onClick={handleClick}>
                    <NavigationOptionLink isActive={selected}>{content}</NavigationOptionLink>
                </NavLink>
            )}
        </li>
    );
};

const DesktopList = styled('ul')({
    float: 'left',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    width: '100%',
});

interface NavigationGroupProps {
    group: MenuItem[];
}

const NavigationGroup: React.FC<NavigationGroupProps> = ({ group }) => (
    <>
        {group.map(({ label, link, selected, action, external, endIcon, enabled = true, onClick }) => {
            if (!enabled) {
                return null;
            }
            return (
                <NavigationItem
                    key={label}
                    label={label}
                    selected={selected}
                    link={link}
                    action={action}
                    external={external}
                    endIcon={endIcon}
                    onClick={onClick}
                />
            );
        })}
    </>
);

interface DesktopNavigationProps {
    menu: MenuItem[][];
}

const DesktopNavigation: React.FC<DesktopNavigationProps> = ({ menu }) => {
    const isTeamAccountsFeatureFlagEnabled = useFeatureFlagEnabled('team_accounts_v0');

    return (
        <DesktopList>
            {isTeamAccountsFeatureFlagEnabled && (
                <Box width={navigationWidth} mb={2}>
                    <AccountSelector />
                </Box>
            )}
            {menu.map((group, index) => (
                // menu is a constant array, not external data, so it is guaranteed not to reorder, so index is safe
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={index}>
                    {index > 0 && group.some((item) => item.enabled) && <Divider />}
                    <NavigationGroup group={group} />
                </React.Fragment>
            ))}
        </DesktopList>
    );
};

export default DesktopNavigation;
