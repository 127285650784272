import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { FeatureDefinition, featuresDefinition, signaler, SignalerFeatureFlagName } from '~/adapters/signaler/signaler';
import { trackABTest } from '~/adapters/tracking/tracking';

interface SignalerContextType {
    version: number;
    onFeatureFlagUpdate: () => void;
}

const SignalerContext = createContext<SignalerContextType | null>(null);

/*
The purpose of this provider is to track feature flags in GA and to provide a way to rerender whole React app when feature flag is manually changed in /signaler page
*/
export const SignalerProvider: FC<PWC> = ({ children }) => {
    // track all signaler feature flags that are running an AB test
    useEffect(() => {
        Object.entries(featuresDefinition)
            .filter(([, featureDefinition]) => featureDefinition.flags.off < 1)
            .forEach(([featureName, featureDefinition]) => {
                const value = signaler.featureFlag(featureName as SignalerFeatureFlagName);
                if (value) {
                    trackABTest(featureName, value, (featureDefinition as FeatureDefinition).launchDate);
                }
            });
    }, []);

    const [version, setVersion] = useState(0);

    const rerenderApp = useCallback(() => {
        setVersion((prev) => {
            return prev + 1;
        });
    }, [setVersion]);

    return (
        <SignalerContext.Provider value={{ version, onFeatureFlagUpdate: rerenderApp }}>
            {children}
        </SignalerContext.Provider>
    );
};

export const useSignalerContext = () => {
    const context = useContext(SignalerContext);
    if (!context) {
        throw new Error('useSignalerContext must be used within a SignalerProvider');
    }
    return context;
};
