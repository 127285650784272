import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { IconButton, Skeleton } from '@mui/material';
import { Box, Button, ShareIcon, Stack } from '@customink/pigment-react';
import { ReferenceType } from '~/adapters/services/collaboration/graphqlTypes';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { BaseContext, FeedbackPath } from '~/config';
import { useLinks } from '~/contexts/LinkContext';
import { usePreservedQueryParam } from '~/adapters/browser/usePreservedQueryParam';
import { useGetDesignReviewInfoQuery } from '~/adapters/services/collaboration/collaborationHooks';
import { designStatusEnumToSharingStatusType } from '../SharingAndCollab/mappers';
import { AvatarList } from '../SharingAndCollab/components/AvatarList';
import { SharingStatus } from '../SharingAndCollab/components/SharingStatus';
import { DesignDetailCard } from './DesignDetailCard';

interface SharingCardProps {
    cid: string;
    openSharingDialog: EmptyFunction;
}

export const SharingCard = ({ cid, openSharingDialog }: SharingCardProps) => {
    const { basePath } = useLinks();
    const queryParams = usePreservedQueryParam();

    const { data, isLoading } = useGetDesignReviewInfoQuery({
        input: { referenceId: cid, referenceType: ReferenceType.DesignReview },
    });
    const resourceId = data?.resource?.id;
    const sharingStatus = useMemo(
        () => designStatusEnumToSharingStatusType(data?.resource?.designStatus.status),
        [data],
    );

    if (isLoading) {
        return <Skeleton variant="rounded" width="100%" height="180px" />;
    }

    if (sharingStatus === 'private' || !resourceId) {
        return (
            <DesignDetailCard>
                <Stack direction="row" alignItems="center">
                    <IconButton onClick={openSharingDialog}>
                        <ShareIcon />
                    </IconButton>
                    <Button onClick={openSharingDialog} variant="link">
                        Share this design
                    </Button>
                </Stack>
            </DesignDetailCard>
        );
    }
    const feedbackLink = `${basePath}${BaseContext}${generatePath(FeedbackPath, { resourceId })}${queryParams}`;

    return (
        <DesignDetailCard>
            <Box>
                Status: <SharingStatus status={sharingStatus} onClick={openSharingDialog} />
            </Box>
            <Stack direction="row" alignItems="center" spacing={1} my={1} alignSelf="stretch">
                <IconButton onClick={openSharingDialog}>
                    <ShareIcon />
                </IconButton>

                <AvatarList onClick={openSharingDialog} />
            </Stack>
            <Button variant="secondary" href={feedbackLink}>
                View Feedback
            </Button>
        </DesignDetailCard>
    );
};
