import { Avatar as MuiAvatar, Skeleton, useTheme } from '@mui/material';

export function AvatarSkeleton() {
    const theme = useTheme();
    return (
        // The outer Avatar is for correct display inside AvatarGroup (borders, margins)
        // The inner Avatar is for the Skeleton to infer correct dimensions
        <MuiAvatar style={{ marginLeft: theme.spacing(-1) }}>
            <Skeleton variant="circular" sx={{ bgcolor: 'background.default' }}>
                <MuiAvatar />
            </Skeleton>
        </MuiAvatar>
    );
}
