import { Box, Stack } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';

export const AppLayout = styled(Stack)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    gap: 0,
    [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
        gap: theme.spacing(5),
        paddingTop: theme.spacing(5),
    },
}));

export const MainContentLayout = styled(Stack)(({ theme }) => ({
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
    },
}));

export const Content = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    minWidth: 0,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
        marginTop: 0,
        paddingLeft: theme.spacing(2),
    },
}));
