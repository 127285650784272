import { AnalyticsEvent, trackEvent } from '~/adapters/tracking/tracking';
import { AnalyticsConstants } from '~/adapters/tracking/AnalyticsConstants';

export function sendSharingDialogEvent(action: string, label: string, emailSharesAdded?: number): void {
    const a: AnalyticsEvent = { category: 'Modal', action, label };
    if (emailSharesAdded !== undefined) {
        a.customMetrics = { [AnalyticsConstants.emailSharesAdded]: emailSharesAdded };
    }
    trackEvent(a);
}
