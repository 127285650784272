import { ECMBusinessAddressDto, ECMCustomerDto } from '~/adapters/services/accounts/taxExemptionsECM/types';

export interface ButtonVariantText {
    text: string;
    variant: 'primary' | 'secondary';
}

export type CustomerWithBusinessAddress = ECMCustomerDto & { businessAddress: ECMBusinessAddressDto };
export type OptionalCustomer = ECMCustomerDto | null;

export const avalaraTakingTooLongMessage =
    "The Tax Exemption portal is taking longer to initiate. No action is needed on your part. We will continue the initiation process and send you an email with a link to the Tax Exemption Portal once it's available. Or you can check back later.";
export const portalErrorNotificationMessage =
    'Error during Tax Exemption initialization: Your portal link could not be created.';
