import { useEffect, useRef } from 'react';

interface ScrollLoaderProps {
    /**
     * percentage of scroll when should load be triggered
     * @default 0.5
     */
    threshold?: number;
    /**
     * callback function which will be triggered
     */
    load: () => void;
    /**
     * triggering callback function is enabled
     * @default true
     */
    enabled?: boolean;
}

export function useScrollLoad({ load, enabled = true, threshold = 0.5 }: ScrollLoaderProps) {
    const ref = useRef<HTMLDivElement | null>(null);
    const scrollRef = useRef<() => void>();

    scrollRef.current = function onScroll() {
        if (!enabled) {
            return;
        }
        // We should not trigger loading on network loads it can cause receiving the page twice
        const itemHeight = ref.current?.offsetHeight ?? Number.MAX_VALUE;
        const documentScroll = document.documentElement.scrollTop ?? 0;
        const scrolledRatio = documentScroll / itemHeight;
        const shouldScroll = scrolledRatio > threshold;
        if (shouldScroll) {
            load();
        }
    };

    function scroll() {
        const { current } = scrollRef;
        if (current) {
            current();
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scroll);
        return () => window.removeEventListener('scroll', scroll);
    }, []);

    return ref;
}
