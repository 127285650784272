import {
    GetDesignReviewInfoQuery,
    SharingRole,
    SharingUser,
    UpdateEmailSharesMutationVariables,
} from '~/adapters/services/collaboration/graphqlTypes';

/**
 * clean up emailShares, a part of response from Query DesignReviewInfo, by removing the owner object
 * this transforms data to the request format for Mutation UpdateEmailShares
 * as a beneficial side-effect, it also prepares data for the usual display (with owner omitted)
 * @see ./transformers.test.ts
 * @see ./hooksManual.ts
 */
export const cleanEmailShares = (emailSharing: SharingUser[]): SharingUser[] =>
    emailSharing.filter((user) => user.role !== SharingRole.Owner);

export const createOptimisticallyUpdatedDesignReviewInfoResponse = (
    variables: UpdateEmailSharesMutationVariables,
    previous?: GetDesignReviewInfoQuery,
): GetDesignReviewInfoQuery => {
    if (!previous?.resource) {
        return {};
    }
    const ownerObject = previous.resource.emailSharing.filter((user) => user.role === SharingRole.Owner);
    return {
        resource: {
            ...previous.resource,
            // FIY note: this is only possible because in the DesignReviewInfo Query document (graphql.ts), we are asking *only* for emailSharing { email, role }
            // that's the same data as SharingUsers[] required by the mutation – any further properties are unacceptable for the mutation!
            // any additional information could not be correctly synthesized, because it is necessarily lost at this point (it could only be mocked)
            emailSharing: ownerObject.concat(variables.input.users),
        },
    };
};
