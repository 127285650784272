import { useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { ApiCall } from '../shared/apiCall';
import { buyNow } from './links';
import { useRfeApiCall } from './rfeApiCall';

class RfeRepository {
    constructor(private readonly rfeApiCall: ApiCall) {}

    public addDesignsToCart = async (cids: string[]) => {
        try {
            await this.rfeApiCall(`/api/checkout/add_to_cart?cid=${cids.join(',')}`);
        } catch (e) {
            // If anything happens when adding product to cart, we still want to redirect user to cart at least with only current product
            Sentry.captureException(e, {
                level: 'error',
            });
            return buyNow(cids[0]);
        }
        return null;
    };
}

export const useRfeRepository = () => {
    const rfeApiCall = useRfeApiCall();

    return useMemo(() => new RfeRepository(rfeApiCall), []);
};
