import { Alert, Button, Dialog, DialogActions, DialogContent, Typography } from '@customink/pigment-react';
import Spinner from '~/components/Spinner';
import { MouseEventHandler, ReactElement, ReactNode, useCallback, useState } from 'react';

export type ConfirmDeleteDialogProps = {
    /**
     * A render prop that renders the CTA that this confirmation belongs to
     * @param openConfirmDialog handler that opens this dialog
     */
    children: <T extends never>(openConfirmDialog: MouseEventHandler<T>) => ReactElement<T>;
    onDeleteConfirmed: () => Promise<unknown>;
    dialogTitle?: string;
    alertText?: ReactNode;
    additionalText?: ReactNode;
    confirmButtonLabel?: string;
};

export function ConfirmDeleteDialog({
    children: renderOpeningCTA,
    onDeleteConfirmed,
    dialogTitle = 'Are you sure?',
    alertText = 'This action is permanent and cannot be undone.',
    additionalText,
    confirmButtonLabel = 'Delete',
}: ConfirmDeleteDialogProps) {
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleOpen = useCallback(() => setDialogVisible(true), []);
    const handleClose = useCallback(() => setDialogVisible(false), []);

    const handleDelete = useCallback(async () => {
        setIsLoading(true);
        onDeleteConfirmed()
            .finally(handleClose)
            .finally(() => setIsLoading(false));
    }, [onDeleteConfirmed, handleClose]);

    return (
        <>
            {renderOpeningCTA(handleOpen)}
            <Dialog title={dialogTitle} open={dialogVisible} onClose={handleClose} width="sm">
                <DialogContent>
                    {alertText && (
                        <Alert severity="error">
                            <Typography variant="paragraphSmall">{alertText}</Typography>
                        </Alert>
                    )}
                    {additionalText && (
                        <Typography sx={{ mt: 3 }} paragraph variant="paragraphSmall">
                            {additionalText}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="primary" onClick={handleDelete} autoFocus disabled={isLoading}>
                        {isLoading ? <Spinner /> : confirmButtonLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
