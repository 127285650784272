import { MouseEvent, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Tooltip } from '@customink/pigment-react';
import { FeedbackPath } from '~/config';
import { usePreservedQueryParam } from '~/adapters/browser/usePreservedQueryParam';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { Design } from '~/adapters/services/accounts/designs/types';
import { trackEvent } from '~/adapters/tracking/tracking';
import { ResponsiveMenu } from '~/components/Menu/ResponsiveMenu';
import { ResponsiveMenuItem } from '~/components/Menu/ResponsiveMenuItem';
import { gaCategoryOld } from '../tracking';
import { SharingStatusType } from '../SharingAndCollab/types';

interface CardMenuProps {
    handleClose: EmptyFunction;
    anchorElement: HTMLElement | null;
    design: Design;
    designSharingStatus: SharingStatusType;
    resourceId?: string;
    onDesignDeleteClick: EmptyFunction;
}

export function CardMenu({
    handleClose,
    anchorElement,
    resourceId,
    design,
    designSharingStatus,
    onDesignDeleteClick,
}: CardMenuProps) {
    const navigate = useNavigate();
    const queryParams = usePreservedQueryParam();

    const deleteDesign = useCallback(() => {
        handleClose();
        trackEvent({
            category: gaCategoryOld,
            action: 'Button Click',
            label: 'Delete Design',
        });
        onDesignDeleteClick();
    }, [handleClose, onDesignDeleteClick]);

    const navigateToFeedback = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            // This will always be true because it's only fired when designSharingStatus is not private
            if (resourceId) {
                handleClose();
                e.preventDefault();
                trackEvent({
                    category: gaCategoryOld,
                    action: 'Link Click',
                    label: 'View Feedback',
                });
                navigate(`${generatePath(FeedbackPath, { resourceId })}${queryParams}`);
            }
        },
        [resourceId, handleClose, queryParams],
    );

    return (
        <ResponsiveMenu
            anchorElement={anchorElement}
            handleClose={handleClose}
            menuAriaLabel="more-menu"
            title="More Options"
        >
            {designSharingStatus !== 'private' && (
                <ResponsiveMenuItem onClick={navigateToFeedback} label="View Feedback" />
            )}
            <Tooltip title={design.isLocked ? 'This design can’t be deleted' : ''} placement="top">
                <span data-testid="delete design menuitem">
                    <ResponsiveMenuItem onClick={deleteDesign} disabled={design.isLocked} label="Delete" />
                </span>
            </Tooltip>
        </ResponsiveMenu>
    );
}
