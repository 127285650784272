import { useCallback } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, Typography } from '@customink/pigment-react';
import { trackEvent } from '~/adapters/tracking/tracking';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { pushApiErrorNotification, pushSuccessNotification } from '~/adapters/notistack/notistack';
import { useAuth } from '~/contexts/Auth/AuthContext';
import Spinner from '~/components/Spinner';
import { useDesignDelete } from '~/adapters/services/accounts/designs/hooks';
import { useAccount } from '~/contexts/UserAccount/UserAccountContext';

function sendHideDesignEvent(label: string) {
    trackEvent({ action: 'accounts hide design', label });
}

interface DeleteDialogProps {
    name: string;
    cid: string;
    open: boolean;
    onClose: EmptyFunction;
    onSuccess?: EmptyFunction;
}

export function DeleteDialog({ name, cid, open, onClose, onSuccess }: DeleteDialogProps) {
    const { mutateAsync, isPending } = useDesignDelete();
    const { id: accountId } = useAccount();
    const { isImpersonatedExperience } = useAuth();

    const handleDeleteDesign = useCallback(async () => {
        sendHideDesignEvent(`delete${isImpersonatedExperience ? ' impersonate' : ''}`);
        try {
            await mutateAsync({ cid, accountId });
            pushSuccessNotification(`Design “${name}” has been successfully removed from your account.`);
        } catch (err) {
            pushApiErrorNotification(`An error occurred while trying to delete “${name}”.`);
        }
        onClose();
        onSuccess?.();
    }, [cid, name, accountId, mutateAsync, onClose, onSuccess]);

    return (
        <Dialog title="Delete This Design" open={open} onClose={onClose}>
            <DialogContent pt={2}>
                <Alert severity="error" sx={{ mb: 3 }}>
                    <Typography variant="paragraphSmall">
                        By deleting “{name}”, this design will be removed from your account.
                    </Typography>
                </Alert>
                <Typography variant="paragraphSmall">
                    You will no longer be able to access, edit, or view this design from shared links. This action is
                    permanent and cannot be undone.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    data-testid="delete design confirm button"
                    onClick={handleDeleteDesign}
                    variant="primary"
                    disabled={isPending}
                >
                    {isPending ? <Spinner /> : 'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
