import { useMemo } from 'react';
import { PaginatedParams } from '~/adapters/services/shared/types/PaginatedParams';
import { offsetPaginationParams } from '~/adapters/services/shared/createParams';
import { AccountsApiClient } from '../accountsApiClient';
import { useAccountsApiClient } from '../useAccountsApiClient';

export interface GetGroupOrderProps {
    accountId: number;
}

const PER_PAGE = 6;

class GroupOrdersRepository {
    constructor(private readonly client: AccountsApiClient) {}

    public getGroupOrders = async ({ pageParam = 1, accountId }: PaginatedParams<GetGroupOrderProps>) => {
        const response = await this.client.getPaginated(
            `/accounts/{accountId}/group-orders`,
            {
                params: {
                    path: { accountId },
                    query: {
                        ...offsetPaginationParams({ perPage: PER_PAGE, page: pageParam }),
                    },
                },
            },
            pageParam,
            PER_PAGE,
        );

        return {
            ...response,
            items: response.items.map((groupOrder) => ({ ...groupOrder, isStoreGroupOrder: false })),
        };
    };

    public getGroupOrdersCount = async ({ accountId }: GetGroupOrderProps): Promise<number> => {
        const params = { limit: 1, offset: 0 };
        const response = await this.client.getPaginated(
            `/accounts/{accountId}/group-orders`,
            {
                params: { path: { accountId }, query: params },
            },
            0,
            1,
        );
        return response.totalCount;
    };
}

export const useGroupOrdersRepository = () => {
    const client = useAccountsApiClient();

    return useMemo(() => new GroupOrdersRepository(client), [client]);
};
