import { useMemo } from 'react';

/**
 * measure pixel size of text, using a reference element to extract the font style
 */
export function useMeasureTextWidth(text: string, refElement: HTMLElement = document.body): number | string {
    const fallbackWidth = '100%';
    const canvas = useMemo(() => document.createElement('canvas'), []);

    // canvas.getContext() is typed as nullable, but in this code it cannot return null. Still, we have to count with it.
    const context: CanvasRenderingContext2D | null = useMemo(() => {
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.font = window.getComputedStyle(refElement).font;
        }
        return ctx;
    }, [canvas, refElement]);

    return context ? context.measureText(text).width : fallbackWidth;
}
