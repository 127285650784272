import { ApiCall } from '~/adapters/services/shared/apiCall';
import { useProfilesApiCall } from '~/adapters/services/profiles/profilesApiCall';
import { useMemo } from 'react';
import { MeUserApiResponse } from './types';

class ProfileRepository {
    constructor(private readonly profilesApiCall: ApiCall) {}

    public getMyUserData = async (): Promise<MeUserApiResponse> => {
        const result = await this.profilesApiCall<MeUserApiResponse>('/api/users/me', { attachImpersonate: false });
        return result.data;
    };
}

export const useProfileRepository = () => {
    const profilesApiCall = useProfilesApiCall();

    return useMemo(() => new ProfileRepository(profilesApiCall), []);
};
