import { request, RequestDocument, Variables } from 'graphql-request';
import {
    keepPreviousData,
    QueryKey,
    useInfiniteQuery,
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { EnabledQueryOption } from '~/adapters/TanStackQuery/types';
import { graphqlLoader } from '~/adapters/services/shared/graphql/graphqlLoader';
import { useUserHeader } from '~/adapters/services/shared/graphql/useUserHeader';
import { getEnv } from '~/adapters/config/env';
import { getImpersonateQueryVariable } from '~/adapters/browser/getImpersonateQueryVariable';
import getQueryVariable from '~/adapters/browser/getQueryVariable';
import { createOptimisticallyUpdatedDesignReviewInfoResponse } from '~/adapters/services/collaboration/transformers';
import { omitNullishFields } from '~/adapters/typescript/omitNullishFields';
import {
    CloneResourceForMeMutation,
    CloneResourceForMeMutationVariables,
    CloneResourceForUserMutation,
    CloneResourceForUserMutationVariables,
    CreateEventMutation,
    CreateEventMutationVariables,
    CreateResourceMutation,
    CreateResourceMutationVariables,
    GetDesignReviewInfoQuery,
    GetDesignReviewInfoQueryVariables,
    GetDesignReviewQuery,
    GetDesignReviewQueryVariables,
    ReferenceType,
    SharedWithMeCountQuery,
    SharedWithMeQuery,
    SharedWithMeQueryVariables,
    SharingRole,
    UnsubscribeFromResourceMutation,
    UnsubscribeFromResourceMutationVariables,
    UpdateEmailSharesMutationVariables,
    UpdateLinkShareMutation,
    UpdateLinkShareMutationVariables,
} from './graphqlTypes';
import {
    CreateDesignCloneForMe,
    CreateDesignCloneForUser,
    CreateEvent,
    CreateResource,
    DesignReview,
    DesignReviewInfo,
    SharedWithMe,
    SharedWithMeCount,
    UnsubscribeFromResource,
    UpdateEmailShares,
    UpdateLinkShare,
} from './graphql';

interface GraphQlParams {
    impersonate?: string;
    code?: string;
}

function useGraphqlURL() {
    const params: GraphQlParams = {
        impersonate: getImpersonateQueryVariable(),
        code: getQueryVariable('code'),
    };
    return `${getEnv('ACCOUNTS_COLLABORATION_URL')}/api/graphql?${new URLSearchParams(
        omitNullishFields(params),
    ).toString()}`;
}

export const DesignReviewInfoKey = 'DesignReviewInfo';

export const useGetDesignReviewInfoQuery = (
    variables: GetDesignReviewInfoQueryVariables,
    { enabled }: EnabledQueryOption = {},
) => {
    const graphqlURL = useGraphqlURL();
    const getHeaders = useUserHeader();

    return useQuery<GetDesignReviewInfoQuery>({
        queryKey: [DesignReviewInfoKey, variables.input.referenceId],
        queryFn: async () => {
            const headers = await getHeaders();
            const loader = graphqlLoader(graphqlURL, headers);
            return loader.load({ document: DesignReviewInfo, variables });
        },
        enabled,
    });
};

export const InfiniteDesignReviewKey = 'GetDesignReview.infinite';

export const useInfiniteGetDesignReviewQuery = (variables: GetDesignReviewQueryVariables) => {
    const graphqlURL = useGraphqlURL();
    const getHeaders = useUserHeader();

    return useInfiniteQuery<GetDesignReviewQuery>({
        queryKey: [InfiniteDesignReviewKey, variables.input.id],
        queryFn: async (metaData) => {
            const headers = await getHeaders();
            return request<GetDesignReviewQuery, GetDesignReviewQueryVariables>(
                graphqlURL,
                DesignReview,
                {
                    ...variables,
                    ...(metaData.pageParam ?? {}),
                },
                headers,
            );
        },
        throwOnError: true,
        initialPageParam: 1,
        getNextPageParam(last) {
            const next = last.resource?.events.pageCursors.next?.cursor;
            return next ? { after: next } : undefined;
        },
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: true,
    });
};

export const SharedWithMeKey = 'SharedWithMe.infinite';

export const useInfiniteSharedWithMeQuery = (variables?: Partial<SharedWithMeQueryVariables>) => {
    const graphqlURL = useGraphqlURL();
    const getHeaders = useUserHeader();

    return useInfiniteQuery<SharedWithMeQuery>({
        queryKey: [SharedWithMeKey],
        queryFn: async (metaData) => {
            const headers = await getHeaders();
            return request<SharedWithMeQuery, SharedWithMeQueryVariables>(
                graphqlURL,
                SharedWithMe,
                {
                    input: ReferenceType.DesignReview,
                    filter: { roleNotEq: SharingRole.Owner },
                    ...variables,
                    ...(metaData.pageParam ?? {}),
                },
                headers,
            );
        },
        initialPageParam: 1,
        getNextPageParam(last) {
            const next = last.resources.pageCursors.next?.cursor;
            return next ? { after: next } : undefined;
        },
        placeholderData: keepPreviousData,
    });
};

export const SharedWithMeCountKey = 'SharedWithCountMe';

export const useSharedWithMeCount = ({ enabled }: EnabledQueryOption = {}) => {
    const graphqlURL = useGraphqlURL();
    const getHeaders = useUserHeader();
    return useQuery<SharedWithMeCountQuery>({
        queryKey: [SharedWithMeCountKey],
        queryFn: async () => {
            const headers = await getHeaders();
            return request<SharedWithMeCountQuery>(graphqlURL, SharedWithMeCount, {}, headers);
        },
        enabled,
    });
};

// while the query hooks are all somewhat different, the mutation hooks follow exactly the same structure
// except useUpdateEmailSharesMutation (see below)
function createMutationHook<Mutation = any, Vars extends Variables = Variables>(
    queryKey: string,
    queryDocument: RequestDocument,
) {
    return (options?: UseMutationOptions<Mutation, unknown, Vars>) => {
        const graphqlURL = useGraphqlURL();
        const getHeaders = useUserHeader();
        return useMutation<Mutation, unknown, Vars>({
            mutationKey: [queryKey],

            mutationFn: async (variables: Vars) => {
                const headers = await getHeaders();

                return request<Mutation, Variables>(graphqlURL, queryDocument, variables, headers);
            },

            ...options,
        });
    };
}

export const CreateResourceKey = 'CreateResource';
export const useCreateResourceMutation = createMutationHook<CreateResourceMutation, CreateResourceMutationVariables>(
    CreateResourceKey,
    CreateResource,
);

export const UpdateLinkShareKey = 'UpdateLinkShare';
export const useUpdateLinkShareMutation = createMutationHook<UpdateLinkShareMutation, UpdateLinkShareMutationVariables>(
    UpdateLinkShareKey,
    UpdateLinkShare,
);

export const CloneResourceForUserKey = 'CreateDesignCloneForUser';
export const useCloneResourceForUserMutation = createMutationHook<
    CloneResourceForUserMutation,
    CloneResourceForUserMutationVariables
>(CloneResourceForUserKey, CreateDesignCloneForUser);

export const CloneResourceForMeKey = 'CloneResourceForMe';
export const useCloneResourceForMeMutation = createMutationHook<
    CloneResourceForMeMutation,
    CloneResourceForMeMutationVariables
>(CloneResourceForMeKey, CreateDesignCloneForMe);

export const CreateEventKey = 'CreateEvent';
export const useCreateEventMutation = createMutationHook<CreateEventMutation, CreateEventMutationVariables>(
    CreateEventKey,
    CreateEvent,
);

export const UnsubscribeFromResourceKey = 'UnsubscribeFromResource';
export const useUnsubscribeFromResourceMutation = createMutationHook<
    UnsubscribeFromResourceMutation,
    UnsubscribeFromResourceMutationVariables
>(UnsubscribeFromResourceKey, UnsubscribeFromResource);

// updateEmailShares also implement optimistic update logic. This means that options argument is not available
export const useUpdateEmailSharesMutation = (queryKey: QueryKey) => {
    const graphqlURL = useGraphqlURL();
    const getHeaders = useUserHeader();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (variables: UpdateEmailSharesMutationVariables) => {
            const userHeader = await getHeaders();

            return request(graphqlURL, UpdateEmailShares, variables, userHeader);
        },
        onSettled: () => client.invalidateQueries({ queryKey, exact: true }),
        onMutate: async (variables) => {
            // optimistic update – first step is cancelling outgoing queries
            await client.cancelQueries({ queryKey });
            // take snapshot of previous data (we have exact key available, so get one specific query)
            const previousData = client.getQueryData<GetDesignReviewInfoQuery>(queryKey);
            // the update itself - for the matching query, synthesize updated data & set it in cache
            client.setQueryData(queryKey, (previous?: GetDesignReviewInfoQuery) => {
                return createOptimisticallyUpdatedDesignReviewInfoResponse(variables, previous);
            });
            // store the snapshot in query context
            // FIY the structure of query context is completely arbitrary, so "previousData" is chosen for clarity
            return { previousData };
        },
        // rollback the optimistic update by restoring the snapshot from previousData
        onError: (_error, _variables, context) => {
            if (!context) {
                return;
            }
            client.setQueryData(queryKey, context.previousData);
        },
    });
};
