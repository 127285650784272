import { useInfiniteStores } from '~/adapters/services/accounts/stores/hooks';
import { useFlattenPaginatedData } from '~/adapters/services/shared/useFlattenPaginatedData';
import { Loader } from '~/components/Loader/loader';
import { PageHeader } from '~/components/PageHeader/PageHeader';
import { usePage } from '~/adapters/browser/usePage';
import { useAccount } from '~/contexts/UserAccount/UserAccountContext';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { StoreList } from './StoreList';

export function Stores() {
    const { id: accountId } = useAccount();
    usePage({ title: 'Online Stores', reportPath: '/accounts/stores' });

    const { fetchNextPage, hasNextPage, isFetching, isLoading, data } = useInfiniteStores({ accountId });
    const stores = useFlattenPaginatedData(data, 'items');

    if (isLoading) {
        return <Loader title="Loading..." devLabel="stores API" />;
    }

    return (
        <ErrorBoundary errorMessage="An error occurred while loading your online stores">
            <PageHeader>Online Stores</PageHeader>
            <StoreList
                hasNextPage={!!hasNextPage}
                fetchNextPage={fetchNextPage}
                isFetching={isFetching}
                stores={stores}
            />
        </ErrorBoundary>
    );
}
