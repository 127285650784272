import { Button, Card, Tooltip, Typography } from '@customink/pigment-react';
import { CardActions, CardContent, CardHeader, CardMedia } from '@mui/material';
import { useLinks } from '~/contexts/LinkContext';
import { Art } from '~/adapters/services/accounts/arts/types';
import { sendAddToProduct } from '~/adapters/services/accounts/arts/tracking';
import { formatDate } from '~/adapters/date/date';
import { useState } from 'react';
import Spinner from '~/components/Spinner';
import styles from './ArtCard.module.css';
import { DeleteButton } from './DeleteButton/DeleteButton';
import { RenameButton } from './RenameButton/RenameButton';

interface Props {
    art: Art;
    deleted: boolean;
}

function createAddProductLink(addToProductUrl: string, referenceId: string): string {
    return `${addToProductUrl}/?U=${decodeURIComponent(referenceId).replace(/^\/uploads\//, '')}`;
}

export function ArtCard({ art, deleted }: Props) {
    const links = useLinks();
    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoaded = () => {
        setIsLoading(false);
    };

    return (
        <Card sx={{ position: 'relative', pb: 0.5 }}>
            <DeleteButton id={art.id} name={art.name} />
            <RenameButton name={art.name} id={art.id} />
            <CardMedia sx={{ pb: 1.5 }}>
                <div
                    style={{ height: '275px' /* empirically decided value */ }}
                    className={styles.backgroundTransparentPattern}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                        }}
                    >
                        {isLoading && <Spinner />}

                        <img
                            src={links.uploadServiceLink + art.referenceId}
                            style={{ maxWidth: '80%', maxHeight: '80%', display: isLoading ? 'none' : 'inherit' }}
                            alt={art.name ?? undefined}
                            onLoad={handleImageLoaded}
                        />
                    </div>
                </div>
            </CardMedia>
            <CardHeader title={art.name || <>&nbsp;</>} />
            <CardContent>
                {art.updatedAt && (
                    <Tooltip title={new Date(art.updatedAt).toLocaleString()} placement="top">
                        <Typography variant="uiTextSmall">Last updated on {formatDate(art.updatedAt)}</Typography>
                    </Tooltip>
                )}
            </CardContent>
            {!deleted && (
                <CardActions>
                    <Button
                        fullWidth
                        variant="secondary"
                        onClick={sendAddToProduct}
                        href={createAddProductLink(links.ndxAddProductLink, art.referenceId)}
                    >
                        Add to Product
                    </Button>
                </CardActions>
            )}
        </Card>
    );
}
