import { useLinks } from '~/contexts/LinkContext';

export const useStoresLinks = () => {
    const { storeLink } = useLinks();

    const createHealthAndWellnessLink = (secureId: number | string) => {
        return `${storeLink}/health-wellness/purchases/${secureId}`;
    };

    return {
        createHealthAndWellnessLink,
    };
};
