import { useCallback } from 'react';
import { useAuth } from '~/contexts/Auth/AuthContext';
import { Config } from '~/adapters/config/config';
import { name } from '../../../../../package.json';

export function useUserHeader() {
    const { identityProvider } = useAuth();
    const { getTokens, user } = useAuth();
    return useCallback(async () => {
        const header = new Headers();
        header.set('graphql-client-name', name);
        header.set('graphql-client-version', Config.VITE_RELEASE);
        if (!user?.isAuthenticated) {
            return header;
        }

        const { accessToken, idToken } = await getTokens();
        const token = identityProvider === 'auth0' ? accessToken : idToken;
        if (token) {
            header.set('Authorization', `Bearer ${token}`);
        }
        return header;
    }, [user, getTokens]);
}
