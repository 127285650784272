import { PWC } from '~/adapters/typescript/propsWithChildren';
import { useSignalmanFeatureFlag } from '~/contexts/FeatureFlagSignalmanContext';
import { ProfilesSessionGuard } from '~/contexts/Auth/Profiles/ProfilesSessionGuard';
import { AuthErrorBoundary } from '~/contexts/Auth/AuthErrorBoundary';
import { log } from '~/adapters/browser/localLogger';
import { ProfilesProvider } from './Profiles/ProfilesProvider';
import { Auth0ProviderWrapper } from './Auth0/Auth0ProviderWrapper';

const useIsAuth0Enabled = () => {
    const isSignalmanAuth0Enabled = useSignalmanFeatureFlag('auth0');

    if (import.meta.env.BASE_URL?.endsWith('dev.customink.com')) {
        return true;
    }
    return isSignalmanAuth0Enabled;
};

export const AuthProvider = ({ children }: PWC) => {
    const isAuth0Enabled = useIsAuth0Enabled();

    log.info('🔐 AuthProvider isAuth0Enabled', isAuth0Enabled);

    return (
        <AuthErrorBoundary>
            {isAuth0Enabled ? (
                <Auth0ProviderWrapper>{children}</Auth0ProviderWrapper>
            ) : (
                <ProfilesProvider>
                    <ProfilesSessionGuard>{children}</ProfilesSessionGuard>
                </ProfilesProvider>
            )}
        </AuthErrorBoundary>
    );
};
