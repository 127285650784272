import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Box } from '@customink/pigment-react';

export const UploadBox = styled(Box)(({ theme }) => ({
    border: 1,
    borderColor: theme.palette.grey[400],
    borderStyle: 'dashed',
    borderRadius: 6,
    textAlign: 'center',
    position: 'relative',
    padding: '1em 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
