import React from 'react';
import LoadingButton from './LoadingButton';
import ViewMoreButton from './ViewMoreButton';
import { SectionContent } from '../Layout/SectionContent';

interface Props {
    loading: boolean;
    onClick: () => void;
}

const LoadMoreButton: React.FC<Props> = ({ loading, onClick }) => {
    return (
        <SectionContent id="saved-designs-view-more-wrapper" onClick={onClick}>
            {loading ? <LoadingButton /> : <ViewMoreButton onClick={onClick} />}
        </SectionContent>
    );
};

export default LoadMoreButton;
