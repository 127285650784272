import { useMemo } from 'react';
import { PaginatedParams } from '~/adapters/services/shared/types/PaginatedParams';
import { offsetPaginationParams } from '~/adapters/services/shared/createParams';
import { transformToGroupOrder } from './transformers';
import { AccountsApiClient } from '../accountsApiClient';
import { useAccountsApiClient } from '../useAccountsApiClient';

export interface GetStoresProps {
    accountId: number;
}

const PER_PAGE = 6;

class StoresRepository {
    constructor(private readonly client: AccountsApiClient) {}

    public getHealthAndWellnessOrders = async ({ pageParam = 1, accountId }: PaginatedParams<GetStoresProps>) => {
        return this.client.getPaginated(
            `/accounts/{accountId}/stores/purchases/health-and-wellness`,
            {
                params: {
                    path: { accountId },
                },
            },
            pageParam,
            PER_PAGE,
        );
    };

    public getStores = async ({ pageParam = 1, accountId }: PaginatedParams<GetStoresProps>) => {
        const query = {
            ...offsetPaginationParams({ perPage: PER_PAGE, page: pageParam }),
        };

        const response = await this.client.getPaginated(
            `/accounts/{accountId}/stores`,
            {
                params: {
                    path: { accountId },
                    query,
                },
            },
            pageParam,
            PER_PAGE,
        );

        const userInvitationToken = response.headers.get('x-user-invitation-token');

        return {
            ...response,
            userInvitationToken,
        };
    };

    public getStoresGroupOrders = async ({ pageParam = 1, accountId }: PaginatedParams<GetStoresProps>) => {
        const query = {
            ...offsetPaginationParams({ perPage: PER_PAGE, page: pageParam }),
        };

        const response = await this.client.getPaginated(
            `/accounts/{accountId}/stores/gof`,
            {
                params: {
                    path: { accountId },
                    query,
                },
            },
            pageParam,
            PER_PAGE,
        );

        return {
            ...response,
            items: response.items.map((go) => ({ ...transformToGroupOrder(go), isStoreGroupOrder: true })),
        };
    };

    public getStoreGroupOrdersCount = async ({ accountId }: GetStoresProps): Promise<number> => {
        const params = { limit: 1, offset: 0 };
        const response = await this.client.getPaginated(
            `/accounts/{accountId}/stores/gof`,
            {
                params: {
                    path: { accountId },
                    query: params,
                },
            },
            1,
            1,
        );
        return response.totalCount;
    };
}

export const useStoresRepository = () => {
    const client = useAccountsApiClient();

    return useMemo(() => new StoresRepository(client), [client]);
};
