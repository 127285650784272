import { Button, Carousel, Stack } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Grid } from '@mui/material';
import { MouseEventHandler } from 'react';
import { useOrderLinks } from '~/adapters/services/accounts/orders/links';
import { useAuth } from '~/contexts/Auth/AuthContext';
import { OrderSummary } from '~/adapters/services/accounts/orders/types';
import { CarouselProductImage } from '~/components/Image/CarouselProductImage';
import { BoxVisibleAboveMd, BoxVisibleBelowMd } from '~/components/Visibility/VisibleBox';
import { OrderDetailToggle } from '../../../components/OrderDetailToggle';
import { AccountsPortalOrderState, getAccountsPortalOrderState } from '../../../lib/getAccountsPortalOrderState';
import { orderLinkTrackEvent } from '../../../tracking';

const fallbackActionsWidth = '300px';
const fallbackCarouselWidth = 200;

interface OrderContentRowProps {
    order: OrderSummary;
    detailOpen: boolean;
    toggle: MouseEventHandler<HTMLElement>;
    singleDetail: boolean;
}

const OrderContent = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        padding: theme.spacing(2),
    },
}));

const OrderActions = styled(Stack)(({ theme }) => ({
    width: fallbackActionsWidth,
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: theme.spacing(2),
    },
}));

const OrderContentGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5),
    },
}));

const FORTNIGHT_IN_MILLISECONDS = 12096e5;

export function OrderContentRow({ order, detailOpen, singleDetail, toggle }: OrderContentRowProps) {
    const { isImpersonatedExperience } = useAuth();
    const orderLinks = useOrderLinks(order);
    const slides = order.proofs.map((uri) => <CarouselProductImage alt="Product preview" src={uri} key={uri} />);

    const isDeliveredLastFortnight =
        order.deliveredAt && new Date(order.deliveredAt) > new Date(Date.now() - FORTNIGHT_IN_MILLISECONDS);
    const apOrderState = getAccountsPortalOrderState(order);

    const isOrderInProgress = apOrderState === AccountsPortalOrderState.InProgress;

    return (
        <OrderContent>
            <OrderContentGrid container>
                <Grid item sx={{ width: '50%', maxWidth: fallbackCarouselWidth }}>
                    <Carousel slides={slides} sliderHeight="auto" loop={false} />
                </Grid>
                <Grid
                    item
                    sx={{
                        px: 2,
                    }}
                >
                    <Stack justifyContent="space-between" sx={{ height: '100%' }}>
                        <Stack direction="column" gap={1}>
                            <div>Design Name</div>
                            <b>{order.designName}</b>
                            <div>Quantity</div>
                            <b>{order.quantity}</b>
                        </Stack>
                        <BoxVisibleAboveMd>
                            <OrderDetailToggle singleDetail={singleDetail} toggle={toggle} open={detailOpen} />
                        </BoxVisibleAboveMd>
                    </Stack>
                </Grid>
            </OrderContentGrid>
            <OrderActions spacing={2}>
                {isDeliveredLastFortnight && (
                    <Button
                        variant="primary"
                        href={orderLinks.trackOrderUrl}
                        onClick={(event) => orderLinkTrackEvent(event, 'track shipment')}
                    >
                        Track Shipment
                    </Button>
                )}
                {isOrderInProgress && (
                    <Button
                        variant={isDeliveredLastFortnight ? 'secondary' : 'primary'}
                        href={orderLinks.trackOrderUrl}
                        onClick={(event) => orderLinkTrackEvent(event, 'order status')}
                    >
                        Order Status
                    </Button>
                )}
                <Button
                    variant={isOrderInProgress || isDeliveredLastFortnight ? 'secondary' : 'primary'}
                    href={orderLinks.reorderUrl}
                    onClick={(event) => orderLinkTrackEvent(event, 'reorder')}
                >
                    {isImpersonatedExperience ? 'Internal Reorder' : 'Reorder'}
                </Button>
                <Button
                    variant="secondary"
                    href={orderLinks.designUrl}
                    onClick={(event) => orderLinkTrackEvent(event, 'view design')}
                >
                    {isImpersonatedExperience ? 'Internal Checkout' : 'View Design'}
                </Button>
                <Button
                    variant="secondary"
                    href={orderLinks.invoiceUrl}
                    onClick={(event) => orderLinkTrackEvent(event, 'view design invoice')}
                >
                    View Invoice
                </Button>
                <BoxVisibleBelowMd>
                    <OrderDetailToggle toggle={toggle} open={detailOpen} singleDetail={singleDetail} />
                </BoxVisibleBelowMd>
            </OrderActions>
        </OrderContent>
    );
}
