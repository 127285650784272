import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import { Box, Button, Carousel, Grid, Link, Stack } from '@customink/pigment-react';
import { format } from 'date-fns';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { DesignsPath } from '~/config';
import { Title } from '~/pages/shared/title';
import { usePage } from '~/adapters/browser/usePage';
import { useOptionalAuthUser } from '~/contexts/Auth/AuthContext';
import { useAddToCartOrRedirect } from '~/adapters/services/rfe/hooks';
import { useDesignSummary } from '~/adapters/services/accounts/designs/hooks';
import { parseProductNameAndColor } from '~/adapters/services/accounts/designs/transformer';
import { productCarouselImages } from '~/adapters/services/mms/links';
import { CarouselProductImage } from '~/components/Image/CarouselProductImage';
import { FullLayoutLoader } from '~/components/Layout/FullLayoutLoader';
import { ButtonLink } from '~/components/Link/Link';
import setLocation from '~/adapters/browser/setLocation';
import { checkout } from '~/adapters/services/rfe/links';
import { useEditDesignLink, useFundraiserLink, useGroupOrdersLink } from '../links';
import { SharingAPIProvider } from '../SharingAndCollab/SharingAPIContext';
import { SharingDialog } from '../SharingAndCollab/SharingDialog';
import { DeleteDialog } from '../components/DeleteDialog';
import { DesignPrice } from '../components/DesignPrice';
import { DesignDetailCard } from './DesignDetailCard';
import { SharingCard } from './SharingCard';
import { DesignDetailNotFound } from './DesignDetailNotFound';
import { useDesignDetailParams } from './useDesignDetailParams';

const DesignDetailHeaderRow = styled(Stack)(({ theme }) => ({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

export function DesignDetail() {
    usePage({ title: Title, reportPath: '/accounts/saved_designs' });

    // compositeId is guaranteed, because the route doesn't match without a truthy id provided
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const cid = useDesignDetailParams().compositeId!;

    const navigate = useNavigate();
    const editDesignLink = useEditDesignLink(cid);
    const groupOrdersLink = useGroupOrdersLink(cid);
    const fundraiserLink = useFundraiserLink(cid);
    const addToCartOrRedirect = useAddToCartOrRedirect();

    const navigateToDesigns = () => navigate('..');
    const [buyWaiting, setBuyWaiting] = useState(false);
    const handleAddToCart = useCallback(async () => {
        setBuyWaiting(true);
        await addToCartOrRedirect([cid]);
        setLocation(checkout());
        setBuyWaiting(false);
    }, [cid, addToCartOrRedirect]);

    const { data: designData, isLoading } = useDesignSummary(cid);

    const authUser = useOptionalAuthUser();
    const isMyDesign = authUser?.isAuthenticated && authUser.email === designData?.email;

    const [sharingDialogVisible, setSharingDialogVisible] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

    const openSharingDialog = useCallback(() => setSharingDialogVisible(true), []);
    const closeSharingDialog = useCallback(() => setSharingDialogVisible(false), []);
    const openDeleteDialog = useCallback(() => setDeleteDialogVisible(true), []);
    const closeDeleteDialog = useCallback(() => setDeleteDialogVisible(false), []);

    const carouselImages = useMemo(() => {
        if (!designData) {
            return [];
        }
        const carouselImageUrls = productCarouselImages(
            designData.products.map((p) => ({ ...p, compositeId: designData.compositeId })),
        );
        return carouselImageUrls.map((uri) => <CarouselProductImage alt="Product preview" src={uri} key={uri} />);
    }, [designData]);

    const modifiedDate = useMemo(
        () => (designData?.updatedAt ? format(new Date(designData.updatedAt), 'MMM d, yyyy') : null),
        [designData],
    );

    if (isLoading) {
        // FullLayoutLoader does not fit the DesignDetail page layout
        // TODO after UX finalizes the page design, create a custom layout loader, or use a LayoutLoader for Feedback page when it is created, which will fit well
        return <FullLayoutLoader devLabel="RFE design API" />;
    }
    if (!designData) {
        return <DesignDetailNotFound />;
    }

    const { designName } = designData;
    const [productName, productColor] = parseProductNameAndColor(designData.products[0].productNameAndColor);

    return (
        <SharingAPIProvider cid={cid}>
            <DesignDetailHeaderRow>
                <Typography variant="heading3">{designName}</Typography>
                <Stack spacing={2} direction="row">
                    <ButtonLink variant="secondary" to={DesignsPath}>
                        See all Saved Designs
                    </ButtonLink>
                    {isMyDesign && (
                        <Button variant="secondary" href={editDesignLink}>
                            Edit Design
                        </Button>
                    )}
                    <Button variant="primary" onClick={handleAddToCart} disabled={buyWaiting}>
                        {buyWaiting ? <CircularProgress color="secondary" size={24} /> : 'Add to Cart'}
                    </Button>
                </Stack>
            </DesignDetailHeaderRow>
            <Grid container spacing={4}>
                <Grid item sm={12} md={6}>
                    <Box sx={{ img: { borderRadius: 2 } }}>
                        <Carousel
                            slides={carouselImages}
                            sliderHeight="auto"
                            loop={false}
                            onSlideClick={handleAddToCart}
                        />
                    </Box>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Stack direction="column" spacing={2}>
                        <DesignDetailCard>
                            <Box>
                                <Typography variant="uiTextSmall" paragraph>
                                    Last Modified: {modifiedDate}
                                </Typography>
                                <Typography variant="uiTextSmall" paragraph>
                                    {productName}
                                    <br />
                                    {productColor}
                                    {isMyDesign && <DesignPrice cid={cid} />}
                                </Typography>
                            </Box>
                        </DesignDetailCard>
                        <DesignDetailCard>
                            <Link variant="buttonLg" href={groupOrdersLink}>
                                Set up Group Order
                            </Link>
                            <Typography variant="captionRegular" pb={1}>
                                Collect sizes. Split payments. Ship individually or together.
                            </Typography>
                            <Link variant="buttonLg" href={fundraiserLink}>
                                Start a Fundraiser
                            </Link>
                            <Typography variant="captionRegular" pb={1}>
                                Raise money by selling this design online.
                            </Typography>
                            {isMyDesign && (
                                // component="button" does render a HTML <button> element, so eslint is mistaken
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <Link component="button" variant="buttonLg" onClick={openDeleteDialog}>
                                    Delete Design
                                </Link>
                            )}
                        </DesignDetailCard>
                        {isMyDesign && <SharingCard cid={cid} openSharingDialog={openSharingDialog} />}
                    </Stack>
                </Grid>
            </Grid>
            <SharingDialog designName={designName} open={sharingDialogVisible} onClose={closeSharingDialog} />
            {isMyDesign && (
                <DeleteDialog
                    name={designName}
                    cid={cid}
                    open={deleteDialogVisible}
                    onClose={closeDeleteDialog}
                    onSuccess={navigateToDesigns}
                />
            )}
        </SharingAPIProvider>
    );
}
