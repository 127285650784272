import { ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

export const slope = 30; // ms/character
export const intercept = 2000; // ms
export const maxTimeout = 10_000; // ms

/**
 * Calculate the timeout for the notification using a linear formula:
 * timeout = slope * textLength + intercept
 * @param message ReactNode which will be evaluated for total length of text nodes
 * @returns calculated timeout in ms
 */
export const calculateNotificationTimeout = (message: ReactNode) => {
    const JSXFromMessage = <>{message}</>;
    const staticMarkup = renderToStaticMarkup(JSXFromMessage);
    const parser = new DOMParser();
    const parsed = parser.parseFromString(staticMarkup, 'text/html');
    const text = parsed.body.textContent ?? '';
    const calculatedTimeout = slope * text.length + intercept;
    return Math.min(calculatedTimeout, maxTimeout);
};
