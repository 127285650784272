import { useEffect, useState } from 'react';
import { useTrackPage } from '~/adapters/tracking/useTrackPage';
import { useOptionalAuthUser } from '~/contexts/Auth/AuthContext';
import { usePersonalAccount } from '~/adapters/services/accounts/accounts/hooks';
import { createHexadecimalHash } from '~/adapters/tracking/accountCompoundId';

/**
 * Set the title and report to ga
 * title will be prefixed with Custom Ink -
 * So it will look like Custom Ink - $HERE_GOES_YOUR_TITLE
 * @param title
 */

interface PageProps {
    title: string;
    reportPath: string;
}

export function usePage({ title, reportPath }: PageProps) {
    const user = useOptionalAuthUser();
    const { data: personalAccount } = usePersonalAccount();
    const [accountCompoundId, setAccountCompoundId] = useState<string | undefined>(undefined);

    useEffect(() => {
        const initAccountCompoundId = async () => {
            if (!personalAccount || !user?.email) {
                setAccountCompoundId('');
                return;
            }

            const hexEmail = await createHexadecimalHash(user.email);
            setAccountCompoundId(`${personalAccount.id}-${hexEmail.substring(0, 5)}`);
        };
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        initAccountCompoundId();
    }, [user, personalAccount]);

    useEffect(() => {
        document.title = `Custom Ink - ${title}`;
    }, [title]);

    useTrackPage(title, reportPath, personalAccount?.id ?? null, accountCompoundId);
}
