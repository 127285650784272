import { components } from '../schema';

export type CertificateStatus = components['schemas']['EcmCertificateDto']['cinkStatus'];

export type ECMCertificateDto = components['schemas']['EcmCertificateDto'];

export type ECMCertificate = ReturnType<typeof transformECMCertificateDto>;

export function transformECMCertificateDto({ status, ...certificate }: ECMCertificateDto) {
    return {
        ...certificate,
        attachmentUrl: certificate.attachmentUrl ?? undefined,
        thumbnailUrl: certificate.thumbnailUrl ?? undefined,
        expirationDate: certificate.expirationDate ? new Date(certificate.expirationDate) : undefined,
        signedDate: certificate.signedDate ? new Date(certificate.signedDate) : undefined,
        createdAt: new Date(certificate.createdAt),
        updatedAt: new Date(certificate.updatedAt),
    };
}

export type CreateCustomerParams = components['schemas']['CreateEcmCustomerDto'];
export type UpdateCustomerParams = components['schemas']['UpdateEcmCustomerDto'];

export type ECMCustomerDto = components['schemas']['EcmCustomer'];

export type ECMBusinessAddressDto = components['schemas']['EcmBusinessAddress'];

export type BusinessAddress = components['schemas']['EcmBusinessAddress'];
export type BusinessAddressWName = BusinessAddress & { organizationName: string };
