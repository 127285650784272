interface Pagination {
    page?: number;
    perPage?: number;
}

export interface OffsetPaginationParams {
    limit?: number;
    offset?: number;
}

export function offsetPaginationParams(pagination: Pagination): OffsetPaginationParams {
    const pageBase = pagination.page ?? 1;
    return {
        limit: pagination.perPage,
        offset: pagination.perPage ? (pageBase - 1) * pagination.perPage : undefined,
    };
}
export interface SortParams {
    sort: string;
}
