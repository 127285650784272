import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PigmentThemeProvider } from '@customink/pigment-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { AuthProvider } from '~/contexts/Auth/AuthProvider';
import { AmendedThemeProvider } from '~/adapters/designSystem/AmendedThemeProvider';
import { FullLayoutLoader } from '~/components/Layout/FullLayoutLoader';
import { Config } from '~/adapters/config/config';
import { NotistackProvider } from '~/adapters/notistack/NotistackProvider';
import { SignalerProvider } from '~/contexts/SignalerContext';
import { FeatureFlagSignalmanProvider } from './contexts/FeatureFlagSignalmanContext';
import { LinkProvider } from './contexts/LinkContext';
import { ModalStateProvider } from './contexts/ModalStateContext';

const client = new QueryClient({
    defaultOptions: {
        queries: { refetchOnWindowFocus: false, networkMode: 'offlineFirst' },
        mutations: { networkMode: 'offlineFirst' },
    },
});

const ReactQueryProviders = ({ children }: PWC) => (
    <Suspense fallback={<FullLayoutLoader devLabel="ReactQuery Suspense" />}>
        <QueryClientProvider client={client}>
            {Config.MODE === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
            {children}
        </QueryClientProvider>
    </Suspense>
);

export function AppProviders({ children }: PWC) {
    return (
        <SignalerProvider>
            <PigmentThemeProvider>
                <AmendedThemeProvider>
                    <NotistackProvider />
                    <ReactQueryProviders>
                        <FeatureFlagSignalmanProvider>
                            <LinkProvider>
                                <AuthProvider>
                                    <ModalStateProvider>{children}</ModalStateProvider>
                                </AuthProvider>
                            </LinkProvider>
                        </FeatureFlagSignalmanProvider>
                    </ReactQueryProviders>
                </AmendedThemeProvider>
            </PigmentThemeProvider>
        </SignalerProvider>
    );
}
