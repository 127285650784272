import { FC, PropsWithChildren, ReactNode } from 'react';
import { Dialog, DialogContent, Stack, useMobileBreakpoint } from '@customink/pigment-react';
import { Menu } from '@mui/material';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { useModalStateContext } from '~/contexts/ModalStateContext';

type ResponsiveMenuProps = PropsWithChildren<{
    anchorElement: HTMLElement | null;
    handleClose: EmptyFunction;
    title?: ReactNode;
    menuAriaLabel?: string;
}>;

/**
 * A responsive menu that will render a Menu on desktop and a bottomSheet Dialog on mobile.
 * @param children A collection of ResponsiveMenuItem components is expected for proper behavior.
 */
export const ResponsiveMenu: FC<ResponsiveMenuProps> = ({
    children,
    anchorElement,
    handleClose,
    title,
    menuAriaLabel,
}) => {
    const isMobile = useMobileBreakpoint();
    const { isAnyTutorialOpen } = useModalStateContext();

    return isMobile ? (
        <Dialog open={Boolean(anchorElement)} onClose={handleClose} title={title} mobileVariant="bottomSheet">
            <DialogContent pt={2}>
                <Stack gap={3}>{children}</Stack>
            </DialogContent>
        </Dialog>
    ) : (
        <Menu
            open={Boolean(anchorElement)}
            onClose={handleClose}
            anchorEl={anchorElement}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuAriaLabel}
            MenuListProps={{ 'aria-labelledby': menuAriaLabel }}
            // normally, a Modal element such as Menu should lock scrolling. But that can change viewport width when scrollbar vanishes.
            // Tutorial is unfortunately positioned from right, and it would snap, so leave the menu scrollable when Tutorial is shown
            disableScrollLock={isAnyTutorialOpen}
        >
            {children}
        </Menu>
    );
};
