import { useMemo, useState } from 'react';
import { processInputToEmails } from '~/components/input/processInputToEmails';

export const useMultipleEmailInput = (initialValue: Array<string> = []) => {
    const [emails, setEmails] = useState<Array<string>>(initialValue);
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const parsingResult = useMemo(() => processInputToEmails(inputValue, emails), [inputValue, emails]);

    return {
        multipleEmailInputProps: {
            emails,
            inputValue,
            errorMessage,
            setEmails,
            setInputValue,
            setErrorMessage,
        },
        parsingResult,
    };
};
