import { useTheme } from '@mui/material';
import { CertificateStatus, ECMCertificate } from '~/adapters/services/accounts/taxExemptionsECM/types';
import { useMemo } from 'react';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';

interface StatusAdornment {
    color: string;
    tooltipMessage?: string;
}

export const useStatusAdornments = (certificate: ECMCertificate): StatusAdornment => {
    // the theme is needed for MUI color overriding. Using just styles or sx won't work.
    const theme = useTheme();
    const map = useMemo(
        () =>
            ({
                valid: {
                    color: theme.palette.success.main,
                    tooltipMessage: 'You are able to make qualifying tax-exempt purchases in this state.',
                },
                pending: {
                    color: designTokens.color.status.warning.value,
                    tooltipMessage:
                        'Your tax exemption status is waiting for verification. Please review your application in the portal.',
                },
                expired: {
                    color: theme.palette.error.main,
                    tooltipMessage:
                        'This certificate is no longer valid. Please add a new certificate to update your tax-exempt status.',
                },
                invalid: {
                    color: theme.palette.error.main,
                    tooltipMessage:
                        'This certificate is invalid. If you think this is an error, start a new application in the portal.',
                },
                revoked: {
                    color: theme.palette.error.main,
                    tooltipMessage:
                        'This certificate has been revoked. Please add a new certificate to update your tax-exempt status.',
                },
            } satisfies Record<CertificateStatus, StatusAdornment>),
        [theme],
    );
    return map[certificate.cinkStatus];
};
