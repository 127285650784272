import { createContext, useContext, useMemo } from 'react';
import { createContextualCan } from '@casl/react';
import { Ability, subject } from '@casl/ability';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { SharingRole } from '~/adapters/services/collaboration/graphqlTypes';
import { defineAbilityForRole, Resource as ResourceKey, ResourceActions } from './collaborationAbility';

interface Resource {
    myRole?: SharingRole;
}

export const AbilityContext = createContext<Ability>(new Ability());
export const ResourceContext = createContext<Resource | null>(null);

const InnerCan = createContextualCan(AbilityContext.Consumer);

interface CanProps {
    I: ResourceActions;
}

export function Can({ I, children }: PWC<CanProps>) {
    const resource = useContext(ResourceContext);

    if (!resource) {
        throw new Error('Ability Can has to be used inside resource context');
    }
    return (
        <InnerCan do={I} on={subject(ResourceKey, resource)}>
            {children}
        </InnerCan>
    );
}

interface CollaborationAbilityProviderProps {
    resource: Resource;
}

export function CollaborationAbilityProvider({ children, resource }: PWC<CollaborationAbilityProviderProps>) {
    const abilityForResourceRole = useMemo(
        () => defineAbilityForRole(resource?.myRole ?? SharingRole.Commenter),
        [resource],
    );
    return (
        <AbilityContext.Provider value={abilityForResourceRole}>
            <ResourceContext.Provider value={resource}>{children}</ResourceContext.Provider>
        </AbilityContext.Provider>
    );
}
