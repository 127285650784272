import { PropsWithChildren, useEffect, useState } from 'react';
import { useQueryParam } from '~/adapters/react-router/useQueryParam';
import { FullLayoutLoader } from '~/components/Layout/FullLayoutLoader';
import { useAuth } from '~/contexts/Auth/AuthContext';

export function ProtectedCodeOrUser({ children }: PropsWithChildren<unknown>) {
    const accessCode = useQueryParam('code');
    const { user, loginWithRedirect } = useAuth();
    const [isWaitingOnAuthentication, setIsWaitingOnAuthentication] = useState(true);

    useEffect(() => {
        if (accessCode || user) {
            setIsWaitingOnAuthentication(false);
            return;
        }

        loginWithRedirect();
    }, [user, accessCode]);

    if (isWaitingOnAuthentication) {
        return <FullLayoutLoader devLabel="ProtectedCodeOrUser (awaiting auth)" />;
    }

    return <>{children}</>;
}
