import { Button } from '@customink/pigment-react';
import { CushionedBox } from '~/components/Layout/CushionedBox';

interface Props {
    groupsLink: string;
}

export function EmptyGroupList({ groupsLink }: Props) {
    return (
        <CushionedBox>
            <p data-testid="empty group_order list">
                You have no Group Orders.
                <br />
                Make collecting sizes and
                <br />
                payments super easy.
            </p>
            <Button href={groupsLink}>Learn More</Button>
        </CushionedBox>
    );
}
