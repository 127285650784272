import { ScreenReadersOnly } from '@customink/pigment-react';
import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import LoadMoreButton from '~/components/LoadMoreButton';
import { useScrollLoad } from '~/adapters/browser/useScrollLoad';
import CardWidthBox from '~/components/Layout/CardWidthBox';
import { Art } from '~/adapters/services/accounts/arts/types';
import { SectionContent } from '~/components/Layout/SectionContent';
import { OldCardThemeProvider } from '~/components/OldCard/OldCardThemeProvider';
import Upload from './Upload';
import { UploadBox } from './Upload/UploadBox';
import { ArtCard } from './ArtCard';

interface Props {
    cards: Array<Art>;
    onLoadMore: () => void;
    loading: boolean;
    hasMorePages: boolean;
}

export const UploadCard = styled(UploadBox)(({ theme }) => ({
    height: '459.8px' /* this value is manually based on the height of other upload cards */,
    backgroundColor: theme.palette.grey[200],
}));

export function ArtsCardList(props: PropsWithChildren<Props>) {
    const { cards, onLoadMore, loading, hasMorePages } = props;

    const ref = useScrollLoad({
        load: onLoadMore,
        enabled: !loading && hasMorePages,
    });

    return (
        <>
            <ScreenReadersOnly>
                <h2>Saved Designs</h2>
            </ScreenReadersOnly>
            <SectionContent>
                <div style={{ display: 'flex', flexFlow: 'row wrap', gap: '1.5rem' }} ref={ref}>
                    <CardWidthBox>
                        <UploadCard>
                            <Upload />
                        </UploadCard>
                    </CardWidthBox>
                    <OldCardThemeProvider>
                        {cards.map((art) => (
                            <CardWidthBox key={art.id}>
                                <ArtCard art={art} deleted={false} />
                            </CardWidthBox>
                        ))}
                    </OldCardThemeProvider>
                </div>
                {hasMorePages && <LoadMoreButton onClick={onLoadMore} loading={loading} />}
            </SectionContent>
        </>
    );
}
