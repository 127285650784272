import { Box, Stack, Typography } from '@customink/pigment-react';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { FeedbackLabel } from '~/pages/Feedback/components/FeedbackLabel';
import { FeedbackColumn } from '~/pages/Feedback/components/FeedbackColumn';
import { FeedbackText } from '~/pages/Feedback/components/FeedbackText';
import { DesignStatus, DesignStatusEnum } from '~/adapters/services/collaboration/graphqlTypes';
import { SharingStatus } from '~/pages/SavedDesigns/SharingAndCollab/components/SharingStatus';
import { designStatusEnumToSharingStatusType } from '~/pages/SavedDesigns/SharingAndCollab/mappers';

interface FeedbackStatusProps {
    designStatus?: Partial<DesignStatus>;
}

export function FeedbackStatus({ designStatus }: FeedbackStatusProps) {
    const { status = DesignStatusEnum.Shared, updatedAt = new Date() } = designStatus ?? {};
    const modifiedDate = useMemo(() => format(new Date(updatedAt), 'MMM d, yyyy'), [updatedAt]);
    const sharingStatus = useMemo(() => designStatusEnumToSharingStatusType(status), [status]);
    return (
        <Box>
            <Typography variant="subHeading" sx={{ pt: 0 }}>
                Summary
            </Typography>
            <Stack direction="row" gap={2} pt={1}>
                <FeedbackColumn>
                    <FeedbackLabel>Status</FeedbackLabel>
                    <FeedbackText>
                        <SharingStatus status={sharingStatus} />
                    </FeedbackText>
                </FeedbackColumn>
                <FeedbackColumn>
                    <FeedbackLabel>Last Modified</FeedbackLabel>
                    <FeedbackText>{modifiedDate}</FeedbackText>
                </FeedbackColumn>
            </Stack>
        </Box>
    );
}
