import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Chip } from '@customink/pigment-react';

export const UpperCaseChip = styled(Chip)(() => ({
    textTransform: 'uppercase',
}));

export const UpperCaseChipWithHover = styled(UpperCaseChip)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.grey[300],
        cursor: 'pointer',
    },
}));
