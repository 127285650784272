import { trackEvent } from '~/adapters/tracking/tracking';

const artsCategory = 'Arts';

export function sendArtsUploadEvent(label: string): void {
    trackEvent({ category: artsCategory, action: 'Upload', label });
}

export function sendArtsDeleteEvent(label: string): void {
    trackEvent({ category: artsCategory, action: 'Delete', label });
}

export function sendArtsRenameEvent(label: string): void {
    trackEvent({ category: artsCategory, action: 'Rename', label });
}

export function sendAddToProduct(): void {
    trackEvent({ category: artsCategory, action: 'Link Clicked', label: 'Add To Product' });
}
