import { AccountsApiClient } from '~/adapters/services/accounts/accountsApiClient';
import { useAccountsApiClient } from '~/adapters/services/accounts/useAccountsApiClient';
import { useMemo } from 'react';
import { normalizeUser } from '~/adapters/services/accounts/users/transformers/userTransformer';
import { transformAccountDtoToAccount } from '~/contexts/UserAccount/account';
import type { components, paths } from '../schema';

export type GetUsersParams = paths['/users']['get']['parameters']['query'];

export type UpdateUserDataProps = components['schemas']['UpdateUserDto'] & {
    userId: number;
};

class UsersRepository {
    constructor(private readonly client: AccountsApiClient) {}

    public getUserData = async (params: GetUsersParams) => {
        const response = await this.client.get(`/users`, {
            params: {
                query: params,
            },
        });
        return normalizeUser(response[0]);
    };

    public getUserAccounts = async (userId: number) => {
        const data = await this.client.get(`/users/{id}/accounts`, {
            params: {
                path: {
                    id: userId,
                },
            },
        });
        return data.map(transformAccountDtoToAccount);
    };

    public updateUserData = async ({ userId, ...body }: UpdateUserDataProps) => {
        const response = await this.client.put(`/users/{id}`, {
            params: {
                path: {
                    id: userId,
                },
            },
            body,
        });
        return normalizeUser(response);
    };
}

export const useUsersRepository = () => {
    const client = useAccountsApiClient();

    return useMemo(() => new UsersRepository(client), [client]);
};
