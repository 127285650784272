import { useCallback, useMemo } from 'react';
import { MenuItem, Select } from '@customink/pigment-react';
import { SharingRole, SharingUser } from '~/adapters/services/collaboration/graphqlTypes';
import { useSharingAPI } from '../SharingAPIContext';
import { PermissionOptions } from '../types';
import { PermissionMapper, SharingRoleMapper } from '../mappers';
import { sendSharingDialogEvent } from '../sendSharingDialogEvent';

interface RecipientAccessProps {
    email: string;
    role: SharingRole;
}

const removeEmailShare = (emailShares: SharingUser[], email: string): SharingUser[] =>
    emailShares.filter((item) => item.email !== email);

const changeEmailShare = (emailShares: SharingUser[], email: string, newRole: SharingRole): SharingUser[] =>
    emailShares.map((item) => {
        if (item.email === email) {
            return {
                email: item.email,
                role: newRole,
            };
        }
        return item;
    });

export function UserRoleSelect({ email, role }: RecipientAccessProps) {
    const { emailShares, setEmailSharing } = useSharingAPI();
    const initialValue = SharingRoleMapper(role);

    const handlePermissionClick = useCallback(
        (newPermission: PermissionOptions) => {
            sendSharingDialogEvent('Select Change', 'Email sharing recipient permission');
            setEmailSharing(changeEmailShare(emailShares, email, PermissionMapper[newPermission]));
        },
        [emailShares, email],
    );
    const handleRemoveAccessClick = useCallback(() => {
        sendSharingDialogEvent('Select Change', 'Email sharing recipient remove', -1);
        setEmailSharing(removeEmailShare(emailShares, email));
    }, [emailShares, email]);

    const permissionMenuItems = useMemo(() => {
        const permissionOptions: PermissionOptions[] = ['view', 'comment', 'edit'];
        return permissionOptions.map((item) => (
            <MenuItem key={item} value={item} onClick={() => handlePermissionClick(item)}>
                Can {item}
            </MenuItem>
        ));
    }, [handlePermissionClick]);

    return (
        <Select value={initialValue}>
            {permissionMenuItems}
            <MenuItem onClick={handleRemoveAccessClick}>Remove</MenuItem>
        </Select>
    );
}
