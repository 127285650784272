import { lazy, Suspense, useCallback, useEffect, useMemo } from 'react';
import { Tab, Tabs, useMobileBreakpoint } from '@customink/pigment-react';
import { PageHeader } from '~/components/PageHeader/PageHeader';
import { useRouteTabs } from '~/components/hooks/useRouteTabs';
import {
    AccountDetailsPath,
    AccountPathsGroup,
    PersonalSettingsPath,
    PostalAddressesPath,
    TaxExemptionPath,
    TeamMembersPath,
} from '~/config';
import { SectionContent } from '~/components/Layout/SectionContent';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { CardListLoader } from '~/components/Layout/CardListLoader';
import { AccountDetailsTab } from '~/pages/Settings/tabs/AccountDetails/AccountDetailsTab';
import { useAccount } from '~/contexts/UserAccount/UserAccountContext';
import { MembersTab } from '~/pages/Settings/tabs/Members/MembersTab';
import { Config } from '~/adapters/config/config';
import { TaxExemptionTab as TaxExemptionEcmTab } from './tabs/TaxExemptionECM/TaxExemptionTab';
import { ProfileTab } from './tabs/Profile/ProfileTab';

const LazyPostalAddressesTab = lazy(() => import('./tabs/PostalAddresses/PostalAddressesTab'));

function useCanRenderPath() {
    const isTeamAccountsFeatureFlagEnabled = useFeatureFlagEnabled('team_accounts_v0');

    const { personal: isAccountPersonal } = useAccount();

    return useCallback(
        (path: (typeof AccountPathsGroup)[number]) => {
            if (path === PersonalSettingsPath) {
                return { can: isAccountPersonal || !isTeamAccountsFeatureFlagEnabled, redirectTo: AccountDetailsPath };
            }
            if (path === AccountDetailsPath || path === TeamMembersPath) {
                return {
                    can: !isAccountPersonal && isTeamAccountsFeatureFlagEnabled,
                    redirectTo: PersonalSettingsPath,
                };
            }
            return { can: true, redirectTo: undefined };
        },
        [isAccountPersonal, isTeamAccountsFeatureFlagEnabled],
    );
}

export function Account() {
    const [selectedPath, selectPath] = useRouteTabs(...AccountPathsGroup);
    const canRender = useCanRenderPath();

    const { can: canRenderSelectedPath, redirectTo } = canRender(selectedPath);
    useEffect(() => {
        if (!canRenderSelectedPath && redirectTo) {
            selectPath(redirectTo);
        }
    }, [redirectTo, canRenderSelectedPath, selectPath]);

    const isMobile = useMobileBreakpoint();
    // There's been a problem on ArgosCI where the indicator was rendering different width each time, so we're hiding it for now to make argosci builds more reliable
    const hideIndicator = useMemo(() => Config.MODE === 'test' && isMobile, [isMobile]);

    return (
        <>
            <PageHeader>Account Settings</PageHeader>
            <ErrorBoundary errorMessage="An error ocurred while loading your account settings">
                <Tabs
                    variant="scrollable"
                    scrollButtons={false}
                    value={canRenderSelectedPath ? selectedPath : redirectTo}
                    onChange={(_, val) => selectPath(val)}
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                    TabIndicatorProps={{ sx: { display: hideIndicator ? 'none' : undefined } }}
                >
                    {canRender(PersonalSettingsPath).can && <Tab label="Profile" value={PersonalSettingsPath} />}
                    {canRender(AccountDetailsPath).can && <Tab label="Details" value={AccountDetailsPath} />}
                    {canRender(TeamMembersPath).can && <Tab label="Members" value={TeamMembersPath} />}
                    {/* <Tab label="Payment Methods" value={PaymentsPath} /> */}
                    <Tab label="Tax Exemption" value={TaxExemptionPath} />
                    <Tab label="Addresses" value={PostalAddressesPath} />
                </Tabs>
                <SectionContent>
                    {canRender(PersonalSettingsPath).can && selectedPath === PersonalSettingsPath && <ProfileTab />}
                    {canRender(AccountDetailsPath).can && selectedPath === AccountDetailsPath && <AccountDetailsTab />}
                    {canRender(TeamMembersPath).can && selectedPath === TeamMembersPath && <MembersTab />}
                    {/* TODO if Payment Methods are to be enabled, the UI of HostedFieldDialog.tsx must be verified (Pigment Dialog was updated, but not tested in this particular case) */}
                    {/* {selectedPath === PaymentsPath && (
                        <Suspense fallback={<CardListLoader />}>
                            <LazyPaymentMethodsTab />
                        </Suspense>
                    )} */}
                    {selectedPath === TaxExemptionPath && <TaxExemptionEcmTab />}
                    {selectedPath === PostalAddressesPath && (
                        <Suspense fallback={<CardListLoader />}>
                            <LazyPostalAddressesTab />
                        </Suspense>
                    )}
                </SectionContent>
            </ErrorBoundary>
        </>
    );
}
