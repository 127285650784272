import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function usePreservedQueryParam() {
    const { search } = useLocation();

    const impersonate = useMemo(() => new URLSearchParams(search).get('impersonate'), [search]);
    const accountId = useMemo(() => new URLSearchParams(search).get('accountId'), [search]);

    return useMemo(() => {
        // non-empty query params
        const params = Object.entries({ impersonate, accountId }).filter(([, value]) => value);
        const queryParams = new URLSearchParams();
        params.forEach(([key, value]) => queryParams.set(key, value as string));

        if (params.length) {
            return `?${queryParams.toString()}`;
        }
        return '';
    }, [impersonate, accountId]);
}
