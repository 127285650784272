export const BaseContext = import.meta.env.ACCOUNTS_BASE_CONTEXT;

// All of the following paths are internal accounts-ui paths controlled by react-router

export const ArtsPath = `/arts` as const;
export const OrdersPath = `/orders` as const;
export const SingleOrderPath = `/order/:orderId` as const;
export const StoresPath = `/stores` as const;
export const FlagsPath = `/flags` as const;
export const PaymentsPath = `/payment_methods` as const;
export const PersonalSettingsPath = `/settings` as const;
export const TaxExemptionPath = `/tax_exemption` as const;
export const AccountDetailsPath = `/details` as const;
export const TeamMembersPath = `/members` as const;
export const PostalAddressesPath = `/addresses` as const;
export const AccountPathsGroup = [
    PersonalSettingsPath,
    AccountDetailsPath,
    TeamMembersPath,
    PaymentsPath,
    TaxExemptionPath,
    PostalAddressesPath,
];
export const DesignsPath = `/designs` as const;
export const SharedDesignsPath = `/shared_designs` as const;
export const FeedbackPath = `/feedback/:resourceId` as const;
export const GroupOrdersPath = `/group_orders` as const;
export const RedirectingTaxExemptionPath = 'redirect/tax-exemption-portal' as const;
