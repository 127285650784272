import { deepmerge } from '@mui/utils';
import { createTheme } from '@mui/material/styles';
import { ThemeOptions, ThemeProvider, useTheme } from '@mui/material';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import { useMemo } from 'react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

const gray = designTokens.color.gray.accent.value;
const amendedThemeOptions: ThemeOptions = {
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    // Autocomplete also overrides styles of Input, in this case TextField
                    // we want to make Autocomplete > TextField look like ordinary TextField
                    '&.MuiOutlinedInput-root': {
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                    },
                    '&.MuiOutlinedInput-root .MuiInputBase-input': {
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 12,
                    },
                },
                option: {
                    // the [aria-disabled="false"][role="option"] is needed to increase the specificity of that selector
                    // it is an unusual case, as the Theme should be the source of truth, but there is another MUI declaration targeting the same elements via a different selector
                    // we have not found out its source to overwrite it directly, so we are overriding it
                    '&[aria-selected="true"][aria-disabled="false"][role="option"]': { backgroundColor: gray },
                    '&.Mui-focused[aria-disabled="false"]': { backgroundColor: gray },
                },
            },
        },
    },
};

export function AmendedThemeProvider({ children }: PWC) {
    const theme = useTheme();
    const amendedTheme = useMemo(() => createTheme(deepmerge(theme, amendedThemeOptions)), [theme]);
    return <ThemeProvider theme={amendedTheme}>{children}</ThemeProvider>;
}
