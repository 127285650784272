import { PropsWithChildren } from 'react';
import LoadMoreButton from '~/components/LoadMoreButton';
import { GroupOrderWithOrigin } from '~/adapters/services/accounts/groupOrders/types';
import { useScrollLoad } from '~/adapters/browser/useScrollLoad';
import CardWidthBox from '~/components/Layout/CardWidthBox';
import { OldCardThemeProvider } from '~/components/OldCard/OldCardThemeProvider';
import { GroupOrderCard } from './GroupOrderCard';
import { EmptyGroupList } from './EmptyGroupOrderList';

interface Props {
    groupsLink: string;
    cards: GroupOrderWithOrigin[];
    onLoadMore: () => void;
    loading: boolean;
    hasMorePages?: boolean;
}

export function GroupOrderList(props: PropsWithChildren<Props>) {
    const { cards, onLoadMore, loading, hasMorePages, groupsLink } = props;
    const cardsList = cards || [];

    const ref = useScrollLoad({
        load: onLoadMore,
        enabled: !loading && hasMorePages,
    });

    return (
        <>
            <div style={{ display: 'flex', flexFlow: 'row wrap', gap: '1.5rem' }} ref={ref}>
                <OldCardThemeProvider>
                    {/* unfortunately, neither groupOrder.compositeId nor groupOrder.id are unique keys for map, even _without_ Store GOFs! */}
                    {/* eslint-disable react/no-array-index-key */}
                    {cardsList.map((groupOrder, index) => (
                        <CardWidthBox key={index}>
                            <GroupOrderCard groupOrder={groupOrder} />
                        </CardWidthBox>
                    ))}
                </OldCardThemeProvider>
            </div>
            {cardsList.length === 0 && <EmptyGroupList groupsLink={groupsLink} />}
            {hasMorePages && <LoadMoreButton onClick={onLoadMore} loading={loading} />}
        </>
    );
}
