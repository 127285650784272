import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getImpersonateQueryVariable } from '~/adapters/browser/getImpersonateQueryVariable';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { FullLayoutLoader } from '~/components/Layout/FullLayoutLoader';
import { setAuthRedirect } from '~/adapters/browser/session';
import { REDIRECTING_TO_LOGIN_ERROR } from '~/contexts/Auth/error';
import { useThrowToErrorBoundary } from '~/components/ErrorBoundary';
import { ProfilesClient } from '@customink/profiles-sso-spa-client';
import { getEnv } from '~/adapters/config/env';
import { useTrackIdentityProvider } from '~/adapters/tracking/useTrackIdentityProvider';
import { AuthContext, AuthContextData, AuthUser, getTargetUserInfo } from '../AuthContext';

export const ProfilesProvider = ({ children }: PWC) => {
    const profilesClient = useMemo(
        () =>
            new ProfilesClient({
                issuer: getEnv('ACCOUNTS_PROFILES_URL'),
                clientId: getEnv('ACCOUNTS_PROFILES_CLIENT_ID'),
                redirectUri: `${window.location.origin}${getEnv('ACCOUNTS_BASE_CONTEXT')}/auth`,
                logoutUri: getEnv('ACCOUNTS_LOGOUT_URL'),
            }),
        [],
    );

    const throwToErrorBoundary = useThrowToErrorBoundary();
    const { search, pathname } = useLocation();
    const impersonate = useMemo(getImpersonateQueryVariable, [search]);
    const [isLoading, setIsLoading] = useState(true);

    const [user, setUser] = useState<AuthUser | undefined>(undefined);
    const [issuedAt, setIssuedAt] = useState<number | undefined>(undefined);

    const isTrackingCompleted = useTrackIdentityProvider(isLoading, Boolean(user?.isAuthenticated));

    const [isImpersonatedExperience, setImpersonatedExperience] = useState(false);

    const providerValue = useMemo<AuthContextData>(
        (): AuthContextData => ({
            logout: async () => profilesClient.logout(),
            loginWithPopup: async () => {
                await profilesClient.loginWithPopup();
            },
            loginWithRedirect: async () => {
                setAuthRedirect(pathname);
                return profilesClient.loginWithRedirect();
            },
            loginSilently: async () => {
                await profilesClient.loginSilently();
            },
            getTokens: async () => {
                try {
                    const { id_token: idToken, access_token: accessToken } = await profilesClient.getTokensSilently();
                    return { idToken, accessToken };
                } catch {
                    await profilesClient.loginWithRedirect();
                    throwToErrorBoundary(new Error(REDIRECTING_TO_LOGIN_ERROR));
                    throw new Error(REDIRECTING_TO_LOGIN_ERROR);
                }
            },
            identityProvider: 'profiles',
            iat: issuedAt,
            isImpersonatedExperience,
            setImpersonatedExperience,
            user,
        }),
        [profilesClient, pathname, user, issuedAt, isImpersonatedExperience],
    );

    useEffect(() => {
        const initializeClient = async () => {
            profilesClient.subscribe(({ isAuthenticated, userInfo, iat }) => {
                if (!isAuthenticated || !userInfo || !iat) {
                    return;
                }

                const isImpersonated = Boolean(impersonate && userInfo.internal);
                setImpersonatedExperience(isImpersonated);
                setIssuedAt(iat);
                setUser({
                    id: userInfo.sub,
                    isInternal: Boolean(userInfo.internal),
                    isAuthenticated,
                    ...getTargetUserInfo(isImpersonated, userInfo.email, impersonate),
                    emailDerivedName: userInfo.email.split('@')[0],
                    fullName: userInfo.name.trim().split('@')[0], // the split on '@' patches a profiles bug where sometimes this field wrongly contains email instead of null.
                });
            });

            await profilesClient.start();
            setIsLoading(false);
        };

        initializeClient();
    }, [profilesClient]);

    if (isLoading) {
        return <FullLayoutLoader devLabel="Profiles AuthProvider" />;
    }
    if (!isTrackingCompleted) {
        return <FullLayoutLoader devLabel="GA tracking of Identity Provider" />;
    }

    return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};
