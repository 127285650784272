import { useCallback } from 'react';
import { Button } from '@customink/pigment-react';
import { useAuthUser } from '~/contexts/Auth/AuthContext';
import { useSharingAPI } from '~/pages/SavedDesigns/SharingAndCollab/SharingAPIContext';
import { processInputToEmails } from '~/components/input/processInputToEmails';
import { sendSharingDialogEvent } from '~/pages/SavedDesigns/SharingAndCollab/sendSharingDialogEvent';
import { SharingTabButtonsProps } from '~/pages/SavedDesigns/SharingAndCollab/types';

export function ButtonsSendCopy({ sharingState, amendState, onClose }: SharingTabButtonsProps) {
    const { cloneDesign } = useSharingAPI();
    const { email: myEmail } = useAuthUser();

    const sendCopyToEmails = useCallback(() => {
        sendSharingDialogEvent('Button Click', 'Send (copy sharing)');
        const result = processInputToEmails(sharingState.emailInputValue, sharingState.emailRecipients, myEmail);

        // empty field results in a valid list of emails, but there is nothing to send
        if (result.newEmails.length === 0 && result.newInputValue.length === 0) {
            amendState({ copyInputErrorMessage: 'Please fill in this field' });
            return;
        }

        // processInputToEmails was not successful (invalid input)
        if (result.status !== 'ok') {
            amendState({
                emailRecipients: result.newEmails,
                emailInputValue: result.newInputValue,
                copyInputErrorMessage: result.errorMessage,
            });
            return;
        }

        // processInputToEmails was successful, send mutation
        cloneDesign(result.newEmails, sharingState.copyMessage);
        amendState({
            emailRecipients: [],
            emailInputValue: '',
            copyInputErrorMessage: '',
        });
        onClose();
    }, [sharingState, myEmail]);

    return (
        <>
            <Button variant="secondary" onClick={onClose}>
                Done
            </Button>
            <Button onClick={sendCopyToEmails}>Send</Button>
        </>
    );
}
