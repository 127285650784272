import { gql } from 'graphql-tag';

// prettier-ignore
export const DesignReview = gql`
    query GetDesignReview($input: ResourceInput!, $after: String) {
        resource(resourceInput: $input) {
            ...on DesignReview {
                id
                myRole
                design {
                    designName
                    products {
                        previews
                    }
                }
                referenceId
                designStatus {
                    status
                    updatedAt
                }
                events(first: 40, after: $after) {
                    pageCursors {
                        next {
                            cursor
                        }
                        previous {
                            cursor
                        }
                    }
                    edges {
                        node {
                            id
                            type
                            data
                            createdAt
                            author        
                        }
                    }
                    
                }
            }
        }
    }
`;

// prettier-ignore
export const DesignReviewInfo = gql`
    query GetDesignReviewInfo($input: ResourceInput!) {
        resource(resourceInput: $input) {
            ...on DesignReview {
                id
                designStatus {
                    status
                }
                emailSharing {
                    email
                    role
                }
                linkShare {
                    id
                    role
                    hash
                }
            }
        }
    }
`;

// prettier-ignore
export const SharedWithMe = gql`
    query SharedWithMe($input: ReferenceType!, $after: String, $filter: ResourcesFilter ) {
        resources(first: 6, after: $after, filter: $filter, referenceType: $input) {
            nodes {
                ... on DesignReview {
                    ...designDetail
                }
            }
            pageCursors {
                next {cursor}
                previous {cursor}
            }
        }
    }

    fragment designDetail on DesignReview {
        id
        design {
            compositeId
            designName
            updatedAt
            products {
                previews
            }
        }
        myRole
    }
`;

// prettier-ignore
export const SharedWithMeCount = gql`
    query SharedWithMeCount {
        resources (
            first: 1
            filter: {roleNotEq: OWNER}
            referenceType: DESIGN_REVIEW
        ) {
            totalCount
        }
    }
`;

// prettier-ignore
export const CreateEvent = gql`
    mutation CreateEvent($input: EventInput!) {
        createEvent(eventInput: $input) {
            author
            data
        }
    }
`;

// prettier-ignore
export const CreateDesignCloneForMe = gql`
    mutation CloneResourceForMe($input: CloneResourceForMeInput!) {
        cloneResourceForMe(cloneForMeInput: $input) {
            ... on ClonedDesign {
                compositeId
                id
            }
        }
    }
`;

// prettier-ignore
export const CreateDesignCloneForUser = gql`
    mutation CloneResourceForUser($input: CloneResourceForUsersInput!) {
        cloneResourceForUsers(cloneForUsersInput: $input) {
            ... on ClonedDesign {
                compositeId
                id
            }
        }
    }
`;

// prettier-ignore
export const CreateResource = gql`
    mutation CreateResource($input: ResourceCreateInput!) {
        createResource(resourceInput: $input){
            resource {
                ... on DesignReview{
                    id
                    linkShare {
                        hash
                    }
                }
            }
            errors { code }
        }
    }
`;

// prettier-ignore
export const UpdateEmailShares = gql`
    mutation UpdateEmailShares($input: EmailSharesCreateInput!) {
        updateEmailShares(emailSharesInput: $input){
            id
        }
    }
`;

export const UpdateLinkShare = gql`
    mutation UpdateLinkShare($input: LinkShareUpdateInput!) {
        updateLinkShare(linkShareInput: $input) {
            id
            hash
        }
    }
`;

export const UnsubscribeFromResource = gql`
    mutation UnsubscribeFromResource($input: UnsubscribeFromResourceInput!) {
        unsubscribeFromResource(unsubscribeInput: $input)
    }
`;
