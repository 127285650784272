import { InfiniteData } from '@tanstack/react-query';
import { GetArtResponse, RenameArtProps } from '~/adapters/services/accounts/arts/rest';
import { Art } from '~/adapters/services/accounts/arts/types';

export const createOptimisticallyRenamedArtsResponse = (
    variables: RenameArtProps,
    previous?: InfiniteData<GetArtResponse>,
) => {
    if (!previous) {
        return undefined;
    }
    return {
        ...previous,
        pages: previous.pages.map((page) => ({
            ...page,
            items: page.items.map((item: Art) => {
                return item.id === variables.uploadId ? { ...item, name: variables.name } : item;
            }),
        })),
    };
};
