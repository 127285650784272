import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Config } from '~/adapters/config/config';
import { getConfigFromSession, setConfigToSession } from '~/adapters/browser/sessionStorage';
import { getConfigFromParams } from '~/adapters/config/fromParams';

export interface Links {
    newSession: string;
    storeLink: string;
    designLabLink: string;
    groupsLink: string;
    groupOrdersLink: string;
    storeGroupOrdersLink: string;
    uploadServiceLink: string;
    ndxAddProductLink: string;
    checkoutBuyNow: string;
    fundRaisingLink: string;
    fundRaisingStart: string;
    inventoryLink: string;
    rfeLink: string;
    collaborationLink: string;
    contactLink: string;
    basePath: string;
}

function createDefaultLinks(): Links {
    return {
        storeLink: '',
        designLabLink: '',
        groupsLink: '',
        groupOrdersLink: '',
        storeGroupOrdersLink: '',
        ndxAddProductLink: '',
        checkoutBuyNow: '',
        newSession: '',
        uploadServiceLink: '',
        fundRaisingLink: '',
        fundRaisingStart: '',
        inventoryLink: '',
        rfeLink: '',
        collaborationLink: '',
        contactLink: '',
        basePath: '',
    };
}

export const LinkContext = createContext<Links>(createDefaultLinks());

export function useLinks() {
    return useContext(LinkContext);
}

export function LinkProvider({ children }: PropsWithChildren<unknown>) {
    const { search } = useLocation();
    const envParams = useMemo(() => {
        if (import.meta.env.MODE !== 'production') {
            const params = new URLSearchParams(search);

            return getConfigFromParams(params);
        }
        return {};
    }, [search]);

    const sessionConfig: Partial<typeof Config> = useMemo(() => {
        if (import.meta.env.MODE !== 'production') {
            return getConfigFromSession();
        }
        return {};
    }, []);
    const [config, setConfig] = useState(() => ({ ...Config, ...sessionConfig, ...envParams }));

    const links = useMemo(
        (): Links => ({
            storeLink: config.ACCOUNTS_STORE_URL,
            groupsLink: `${config.ACCOUNTS_RFE_URL}/groups`,
            groupOrdersLink: `${config.ACCOUNTS_RFE_URL}/g`,
            storeGroupOrdersLink: `${config.ACCOUNTS_RFE_URL}/go`,
            ndxAddProductLink: config.ACCOUNTS_NDX_ADD_PRODUCT_URL,
            designLabLink: config.ACCOUNTS_NDX_URL,
            rfeLink: config.ACCOUNTS_RFE_URL,
            checkoutBuyNow: `${config.ACCOUNTS_RFE_URL}/checkout/buy_now`,
            fundRaisingLink: `${config.ACCOUNTS_FUNDRAISING_URL}/dashboard`,
            fundRaisingStart: `${config.ACCOUNTS_FUNDRAISING_URL}/designs/new`,
            inventoryLink: config.ACCOUNTS_INVENTORY_URL,
            newSession: config.ACCOUNTS_LOGIN_URL,
            uploadServiceLink: config.ACCOUNTS_UPLOAD_SERVICE_URL,
            collaborationLink: config.ACCOUNTS_COLLABORATION_URL,
            contactLink: `${config.ACCOUNTS_RFE_URL}/contact`,
            basePath: window.location.origin,
        }),
        [config],
    );

    useMemo(() => {
        if (import.meta.env.MODE !== 'production') {
            const sesConfig = getConfigFromSession();
            setConfigToSession({ ...sesConfig, ...envParams });
        }
    }, [envParams]);

    useEffect(() => {
        window.__nautilus_accounts = {
            clientVersion: Config.VITE_RELEASE,
        };
        if (import.meta.env.MODE !== 'production') {
            window.__nautilus_accounts = {
                ...window.__nautilus_accounts,
                setEnv(env, value) {
                    const sesConfig = getConfigFromSession();
                    setConfigToSession({ ...sesConfig, [env]: value });
                    setConfig((c) => ({ ...c, [env]: value }));
                },
                reset() {
                    setConfigToSession({});
                    setConfig(Config);
                },
                inspect() {
                    return config;
                },
            };
        }
    }, [config]);
    return <LinkContext.Provider value={links}>{children}</LinkContext.Provider>;
}
