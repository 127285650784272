import { Box, Button } from '@customink/pigment-react';
import { MouseEventHandler } from 'react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { ExpandMore } from '@mui/icons-material';

interface OrderDetailToggleProps {
    toggle: MouseEventHandler<HTMLElement>;
    open: boolean;
    singleDetail: boolean;
}

const ToggleIcon = styled(ExpandMore)<{ open: boolean }>(({ open, theme }) => ({
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export function OrderDetailToggle({ toggle, open, singleDetail }: OrderDetailToggleProps) {
    if (singleDetail) {
        return null;
    }
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="link" onClick={toggle}>
                Order Details
                <ToggleIcon open={open} />
            </Button>
        </Box>
    );
}
