import { Button, FormGroup, Stack, TextField } from '@customink/pigment-react';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { Can } from '~/contexts/ability/collaboration/CollaborationAbilityContext';
import { ResourceActions } from '~/contexts/ability/collaboration/collaborationAbility';

interface FeedbackCommentProps {
    comment: string;
    onChange: (comment: string) => void;
    sendComment: EmptyFunction;
    clearComment: EmptyFunction;
}

export function FeedbackComment({ comment, onChange, sendComment, clearComment }: FeedbackCommentProps) {
    return (
        <Can I={ResourceActions.CommentWrite}>
            <Stack spacing={2}>
                <FormGroup>
                    <TextField
                        label="To everyone"
                        rows={3}
                        value={comment}
                        onChange={(ev) => onChange(ev.target.value)}
                        multiline
                        fullWidth
                        removeHelperTextSpace
                    />
                </FormGroup>
                <Stack justifyContent="flex-end" direction="row" spacing={2}>
                    <Button onClick={clearComment} variant="secondary" disabled={!comment.length}>
                        Cancel
                    </Button>
                    <Button onClick={sendComment}>Comment</Button>
                </Stack>
            </Stack>
        </Can>
    );
}
