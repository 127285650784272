import { AuthMode, useApiCall } from '~/adapters/services/shared/apiCall';
import { getEnv } from '~/adapters/config/env';
import { useAuth } from '~/contexts/Auth/AuthContext';

export const useInventoryApiCall = () => {
    const { identityProvider } = useAuth();
    const auth: AuthMode = identityProvider === 'auth0' ? { withAccessToken: true } : { withIdToken: true };

    const apiCall = useApiCall(getEnv('ACCOUNTS_INVENTORY_API_URL'), {
        auth,
        attachImpersonate: false,
    });

    return apiCall;
};
