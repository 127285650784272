type GetQueryVariableOptions = Partial<{
    tryDecode: boolean;
}>;

export default function getQueryVariable(
    variable: string,
    options = { tryDecode: false } as GetQueryVariableOptions,
): string | undefined {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split(/=(.*)/s);
        if (decodeURIComponent(pair[0]) === variable) {
            const value = decodeURIComponent(pair[1]);

            if (options.tryDecode) {
                try {
                    return window.atob(value);
                } catch {
                    return value;
                }
            }

            return value;
        }
    }
    // eslint-disable-next-line no-undefined
    return undefined;
}
