import { Stack } from '@customink/pigment-react';
import { Typography } from '@mui/material';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { PageControlsAnchor } from '~/components/PageHeader/PageControls';

export const PageHeaderLayout = ({ children }: PWC) => (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" pb={2} pt={1}>
        {children}
    </Stack>
);

export function PageHeader({ children }: PWC) {
    return (
        <PageHeaderLayout>
            <Typography
                variant="h5"
                component="h1"
                sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', p: 0, flexShrink: 0 }}
            >
                {children}
            </Typography>
            <PageControlsAnchor />
        </PageHeaderLayout>
    );
}
