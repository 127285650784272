import { useMemo } from 'react';
import { OrderSortBy } from '~/adapters/services/accounts/orders/types';
import { offsetPaginationParams } from '~/adapters/services/shared/createParams';
import { PaginatedParams } from '~/adapters/services/shared/types/PaginatedParams';
import { useAccountsApiClient } from '../useAccountsApiClient';
import { AccountsApiClient } from '../accountsApiClient';

export interface GetOrdersProps {
    accountId: number;
    sort: OrderSortBy;
}

export interface GetOrderProps {
    orderId: number;
}

class OrdersRepository {
    constructor(private readonly client: AccountsApiClient) {}

    public getOrdersForAccount = ({ pageParam = 1, accountId, sort }: PaginatedParams<GetOrdersProps>) => {
        const query = {
            ...offsetPaginationParams({ perPage: 5, page: pageParam }),
            sort,
        };

        return this.client.getPaginated(
            `/accounts/{accountId}/orders`,
            {
                params: { path: { accountId }, query },
            },
            pageParam,
            5,
        );
    };

    public getOrderDetails = async ({ orderId }: GetOrderProps) => {
        const orderDetails = await this.client.get(`/orders`, {
            params: {
                query: {
                    ids: orderId.toString(),
                },
            },
        });
        return orderDetails[0];
    };
}

export const useOrdersRepository = () => {
    const client = useAccountsApiClient();

    return useMemo(() => new OrdersRepository(client), [client]);
};
