import { useMemo } from 'react';
import {
    CreateCustomerParams,
    transformECMCertificateDto,
    UpdateCustomerParams,
} from '~/adapters/services/accounts/taxExemptionsECM/types';
import { useAccountsApiClient } from '~/adapters/services/accounts/useAccountsApiClient';
import { isResponseError } from '~/adapters/openapifetch/responseError';
import { AccountsApiClient } from '../accountsApiClient';

class TaxExemptionsRepository {
    constructor(private readonly client: AccountsApiClient) {}

    public getCertificates = async (accountId: number) => {
        const response = await this.client.get(`/accounts/{accountId}/tax-exemptions/certificates`, {
            params: {
                path: { accountId },
            },
        });
        return response.map(transformECMCertificateDto);
    };

    public getCustomer = async (accountId: number) => {
        try {
            const response = await this.client.get(`/accounts/{accountId}/tax-exemptions/customer`, {
                params: {
                    path: {
                        accountId,
                    },
                },
            });
            return response;
        } catch (error) {
            if (isResponseError(error) && error.isStatusCode(404)) {
                return null;
            }
            throw error;
        }
    };

    public createCustomer = (accountId: number, customer: CreateCustomerParams) => {
        return this.client.post(`/accounts/{accountId}/tax-exemptions/customer`, {
            params: {
                path: { accountId },
            },
            body: customer,
        });
    };

    public updateCustomer = (customerId: number, customer: UpdateCustomerParams) => {
        return this.client.put(`/tax-exemptions/customer/{id}`, {
            params: {
                path: { id: customerId },
            },
            body: customer,
        });
    };

    public createOrGetTaxExemptionPortalLink = (accountId: number) => {
        return this.client.put(`/accounts/{accountId}/tax-exemptions/cert-express-invites`, {
            params: { path: { accountId } },
        });
    };

    public createOrGetEcommerceToken = (accountId: number) => {
        return this.client.put(`/accounts/{accountId}/tax-exemptions/ecommerce-token`, {
            params: { path: { accountId } },
        });
    };
}

export const useTaxExemptionsRepository = () => {
    const client = useAccountsApiClient();
    return useMemo(() => new TaxExemptionsRepository(client), []);
};
