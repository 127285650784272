import { useMemo } from 'react';
import { InfiniteData } from '@tanstack/react-query';

type KeysWithArrayType<T extends object> = {
    [K in keyof T]: T[K] extends any[] ? K : never;
}[keyof T];

export const useFlattenPaginatedData = <T extends InfiniteData<any>, P extends KeysWithArrayType<T['pages'][number]>>(
    data: T | undefined,
    propertyNameOnPage: P,
) =>
    useMemo(
        (): T extends InfiniteData<infer K> ? K[P] : never =>
            data?.pages.map((page) => page[propertyNameOnPage]).flat() ?? ([] as any),
        [data, propertyNameOnPage],
    );
