import { AuthMode, GetAuthorizationHeader } from '~/adapters/services/shared/apiCall';
import openApiFetch, { Middleware } from 'openapi-fetch';
import { useMemo } from 'react';
import { getEnv } from '~/adapters/config/env';
import { Config } from '~/adapters/config/config';
import { useAuth } from '~/contexts/Auth/AuthContext';
import { name } from '../../../../package.json';
import { AccountsApiClient } from './accountsApiClient';
import { paths } from './schema';

const getSessionId = () => {
    const sessionId = sessionStorage.getItem('accounts-browser-session-id');
    if (sessionId) {
        return sessionId;
    }
    const newSessionId = window.crypto?.randomUUID?.() ?? Math.random().toString(36).substring(2);
    // Intentionally not handling removal on logout. This is intended for debugging purposes to help users who switch between accounts and get errors.
    sessionStorage.setItem('accounts-browser-session-id', newSessionId);
    return newSessionId;
};

const getHash = async (token: string) => {
    const data = new TextEncoder().encode(token);
    const hash = await crypto.subtle.digest('SHA-1', data);
    return Array.from(new Uint8Array(hash))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
};

export const useAccountsApiClient = () => {
    const baseUrl = getEnv('ACCOUNTS_ACCOUNTS_SERVICE_URL');

    const { identityProvider, getTokens, user } = useAuth();
    const authMode: AuthMode = identityProvider === 'auth0' ? { withAccessToken: true } : { withIdToken: true };
    const headers = {
        'client-name': name,
        'client-version': Config.VITE_RELEASE,
        'browser-session-id': getSessionId(),
    };

    const client = openApiFetch<paths>({ baseUrl, headers });

    const middleware: Middleware = {
        onRequest: async (req) => {
            const authorizationToken = await GetAuthorizationHeader({
                url: req.request.url,
                getTokens,
                identityProvider,
                user,
                authMode,
                queryParams: req.params.query,
                isPublic: (req.schemaPath as keyof paths) === '/designs/{cid}/summary',
            });
            if (authorizationToken) {
                const authorizationTokenHash = await getHash(authorizationToken);
                req.request.headers.set('Authorization', authorizationToken);
                req.request.headers.set('authorization-hash', authorizationTokenHash);
            }
            return req.request;
        },
    };
    client.use(middleware);

    return useMemo(() => new AccountsApiClient(client), [client]);
};
