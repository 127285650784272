import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { useTheme } from '@mui/material';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Box, Button, IconButton, InfoCircleIcon, LabIconsUploadIcon, Typography } from '@customink/pigment-react';
import { UploadInfoDialog } from '~/pages/Arts/Upload/UploadInfoDialog';
import { Loader } from '~/components/Loader/loader';
import { useArtsUpload } from '~/adapters/services/accounts/arts/hooks';
import { useAccount } from '~/contexts/UserAccount/UserAccountContext';
import { pushErrorNotification } from '~/adapters/notistack/notistack';

const ACCEPTED_FILES_EXT = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf', 'ai', 'psd', 'eps'];
const ACCEPTED_MIME_TYPES =
    'image/jpeg, image/png, application/pdf, application/x-pdf, image/bmp, image/gif, ' +
    'application/vnd.adobe.illustrator, application/illustrator, image/vnd.adobe.illustrator, ' +
    'application/vnd.adobe.photoshop, application/photoshop, image/vnd.adobe.photoshop, ' +
    'application/postscript, image/x-eps';
const FILE_MAX_SIZE_IN_BYTES = 5_000_000; // in bytes

const ArtsLoadingBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    inset: 0,
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Upload: React.FC = () => {
    const [infoDialogVisible, setInfoDialogVisible] = useState(false);
    const { mutate, isPending } = useArtsUpload();
    const theme = useTheme();
    const currentAccount = useAccount();

    const handleRejectedFiles = useCallback((rejectedFiles: FileRejection[]) => {
        rejectedFiles.forEach((rejectedFile) => {
            let message = `File ${rejectedFile.file.name} failed to upload.`;

            if (rejectedFile.errors.some((err) => err.code === 'file-too-large')) {
                message += ` File is too large, please use file with maximal size ${(
                    FILE_MAX_SIZE_IN_BYTES / 1e6
                ).toFixed()} Mb.`;
            }

            if (rejectedFile.errors.some((err) => err.code === 'file-invalid-type')) {
                message += ` File has invalid type.`;
            }
            // data-action attribute added for test automation
            pushErrorNotification(<span data-action="file-too-large-alert’">{message}</span>);
        });
    }, []);

    const onDrop = useCallback(
        (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            handleRejectedFiles(rejectedFiles);
            mutate({ files: acceptedFiles, accountId: currentAccount.id });
        },
        [mutate, currentAccount.id, handleRejectedFiles],
    );
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: ACCEPTED_MIME_TYPES,
        noClick: true,
        noKeyboard: true,
        maxSize: FILE_MAX_SIZE_IN_BYTES,
    });

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Box {...getRootProps()}>
            <input {...getInputProps()} />

            <div>
                <Box
                    sx={{
                        fontSize: '2rem',
                        mb: 2,
                    }}
                >
                    <LabIconsUploadIcon fontSize="inherit" />
                </Box>
                <Typography variant="uiTextSmall" paragraph sx={{ mb: 1 }}>
                    Drag &amp; Drop
                </Typography>
                <Typography variant="uiTextSmall" paragraph sx={{ mb: 1 }}>
                    or
                </Typography>
                <Button onClick={open} sx={{ margin: 1 }}>
                    Browse your device
                </Button>
                <br />
                <IconButton
                    sx={{ mt: 1, color: theme.palette.blue.main }}
                    size="large"
                    onClick={() => setInfoDialogVisible(true)}
                    aria-label="More information"
                >
                    <InfoCircleIcon fontSize="inherit" />
                </IconButton>
            </div>
            <UploadInfoDialog
                open={infoDialogVisible}
                onClose={() => setInfoDialogVisible(false)}
                acceptedTypes={ACCEPTED_FILES_EXT}
            />
            {isDragActive && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        backgroundColor: 'rgba(60,60,60,0.6)',
                    }}
                />
            )}
            {isPending && (
                <ArtsLoadingBox>
                    <Loader title="Uploading..." />
                </ArtsLoadingBox>
            )}
        </Box>
    );
};

export default Upload;
