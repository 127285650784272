import { Box, Button, Dialog, DialogActions, DialogContent, Stack } from '@customink/pigment-react';
import { LabelValueRow } from '~/pages/Settings/components/LabelValueTable';
import { useCallback, useState } from 'react';
import { useForm, Validate } from 'react-hook-form';
import { PasswordTextField } from '~/pages/Settings/tabs/Profile/components/PasswordTextField';
import { useChangePassword, useGetAuthUserDetail, useSetPassword } from '~/adapters/services/accounts/auth0/hooks';
import { Loader } from '~/components/Loader/loader';
import { validateAuth0PasswordRequirements } from '~/pages/Settings/tabs/Profile/validate-auth0-password-requirements';
import { pushApiErrorNotification } from '~/adapters/notistack/notistack';

type PasswordChange = {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
};

export function PasswordChangeAuth0() {
    const {
        handleSubmit,
        formState: { errors },
        setError,
        setFocus,
        register,
        reset,
        watch,
    } = useForm<PasswordChange>({
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
    });

    const [showEditPassword, setShowEditPassword] = useState(false);
    const openEditPassword = useCallback(() => {
        setShowEditPassword(true);
    }, []);
    const closeEditPassword = useCallback(() => {
        setShowEditPassword(false);
        reset();
    }, []);

    const onInvalidPassword = useCallback(() => {
        setError('currentPassword', {
            type: 'manual',
            message: 'Incorrect password. Please try again.',
        });
        setFocus('currentPassword');
    }, [setError, setFocus]);

    const onUserNotMigratedToAuth0Exception = useCallback(() => {
        closeEditPassword();
        pushApiErrorNotification(
            "You already have a password set, but you're logged in using a passwordless method. Please log in with your password to change it.",
            { showTryAgainMessage: false },
        );
    }, [closeEditPassword]);

    const { mutateAsync: setPassword, isPending: isSetPasswordPending } = useSetPassword({
        onUserNotMigratedToAuth0Exception,
    });
    const { mutateAsync: changePassword, isPending: isChangePasswordPending } = useChangePassword({
        onInvalidPassword,
    });
    const authUserDetail = useGetAuthUserDetail();

    const validateNewPassword = useCallback<Validate<string, PasswordChange>>(
        (newPassword, { currentPassword }) => {
            if (currentPassword === newPassword) {
                return 'New password must be different from the current password';
            }

            return validateAuth0PasswordRequirements(newPassword);
        },
        [validateAuth0PasswordRequirements],
    );

    const validateRepeatPassword = useCallback(
        (confirmPassword: string) =>
            confirmPassword === watch('newPassword') || 'This needs to match your new password. Please try again',
        [watch],
    );

    const handleSave = useCallback(
        async ({ currentPassword, newPassword }: PasswordChange) => {
            if (!authUserDetail.data) {
                throw new Error('User detail is not loaded yet');
            }

            if (authUserDetail.data.hasPassword) {
                await changePassword({ currentPassword, newPassword });
                closeEditPassword();
            } else {
                await setPassword({ password: newPassword });
                closeEditPassword();
            }
        },
        [authUserDetail.data, changePassword, setPassword],
    );

    if (authUserDetail.isPending) {
        return <Loader title="Loading..." devLabel="Auth0 User detail API" />;
    }

    if (authUserDetail.isError) {
        return <Box mb={3}>There was an error getting user authentication detail.</Box>;
    }

    const { hasPassword } = authUserDetail.data;

    return (
        <>
            <LabelValueRow
                label="Password"
                value={hasPassword ? '*************' : 'Set a password for your account'}
                action={
                    <Button onClick={openEditPassword} variant="secondary">
                        {hasPassword ? 'Change Password' : 'Create Password'}
                    </Button>
                }
            />
            <Dialog
                open={showEditPassword}
                onClose={closeEditPassword}
                title={hasPassword ? 'Change Password' : 'Create Password'}
                width="sm"
                mobileVariant="fullScreen"
            >
                <DialogContent pt={2}>
                    <form onSubmit={handleSubmit(handleSave)} id="password-change-form">
                        <Stack direction="column" spacing={2}>
                            {hasPassword && (
                                <Stack direction="column" spacing={1}>
                                    <PasswordTextField
                                        error={!!errors.currentPassword}
                                        helperText={errors.currentPassword?.message}
                                        label="Enter your Current password"
                                        inputProps={{ autoComplete: 'current-password', tabIndex: 1 }}
                                        removeHelperTextSpace={!errors.currentPassword}
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...register('currentPassword', {
                                            required: 'Please enter your current password',
                                        })}
                                    />
                                </Stack>
                            )}
                            <PasswordTextField
                                error={!!errors.newPassword}
                                helperText={errors.newPassword?.message}
                                label="Enter your new password"
                                inputProps={{ autoComplete: 'new-password', tabIndex: 2 }}
                                removeHelperTextSpace={!errors.newPassword}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...register('newPassword', {
                                    validate: validateNewPassword,
                                })}
                            />
                            <PasswordTextField
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword?.message}
                                label="Confirm your new password"
                                inputProps={{ autoComplete: 'new-password', tabIndex: 3 }}
                                removeHelperTextSpace={!errors.confirmPassword}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...register('confirmPassword', {
                                    required: 'Please confirm your new password',
                                    validate: validateRepeatPassword,
                                })}
                            />
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions direction="column">
                    <Button
                        disabled={isChangePasswordPending || isSetPasswordPending}
                        variant="primary"
                        type="submit"
                        form="password-change-form"
                        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                        tabIndex={5}
                    >
                        Save
                    </Button>
                    <Button variant="secondary" onClick={closeEditPassword}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
