import React, { Dispatch, SetStateAction, useState } from 'react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

type CreateTeamData = {
    teamName: string;
    setTeamName: Dispatch<SetStateAction<string>>;
    inviteMessage: string;
    setInviteMessage: Dispatch<SetStateAction<string>>;
    inviteEmails: Array<string>;
    setInviteEmails: Dispatch<SetStateAction<Array<string>>>;
};

const CreateTeamContext = React.createContext<CreateTeamData>({
    teamName: '',
    setTeamName: () => {},
    inviteMessage: '',
    setInviteMessage: () => {},
    inviteEmails: [],
    setInviteEmails: () => {},
});

export const CreateTeamProvider = ({ children }: PWC) => {
    const [teamName, setTeamName] = useState('');
    const [inviteMessage, setInviteMessage] = useState('');
    const [inviteEmails, setInviteEmails] = useState<Array<string>>([]);

    return (
        <CreateTeamContext.Provider
            value={{
                teamName,
                setTeamName,
                inviteMessage,
                setInviteMessage,
                inviteEmails,
                setInviteEmails,
            }}
        >
            {children}
        </CreateTeamContext.Provider>
    );
};

export const useCreateTeamContext = () => {
    const context = React.useContext(CreateTeamContext);
    if (!context) {
        throw new Error(`useCreateTeamContext must be used within a CreateTeamProvider`);
    }
    return context;
};
