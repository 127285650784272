import { useCallback, useState } from 'react';
import { Button, Container, Divider, ScreenReadersOnly, Stack, Typography } from '@customink/pigment-react';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { usePage } from '~/adapters/browser/usePage';
import { sendChangeAccountName, sendChangeContactEmail } from '~/adapters/services/profiles/profile/tracking';
import { useAuth } from '~/contexts/Auth/AuthContext';
import { TeamManagement } from '~/pages/Settings/tabs/AccountDetails/TeamManagement';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';
import { Title } from '../../title';
import { LabelValueRow, LabelValueTable } from '../../components/LabelValueTable';
import { ImpersonateOnlyBanner } from '../../components/ImpersonateOnlyBanner';
import { AccountIdRow } from '../../components/AccountIdRow';
import { ChangeNameDialog } from './ChangeNameDialog';
import { ChangeContactEmailDialog } from './ChangeContactEmailDialog';

export function AccountDetailsTab() {
    usePage({ title: Title, reportPath: '/accounts/details' });
    const { isImpersonatedExperience } = useAuth();
    const [dialogContactEmailOpen, setDialogContactEmailOpen] = useState(false);
    const [dialogNameOpen, setDialogNameOpen] = useState(false);
    const isTeamAccountsFeatureFlagEnabled = useFeatureFlagEnabled('team_accounts_v0');

    const {
        account: { contactEmail, id: accountId, name },
    } = useUserAccount();

    const openEditContactEmailDialog = useCallback(() => {
        sendChangeContactEmail('Open');
        setDialogContactEmailOpen(true);
    }, []);
    const closeEditContactEmailDialog = useCallback(() => {
        sendChangeContactEmail('Close');
        setDialogContactEmailOpen(false);
    }, []);

    const openEditNameDialog = useCallback(() => {
        sendChangeAccountName('Open');
        setDialogNameOpen(true);
    }, []);
    const closeEditNameDialog = useCallback(() => {
        sendChangeAccountName('Close');
        setDialogNameOpen(false);
    }, []);

    if (!isTeamAccountsFeatureFlagEnabled) {
        return <Container>You are not allowed to access this page.</Container>;
    }

    return (
        <>
            <Stack>
                <ScreenReadersOnly>
                    <h2>Accounts</h2>
                </ScreenReadersOnly>
                <LabelValueTable>
                    <LabelValueRow
                        label="Contact email"
                        value={contactEmail ?? 'Not set'}
                        action={
                            <Button onClick={openEditContactEmailDialog} variant="secondary">
                                {contactEmail ? 'Change contact email' : 'Set contact email'}
                            </Button>
                        }
                    />
                    <LabelValueRow
                        label="Team name"
                        value={name}
                        action={
                            <Button onClick={openEditNameDialog} variant="secondary">
                                Edit
                            </Button>
                        }
                    />
                    <LabelValueRow label="Team Management" action={<TeamManagement />} />
                </LabelValueTable>
                <Typography textAlign="end" component="p" variant="captionSmall" m={1}>
                    Development status disclaimer: Deleting team is WIP.
                </Typography>
                {isImpersonatedExperience && (
                    <>
                        <Divider sx={{ mt: 2 }} />
                        <ImpersonateOnlyBanner sx={{ mt: 5, mb: 3 }} />
                        <LabelValueTable>
                            <AccountIdRow />
                        </LabelValueTable>
                    </>
                )}
            </Stack>
            <ChangeContactEmailDialog
                open={dialogContactEmailOpen}
                onClose={closeEditContactEmailDialog}
                currentContactEmail={contactEmail}
                accountId={accountId}
                key={`ChangeContactEmailDialog-${accountId}`} // render separate component for each account
            />
            <ChangeNameDialog
                open={dialogNameOpen}
                onClose={closeEditNameDialog}
                currentName={name}
                accountId={accountId}
                key={`ChangeNameDialog-${accountId}`} // render separate component for each account
            />
        </>
    );
}
