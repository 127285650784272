type ValuesOf<T> = T[keyof T];

type NonNullishKeys<T extends object> = ValuesOf<{
    [key in keyof T]: T[key] extends undefined | null ? never : key;
}>;

export const omitNullishFields = <T extends object>(obj: T): Pick<T, NonNullishKeys<T>> => {
    return Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== undefined && v !== null)) as Required<
        Pick<T, NonNullishKeys<T>>
    >;
};
