import { FC, useEffect, useMemo, useState } from 'react';
import { Alert, Dialog, DialogContent } from '@customink/pigment-react';
import useScript from '~/adapters/browser/useScript';
import { Loader } from '~/components/Loader/loader';
import './genCert.d';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import { useCreateOrGetEcommerceToken } from '~/adapters/services/accounts/taxExemptionsECM/hooks';
import { getEnv } from '~/adapters/config/env';

type ETEDialogProps = {
    open: boolean;
    onClose: () => void;
};

const avalaraApiUrl = getEnv('ACCOUNTS_AVALARA_EXEMPTIONS_API_URL');

export const ETEDialog: FC<ETEDialogProps> = ({ open, onClose }) => {
    const state = 'California';
    const [formContainer, setFormContainer] = useState<HTMLDivElement | null>(null);
    const [isAvalaraInitError, setIsAvalaraInitError] = useState(false);
    const [isAvalaraInitLoading, setIsAvalaraInitLoading] = useState(false);

    const avalaraScript = useScript(`${avalaraApiUrl}/js/gencert.js`);

    const tokenQuery = useCreateOrGetEcommerceToken();
    const [tokenExpired, setTokenExpired] = useState(false);

    useEffect(() => {
        if (avalaraScript.isLoaded && open && formContainer && tokenQuery.isSuccess) {
            try {
                setIsAvalaraInitLoading(true);
                window.GenCert.init(formContainer, {
                    ship_zone: state,
                    gencertAPI: avalaraApiUrl,
                    token: tokenQuery.data.token,
                    primary_color: designTokens.color.primary.default.value,
                    secondary_color: designTokens.color.secondary.default.value,
                    onCertSuccess: () => {
                        // TODO impl
                    },
                    afterShow: () => setIsAvalaraInitLoading(false),
                });
                window.GenCert.show();
            } catch (error) {
                setIsAvalaraInitError(true);
            }
        }
    }, [avalaraScript.isLoaded, open, formContainer, tokenQuery.isPending, tokenQuery.isError, state]);

    const renderFormContainer = useMemo(() => <div ref={setFormContainer} />, []);

    useEffect(() => {
        let timeoutHandle: NodeJS.Timeout;
        if (tokenQuery.data?.expirationDate) {
            const expiration = new Date(`${tokenQuery.data.expirationDate}Z`);
            timeoutHandle = setTimeout(() => {
                setTokenExpired(true);
            }, expiration.getTime() - Date.now());
        }
        return () => clearTimeout(timeoutHandle);
    }, [tokenQuery.data?.expirationDate]);

    return (
        <>
            <Dialog open={open} onClose={onClose} title="Submit Your Tax Exemption Certificate" width="md">
                <DialogContent>
                    {tokenExpired && (
                        <Alert severity="warning">
                            Tax Exemption session has expired after 60 minutes. Please refresh the page to continue.
                        </Alert>
                    )}
                    {(isAvalaraInitError || avalaraScript.isError || tokenQuery.isError) && (
                        <Alert severity="error">
                            An error occurred while loading the Tax Exemption Certificate form. If the problem persists,
                            contact us.
                        </Alert>
                    )}
                    {(isAvalaraInitLoading || avalaraScript.isLoading || tokenQuery.isPending) && <Loader />}
                    {renderFormContainer}
                </DialogContent>
            </Dialog>
        </>
    );
};
