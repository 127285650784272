import { useCallback } from 'react';
import { Button, Stack } from '@customink/pigment-react';
import { signaler, SignalerFeatureFlagName } from '~/adapters/signaler/signaler';
import { useSignalerContext } from '~/contexts/SignalerContext';

interface PossibleValuesProps {
    possibleValues: string[];
    featureName: SignalerFeatureFlagName;
}

export function PossibleValues({ possibleValues, featureName }: PossibleValuesProps) {
    const currentValue = signaler.featureFlag(featureName);
    const { onFeatureFlagUpdate } = useSignalerContext();

    const setFeatureFlag = useCallback(
        (value: string) => {
            signaler.setFeatureFlag(featureName, value);
            onFeatureFlagUpdate();
        },
        [featureName],
    );

    return (
        <Stack spacing={2} direction="row">
            {possibleValues.map((value) => (
                <Button
                    variant={currentValue === value ? 'primary' : 'secondary'}
                    key={value}
                    onClick={() => setFeatureFlag(value)}
                >
                    {value}
                </Button>
            ))}
        </Stack>
    );
}
