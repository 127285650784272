import { useQueryParam } from '~/adapters/react-router/useQueryParam';

export const useAuth0Error = () => {
    const errorDescription = useQueryParam('error_description');
    const error = useQueryParam('error');

    const isInternalRequiredError = errorDescription === 'internal_required' && error === 'access_denied';

    return {
        isInternalRequiredError,
    };
};
