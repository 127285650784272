import { ChangeEvent } from 'react';
import { Box, TextField } from '@customink/pigment-react';
import { MultipleEmailInput } from '~/components/input/MultipleEmailInput';
import { SharingTabProps } from './types';

const commonInputSx = { mb: 3, width: '100%', maxWidth: 340 };

export function SharingDialogAsCopy({ sharingState, amendState }: SharingTabProps) {
    const handleCopyMessageChange = (event: ChangeEvent<HTMLInputElement>) =>
        amendState({ copyMessage: event.target.value });

    return (
        <Box>
            <Box sx={commonInputSx}>
                <MultipleEmailInput
                    emails={sharingState.emailRecipients}
                    setEmails={(value) => amendState({ emailRecipients: value })}
                    inputValue={sharingState.emailInputValue}
                    setInputValue={(value) => amendState({ emailInputValue: value })}
                    errorMessage={sharingState.copyInputErrorMessage}
                    setErrorMessage={(value) => amendState({ copyInputErrorMessage: value })}
                    dataAction="sharing dialog email input" // used by selenium_ui_tests
                />
            </Box>
            <TextField
                label="Message"
                value={sharingState.copyMessage}
                onChange={handleCopyMessageChange}
                multiline
                minRows={2}
                fullWidth
            />
        </Box>
    );
}
