import { GroupOrderWithOrigin } from '~/adapters/services/accounts/groupOrders/types';
import { useLinks } from '~/contexts/LinkContext';

interface GroupOrderLink {
    label: string;
    url: string;
}

interface GroupOrderUrls {
    retrieveUrl: string;
    activityUrl: string;
    confirmationUrl: string;
    purchasesUrl: string;
}

const useGroupOrderUrls = (groupOrder: GroupOrderWithOrigin): GroupOrderUrls => {
    const { rfeLink, storeGroupOrdersLink, groupOrdersLink } = useLinks();

    if (groupOrder.isStoreGroupOrder) {
        return {
            retrieveUrl: `${rfeLink}/designs/${groupOrder.compositeId}/retrieve`,
            activityUrl: `${storeGroupOrdersLink}/${groupOrder.compositeId}/manage/dashboard`,
            confirmationUrl: `${storeGroupOrdersLink}/${groupOrder.compositeId}/manage/dashboard`,
            purchasesUrl: `${storeGroupOrdersLink}/${groupOrder.compositeId}/manage/dashboard`,
        };
    }

    return {
        retrieveUrl: `${rfeLink}/designs/${groupOrder.compositeId}/retrieve`,
        activityUrl: `${groupOrdersLink}/${groupOrder.compositeId}/activity`,
        confirmationUrl: `${groupOrdersLink}/${groupOrder.compositeId}/confirmation`,
        purchasesUrl: `${groupOrdersLink}/${groupOrder.compositeId}/purchases`,
    };
};

/**
 * Returns the links for the group order - mirrors https://github.com/customink/profiles/blob/2738177148994b628ccc77dc45ae1b9ffe32f2bc/app/presenters/gof/presenter.rb#L47-L73
 * @param groupOrder
 */
export const useGroupOrderLinks = (groupOrder: GroupOrderWithOrigin): GroupOrderLink[] => {
    const { retrieveUrl, activityUrl, confirmationUrl, purchasesUrl } = useGroupOrderUrls(groupOrder);

    const viewDesign: GroupOrderLink = { label: 'View Design', url: retrieveUrl };
    const placeGof: GroupOrderLink = { label: 'Place Order', url: activityUrl };
    const manageGof: GroupOrderLink = { label: 'Manage', url: activityUrl };
    const viewOrder: GroupOrderLink = { label: 'View Order', url: confirmationUrl };
    const manageBss: GroupOrderLink = { label: 'Manage Shipping List', url: purchasesUrl };
    const viewBss: GroupOrderLink = { label: 'View Shipping List', url: purchasesUrl };
    const placeBss: GroupOrderLink = { label: 'Place Order', url: purchasesUrl };

    if (groupOrder.crowdsourced) {
        switch (groupOrder.state) {
            case 'canceling':
            case 'canceled':
                return [viewDesign];
            case 'closed':
            case 'open':
                return [placeGof, viewDesign, manageGof];
            case 'placed':
                return [viewDesign, manageGof];
            case 'draft':
                return [];
            default:
                return [viewDesign];
        }
    }

    switch (groupOrder.state) {
        case 'canceling':
        case 'canceled':
            return [viewDesign];
        case 'placed':
            return [viewOrder, viewBss, viewDesign];
        case 'draft':
            return [];
        default:
            return [placeBss, manageBss];
    }
};
