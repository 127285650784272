import { InfiniteData, keepPreviousData, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
    sendArtsDeleteEvent,
    sendArtsRenameEvent,
    sendArtsUploadEvent,
} from '~/adapters/services/accounts/arts/tracking';
import { pushApiErrorNotification, pushErrorNotification } from '~/adapters/notistack/notistack';
import { GetArtProps, GetArtResponse, useArtsRepository } from './rest';
import { createOptimisticallyRenamedArtsResponse } from './transformers';

const infiniteArtsKey = 'infinite-arts';

export function useInfiniteArts(props: GetArtProps) {
    const { getArts } = useArtsRepository();

    return useInfiniteQuery({
        queryKey: [infiniteArtsKey, props],
        queryFn: ({ pageParam }) => getArts({ pageParam, ...props }),
        initialPageParam: 1,
        getNextPageParam: (lastData) => (lastData.hasMorePages ? lastData.currentPage + 1 : undefined),
        placeholderData: keepPreviousData,
    });
}

export function useArtDelete() {
    const { deleteArt } = useArtsRepository();
    const client = useQueryClient();

    return useMutation({
        mutationFn: deleteArt,
        onSuccess: async () => {
            sendArtsDeleteEvent('Success');
            await client.invalidateQueries({ queryKey: [infiniteArtsKey] });
        },
        onError: async () => {
            sendArtsDeleteEvent('Failure');
            pushApiErrorNotification('An error occurred while deleting art.');
        },
        onMutate: async () => {
            sendArtsDeleteEvent('Start');
        },
    });
}

export function useArtRename() {
    const { renameArt } = useArtsRepository();

    const client = useQueryClient();
    return useMutation({
        mutationFn: renameArt,
        onMutate: async (props) => {
            sendArtsRenameEvent('Start');
            // optimistic update – first step is cancelling outgoing queries
            await client.cancelQueries({ queryKey: [infiniteArtsKey] });
            // take snapshot of previous data (array of all queries matching the partial key)
            const previousData = client.getQueriesData<InfiniteData<GetArtResponse>>({ queryKey: [infiniteArtsKey] });
            // the update itself – foreach query matching the key, synthesize updated data & set it in cache
            client.setQueriesData({ queryKey: [infiniteArtsKey] }, (previous?: InfiniteData<GetArtResponse>) =>
                createOptimisticallyRenamedArtsResponse(props, previous),
            );
            // store the snapshot in query context
            // FIY the structure of query context is completely arbitrary, so "previousData" is chosen for clarity
            return { previousData };
        },
        onError: (_err, _variables, context) => {
            sendArtsRenameEvent('Failure');
            pushApiErrorNotification('An error occurred while trying to rename art.');
            // rollback the optimistic update by restoring the snapshot from previousData & applying it on all queries
            context?.previousData?.forEach(([key, data]) => {
                client.setQueryData(key, data);
            });
        },
        onSuccess: async () => {
            sendArtsRenameEvent('Success');
            await client.invalidateQueries({ queryKey: [infiniteArtsKey] });
        },
    });
}

export function useArtsUpload() {
    const { uploadArts } = useArtsRepository();
    const client = useQueryClient();

    return useMutation({
        mutationFn: uploadArts,
        onMutate: async () => {
            sendArtsUploadEvent('Start');
            await client.cancelQueries({ queryKey: [infiniteArtsKey] });
        },
        onError: (error: Error) => {
            sendArtsUploadEvent('Failure');
            pushErrorNotification(error.message);
        },
        onSuccess: async () => {
            sendArtsUploadEvent('Success');
            await client.invalidateQueries({ queryKey: [infiniteArtsKey] });
        },
    });
}
