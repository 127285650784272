import { Button } from '@customink/pigment-react';
import { FC, useCallback, useState } from 'react';
import { ETEDialog } from '~/pages/Settings/tabs/TaxExemptionECM/EmbeddedPortal/ETEDialog';

type AddCertificateButton = {
    /* Called in addition to any internal functionality. */
    onClick?: () => void;
    text: string;
    variant: 'primary' | 'secondary';
};

export const EmbeddedPortalButton: FC<AddCertificateButton> = ({ text, variant, onClick }) => {
    const [open, setOpen] = useState(false);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);
    const handleClick = useCallback(() => {
        setOpen(true);
        onClick?.();
    }, [onClick]);

    return (
        <>
            <Button variant={variant} onClick={handleClick}>
                {text}
            </Button>
            <ETEDialog open={open} onClose={handleClose} />
        </>
    );
};
