import { FC } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { getEnv } from '~/adapters/config/env';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { Auth0SessionGuard } from '~/contexts/Auth/Auth0/Auth0SessionGuard';
import { Auth0UserProvider } from './Auth0UserProvider';

export const Auth0ProviderWrapper: FC<PWC> = ({ children }) => {
    return (
        <Auth0Provider
            domain={getEnv('ACCOUNTS_AUTH0_DOMAIN')}
            clientId={getEnv('ACCOUNTS_AUTH0_CLIENT_ID')}
            authorizationParams={{
                redirect_uri: `${window.location.origin}${getEnv('ACCOUNTS_BASE_CONTEXT')}/designs`,
                audience: getEnv('ACCOUNTS_AUTH0_AUDIENCE'),
            }}
            cacheLocation="localstorage"
        >
            <Auth0UserProvider>
                <Auth0SessionGuard>{children}</Auth0SessionGuard>
            </Auth0UserProvider>
        </Auth0Provider>
    );
};
