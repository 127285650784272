import { useCallback } from 'react';
import { Button } from '@customink/pigment-react';
import { useAuthUser } from '~/contexts/Auth/AuthContext';
import { useSharingAPI } from '~/pages/SavedDesigns/SharingAndCollab/SharingAPIContext';
import { processInputToEmails } from '~/components/input/processInputToEmails';
import { PermissionMapper } from '~/pages/SavedDesigns/SharingAndCollab/mappers';
import { mergeEmailShares } from '~/pages/SavedDesigns/SharingAndCollab/mergeEmailShares';
import { sendSharingDialogEvent } from '~/pages/SavedDesigns/SharingAndCollab/sendSharingDialogEvent';
import { SharingTabButtonsProps } from '~/pages/SavedDesigns/SharingAndCollab/types';
import { SharingUser } from '~/adapters/services/collaboration/graphqlTypes';

export function ButtonsSendEmailSharing({ sharingState, amendState, onClose }: SharingTabButtonsProps) {
    const { createMutationPending, emailShares, setEmailSharing } = useSharingAPI();
    const { email: myEmail } = useAuthUser();

    const clearEmailSharing = useCallback(() => {
        amendState({
            emailRecipients: [],
            emailInputValue: '',
            emailInputErrorMessage: '',
        });
    }, []);

    const sendEmailSharing = useCallback(() => {
        const result = processInputToEmails(sharingState.emailInputValue, sharingState.emailRecipients, myEmail);

        // processInputToEmails was not successful (invalid input)
        if (result.status !== 'ok') {
            amendState({
                emailRecipients: result.newEmails,
                emailInputValue: result.newInputValue,
                emailInputErrorMessage: result.errorMessage,
            });
            return;
        }

        // processInputToEmails was successful, send mutation
        const newEmailShares: SharingUser[] = result.newEmails.map((recipient) => ({
            email: recipient,
            role: PermissionMapper[sharingState.emailPermission],
        }));

        const mergedEmailShares = mergeEmailShares(emailShares, newEmailShares);
        const emailsAdded = mergedEmailShares.length - emailShares.length;
        setEmailSharing(mergedEmailShares, sharingState.emailMessage);
        sendSharingDialogEvent('Button Click', 'Send (email sharing)', emailsAdded);

        clearEmailSharing();
        onClose();
    }, [sharingState, emailShares, myEmail]);

    return (
        <>
            <Button variant="secondary" onClick={clearEmailSharing}>
                Cancel
            </Button>
            <Button
                onClick={sendEmailSharing}
                // the problem that the disabled prop solves is an *extreme* edge case, because the dialog is closed onClick!
                // so user would have to reopen it quickly enough before server response arrives
                // server has to be very slow & user very fast. Then, if it wasn't disabled, button would createResource again -> internal server error
                disabled={createMutationPending}
            >
                Send
            </Button>
        </>
    );
}
