import { Box, Typography, useMobileBreakpoint } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

export function PageSort({ children }: PWC) {
    const isMobile = useMobileBreakpoint();
    return (
        <>
            {!isMobile && (
                <Typography variant="paragraphSmall" sx={{ whiteSpace: 'nowrap' }}>
                    Sort by:
                </Typography>
            )}
            <Box minWidth={0}>{children}</Box>
        </>
    );
}
