import { Box, Typography } from '@customink/pigment-react';
import { Step, Stepper } from '@mui/material';
import { DesignReviewEvent } from '~/adapters/services/collaboration/graphqlTypes';
import { TimelineEvent } from '~/pages/Feedback/FeedbackEvent';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { useScrollLoad } from '~/adapters/browser/useScrollLoad';
import LoadMoreButton from '~/components/LoadMoreButton';

interface FeedbackTimelineProps {
    events: Partial<DesignReviewEvent>[];
    loading: boolean;
    fetchNextPage: EmptyFunction;
    hasNextPage?: boolean;
}

export function FeedbackTimeline({ events, loading, fetchNextPage, hasNextPage }: FeedbackTimelineProps) {
    const ref = useScrollLoad({
        load: fetchNextPage,
        enabled: !loading,
    });
    return (
        <Box ref={ref}>
            <Typography variant="subHeading" sx={{ pt: 0 }}>
                Activity
            </Typography>
            <Stepper orientation="vertical">
                {events.map((event) => (
                    <Step expanded active key={event.id}>
                        <TimelineEvent event={event} />
                    </Step>
                ))}
            </Stepper>
            {hasNextPage && <LoadMoreButton onClick={fetchNextPage} loading={loading} />}
        </Box>
    );
}
