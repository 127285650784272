import { Skeleton } from '@mui/material';
import { useMemo } from 'react';
import { calculateSingleProductPerItemPrice } from '~/adapters/services/accounts/designs/quoteData';
import { useValueInDollar } from '~/adapters/browser/dollarFormater';
import { useDesignQuote } from '~/adapters/services/accounts/designs/hooks';
import { Quote } from '~/adapters/services/accounts/designs/types';

interface PerDesignItemProps {
    quoteData: Quote;
}

function PerDesignItemPrice({ quoteData }: PerDesignItemProps) {
    const price = useMemo(() => calculateSingleProductPerItemPrice(quoteData), [quoteData]);
    const pricePerItemInDollar = useValueInDollar(price?.pricePerItem);
    if (!price) {
        return null;
    }
    return (
        <>
            {' '}
            • {pricePerItemInDollar} {price.itemCount > 1 ? 'each ' : ''}for {price.itemCount}{' '}
            {price.itemCount > 1 ? 'items' : 'item'}
        </>
    );
}

interface DesignPriceProps {
    cid: string;
}

export function DesignPrice({ cid }: DesignPriceProps) {
    const { data: quoteData, isLoading: isQuoteLoading } = useDesignQuote(cid);

    if (isQuoteLoading) {
        return <Skeleton sx={{ ml: 1 }} width={80} />;
    }

    if (!quoteData || !quoteData.quote) {
        return null;
    }

    return <PerDesignItemPrice quoteData={quoteData.quote} />;
}
