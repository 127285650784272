import { useAuth, useOptionalAuthUser } from '~/contexts/Auth/AuthContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useUsersRepository } from '~/adapters/services/accounts/users/rest';
import { pushApiErrorNotification, pushSuccessNotification } from '~/adapters/notistack/notistack';
import { sendChangeNameEvent } from '~/adapters/services/profiles/profile/tracking';
import { ImpersonatedUserKey } from '~/adapters/services/profiles/admin/hooks';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';

const userDataKey = 'user-data-key';
export const userAccountsKey = 'user-accounts-key';

export const useUserData = () => {
    const authUser = useOptionalAuthUser();
    const { getUserData } = useUsersRepository();

    return useQuery({
        queryKey: [userDataKey, authUser?.email],
        queryFn: () => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return getUserData({ email: authUser!.email });
        },
        enabled: Boolean(authUser),
    });
};

export const useUserAccounts = () => {
    const { user } = useUserAccount();
    const { getUserAccounts } = useUsersRepository();

    return useQuery({
        queryKey: [userAccountsKey],
        queryFn: () => getUserAccounts(user.id),
        enabled: Boolean(user),
    });
};

export function useUpdateUserData() {
    const { updateUserData } = useUsersRepository();
    const { loginSilently } = useAuth();

    const client = useQueryClient();
    return useMutation({
        mutationFn: updateUserData,
        onMutate: () => {
            sendChangeNameEvent('Start');
        },
        onSuccess: async () => {
            sendChangeNameEvent('Success');
            await Promise.all([
                client.invalidateQueries({ queryKey: [userDataKey] }),
                client.invalidateQueries({ queryKey: [ImpersonatedUserKey] }),
                loginSilently(),
            ]);
            pushSuccessNotification('Name was updated.');
        },
        onError: () => {
            sendChangeNameEvent('Error');
            pushApiErrorNotification('Your name could not be updated right now.');
        },
    });
}
