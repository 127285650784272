import { useAccountsApiClient } from '~/adapters/services/accounts/useAccountsApiClient';
import { useMemo } from 'react';
import { PaginatedParams } from '~/adapters/services/shared/types/PaginatedParams';
import { offsetPaginationParams } from '~/adapters/services/shared/createParams';
import { DeleteDesignProps, InfiniteDesignProps } from '~/adapters/services/accounts/designs/types';
import { AccountsApiClient } from '../accountsApiClient';

class DesignsRepository {
    constructor(private readonly accountsApiClient: AccountsApiClient) {}

    public deleteDesign = async ({ cid, accountId }: DeleteDesignProps) => {
        return this.accountsApiClient.post('/accounts/{accountId}/designs/{cid}/hide', {
            params: {
                path: { accountId, cid },
            },
        });
    };

    public getDesigns = async ({ accountId, pageParam = 1, sort }: PaginatedParams<InfiniteDesignProps>) => {
        return this.accountsApiClient.getPaginated(
            `/accounts/{accountId}/designs`,
            {
                params: {
                    path: { accountId },
                    query: {
                        sort,
                        ...offsetPaginationParams({ page: pageParam, perPage: 6 }),
                    },
                },
            },
            pageParam,
            6,
        );
    };

    public getDesignSummary = (cid: string) => {
        return this.accountsApiClient.get(`/designs/{cid}/summary`, {
            params: {
                path: { cid },
            },
        });
    };

    public getDesignQuote = (cid: string) => {
        return this.accountsApiClient.get(`/designs/{cid}/quote`, {
            params: {
                path: { cid },
            },
        });
    };

    public getProductDetails = (cid: string) => {
        return this.accountsApiClient.get(`/designs/{cid}/products`, {
            params: {
                path: { cid },
            },
        });
    };
}

export const useDesignsProxyRepository = () => {
    const client = useAccountsApiClient();

    return useMemo(() => new DesignsRepository(client), [client]);
};
