import { useState, MouseEvent, useCallback } from 'react';
import { flow } from 'fp-ts/function';
import { stopPropagation } from '~/adapters/browser/stopPropagation';

export function useMenu() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOpen = useCallback(
        flow(stopPropagation, (event: MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        }),
        [setAnchorEl],
    );
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    return {
        anchorEl,
        open: Boolean(anchorEl),
        handleOpen,
        handleClose,
    };
}
