import { useEffect, useState } from 'react';
import { sendPasswordChanged } from '~/adapters/services/profiles/profile/tracking';
import { usersPasswordEditEmbeddedPath } from '~/adapters/services/profiles/links';
import styles from './styles.module.css';

interface EditPasswordProps {
    onEdit: () => void;
    onClose: () => void;
}

type EditPasswordMessage = 'profiles.password-edited';

type ClosePasswordMessage = 'profiles.password-cancel';

type ErrorPasswordMessage = 'profiles.password-error';

type SizePasswordMessage = {
    type: 'profiles.password-size';
    width: number;
    height: number;
};

type EditPasswordMessageType = EditPasswordMessage | ClosePasswordMessage | SizePasswordMessage | ErrorPasswordMessage;

function isSizePasswordMessage(message: EditPasswordMessageType): message is SizePasswordMessage {
    return (message as SizePasswordMessage)?.type === 'profiles.password-size';
}

const EditPasswordWidth = 360; // [px] has to be set manually to a large enough value, because unlike height, width is not measured reliable by Profiles
const EditPasswordEstimateHeight = 346; // [px] only initial estimate to prevent a noticeable jump
const HeightTolerance = 20; // [px] add slightly more height than measured, in case when horizontal scrollbar appears and slices off a few pixels

export function EditPassword({ onEdit, onClose }: EditPasswordProps) {
    const [height, setHeight] = useState(EditPasswordEstimateHeight);

    useEffect(() => {
        function handler(event: MessageEvent<EditPasswordMessageType>) {
            const { data } = event;
            if (isSizePasswordMessage(data)) {
                setHeight(data.height + HeightTolerance);
                return;
            }

            switch (data) {
                case 'profiles.password-cancel':
                    onClose();
                    break;
                case 'profiles.password-edited':
                    onEdit();
                    sendPasswordChanged('Success');
                    break;
                case 'profiles.password-error':
                    sendPasswordChanged('Failure');
                    break;
                default: // do nothing, as there are uncontrollable MessageEvents coming from some SDKs that we do not intend to handle
            }
        }
        window.addEventListener('message', handler, false);
        return () => window.removeEventListener('message', handler);
    }, []);

    return (
        <iframe
            title="Edit password"
            style={{ margin: 'auto' }}
            height={height}
            width={EditPasswordWidth}
            className={styles['edit-pass-iframe']}
            src={usersPasswordEditEmbeddedPath()}
        />
    );
}
