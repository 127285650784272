import { useMemo } from 'react';
import { format, isToday, isYesterday } from 'date-fns';
import { StepContent, StepLabel, useTheme } from '@mui/material';
import { Stack, Typography } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Circle } from '@mui/icons-material';
import { DesignReviewEvent, DesignReviewEventType } from '~/adapters/services/collaboration/graphqlTypes';

type DesignReviewEventTypeMapper = Record<DesignReviewEventType, string>;

const DesignEventTypeToClassMapper: DesignReviewEventTypeMapper = {
    [DesignReviewEventType.Reject]: 'rejected',
    [DesignReviewEventType.Approve]: 'approved',
    [DesignReviewEventType.Comment]: '',
    [DesignReviewEventType.Rerequest]: 'shared',
    [DesignReviewEventType.Share]: 'shared',
};

const DesignEventTypeToTranslation: DesignReviewEventTypeMapper = {
    [DesignReviewEventType.Reject]: 'rejected this design',
    [DesignReviewEventType.Approve]: 'approved this design',
    [DesignReviewEventType.Rerequest]: 'requested new review for this design',
    [DesignReviewEventType.Comment]: 'commented',
    [DesignReviewEventType.Share]: 'shared this design',
};

const FeedbackCircle = styled(Circle, {
    shouldForwardProp: (prop: string) => !['active', 'completed', 'error'].includes(prop),
})(({ theme }) => ({
    color: theme.palette.grey[500],
    position: 'relative',
    left: '2px',
    '&.shared': {
        color: theme.palette.blue.main,
    },
    '&.rejected': {
        color: theme.palette.error.main,
    },
    '&.approved': {
        color: theme.palette.success.main,
    },
}));

export function TimelineEvent({ event }: { event: Partial<DesignReviewEvent> }) {
    const theme = useTheme();

    const { createdAt, author, data, type = DesignReviewEventType.Comment } = event;
    const timeRange = useMemo(() => {
        const date = new Date(createdAt);
        if (isToday(date)) {
            return `Today ${format(date, 'h:mm a')}`;
        }

        if (isYesterday(date)) {
            return 'Yesterday';
        }

        return format(date, 'd MMM');
    }, [createdAt]);
    return (
        <>
            <StepLabel
                StepIconComponent={() => <FeedbackCircle />}
                StepIconProps={{ className: DesignEventTypeToClassMapper[type] }}
            >
                <Stack direction="row" spacing={1}>
                    <Typography variant="uiTextSmall">{author}</Typography>
                    <Typography variant="uiTextSmall" sx={{ color: theme.palette.grey[500] }}>
                        {DesignEventTypeToTranslation[type]}.
                    </Typography>
                </Stack>
            </StepLabel>
            <StepContent>
                <Stack spacing={1}>
                    <Typography variant="captionRegular" sx={{ color: theme.palette.grey[500] }}>
                        {timeRange}
                    </Typography>
                    {data && type !== DesignReviewEventType.Share && (
                        <Typography variant="paragraphSmall">{data}</Typography>
                    )}
                </Stack>
            </StepContent>
        </>
    );
}
