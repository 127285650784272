import * as Sentry from '@sentry/react';
import { log } from '~/adapters/browser/localLogger';

// replaces fp-ts absurd function used for exhaustive typing, mainly for local DX
// HOWEVER, it also works at runtime – when external data no longer matches our code, absurd gets called!
// fp-ts absurd would throw unhandled error (very dangerous); we want to make a Sentry log instead, to let us know about the discrepancy
export function absurdWithLog(parameter: never): void {
    const exception = `Called \`absurdWithLog\` function which should be uncallable, with parameter:\n${parameter}`;
    log.error(exception);
    Sentry.captureException(exception, { level: 'error' });
}
