import { ComponentProps } from 'react';
import { Button } from '@customink/pigment-react';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';

export function DestructiveButton({ sx, ...props }: ComponentProps<typeof Button>) {
    return (
        <Button
            variant="link"
            sx={{
                color: 'error.main',
                '&:hover': {
                    background: `${designTokens.color.status.error.value}0C`,
                },
                '&:focus': {
                    background: `${designTokens.color.status.error.value}0C`,
                },
                '&:active': {
                    background: `${designTokens.color.status.error.value}19`,
                },
                '&:focus-visible': {
                    color: 'error.main',
                    textDecoration: 'underline',
                    background: `${designTokens.color.status.error.value}0C`,
                },
                ...sx,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}
