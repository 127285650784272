import { Typography } from '@mui/material';
import { PWC } from '~/adapters/typescript/propsWithChildren';

export function InfoText({ children }: PWC) {
    return (
        <Typography variant="uiTextSmall" component="div">
            {children}
        </Typography>
    );
}
