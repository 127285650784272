import React from 'react';
import { Divider, Stack } from '@customink/pigment-react';
import { OrderDetails } from '~/adapters/services/accounts/orders/types';
import { OrderInvoiceInfo } from './OrderInvoiceInfo';
import { OrderShippingInfo } from './OrderShippingInfo';
import { OrderItemsInfo } from './OrderItemsInfo';

interface Props {
    orderDetails: OrderDetails;
}

export const OrderDetail: React.FC<Props> = ({ orderDetails }) => {
    return (
        <Stack spacing={2} sx={{ p: 2 }}>
            {orderDetails.state === 'delivered' && <OrderShippingInfo orderDetails={orderDetails} />}
            <Divider />
            <OrderInvoiceInfo orderDetails={orderDetails} />
            <Divider />
            <OrderItemsInfo orderDetails={orderDetails} />
        </Stack>
    );
};
