import { Alert } from '@customink/pigment-react';
import { Slide, SlideProps, Snackbar, styled } from '@mui/material';
import { useMemo } from 'react';
import { checkout } from '~/adapters/services/rfe/links';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { linkTrackEvent } from '~/adapters/tracking/tracking';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';
import { gaCategoryOld } from '../tracking';

type CartNotificationProps = {
    open: boolean;
    message: string;
    onClose: EmptyFunction;
};

// Centers the icons on mobile when the snackbar text has line breaks
const StyledAlert = styled(Alert)({
    '& > *': {
        alignItems: 'center !important',
    },
});

const SlideTransition = (props: SlideProps) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Slide {...props} direction="down" />;
};

export function CartNotification({ open, message, onClose }: CartNotificationProps) {
    const addToCartEnabled = useFeatureFlagEnabled('add_to_cart_notification_v0');

    return useMemo(
        () => (
            <Snackbar
                open={open && addToCartEnabled}
                transitionDuration={300}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                TransitionComponent={SlideTransition}
                // Used for test automation
                data-action="add to cart alert"
            >
                <StyledAlert severity="success" onClose={onClose}>
                    {message}{' '}
                    <a
                        href={checkout()}
                        onClick={(event) =>
                            linkTrackEvent(event, {
                                category: gaCategoryOld,
                                action: 'Button Click',
                                label: 'View Cart Modal',
                            })
                        }
                    >
                        View cart.
                    </a>
                </StyledAlert>
            </Snackbar>
        ),
        [open, message, onClose],
    );
}
