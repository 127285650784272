import { useCallback, useContext, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Button, IconButton, TrashIcon } from '@customink/pigment-react';
import { Card, CardActions, CardHeader } from '@mui/material';
import { FeedbackPath } from '~/config';
import { useLinks } from '~/contexts/LinkContext';
import { ResourceActions } from '~/contexts/ability/collaboration/collaborationAbility';
import { AbilityContext, Can } from '~/contexts/ability/collaboration/CollaborationAbilityContext';
import { SharedDesignCard as SharedDesignCardType } from '~/adapters/services/collaboration/types';
import { DesignCardMedia } from '~/components/DesignCard/DesignCardMedia';
import { DesignCardContent } from '~/components/DesignCard/DesignCardContent';
import { usePreservedQueryParamLink } from '~/adapters/browser/usePreservedQueryParamLink';
import { DeleteDialog } from '~/pages/SharedDesigns/DeleteDialog';
import { sendSharedDesignEvent } from '~/pages/SharedDesigns/sendSharedDesignEvent';
import setLocation from '~/adapters/browser/setLocation';

interface SharedDesignCardProps {
    card: SharedDesignCardType;
    cardIndex: number;
}

const CardTopRightButton = styled(IconButton)({
    // styles copied verbatim from application.css
    // the button shall be scrapped when Card Redesign is applied on SharedCards too
    zIndex: 1,
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    backgroundColor: 'rgba(100,100,100,0.65)',
    color: 'white',
    borderRadius: 4,
    '&:hover': { backgroundColor: 'rgba(100,100,100,0.95)' },
});

export function SharedDesignCard({ card, cardIndex }: SharedDesignCardProps) {
    const links = useLinks();
    const fullFeedbackPath = useMemo(
        // the reason for regex replace is that in a link, we want a relative path (not starting with a slash)
        () => generatePath(FeedbackPath, { resourceId: card.id }).replace(/^\//, ''),
        [card],
    );

    const cardPreviews: string[] = useMemo(
        () => card.design.products.map((product) => product.previews).flat(),
        [card],
    );

    const viewDesignLink = usePreservedQueryParamLink(fullFeedbackPath);
    const editDesignLink = `${links.designLabLink}?cid=${card.design.compositeId}`;

    // todo after NT-448
    const productNameTodo = '';
    const productColorTodo = '';

    const ability = useContext(AbilityContext);
    const canEdit = ability.can(ResourceActions.Edit, card);

    const [removeDialogVisible, setRemoveDialogVisible] = useState(false);
    const openRemoveDialog = useCallback(() => setRemoveDialogVisible(true), []);
    const closeRemoveDialog = useCallback(() => setRemoveDialogVisible(false), []);

    const handleSlideClick = useCallback(() => {
        sendSharedDesignEvent('Link Click', 'Proof image', cardIndex);
        setLocation(viewDesignLink);
    }, [viewDesignLink, cardIndex]);

    return (
        <Card sx={{ position: 'relative', pb: 0.5 }}>
            <DesignCardMedia
                cardIndex={cardIndex}
                carouselImages={cardPreviews}
                onSlideClick={handleSlideClick}
                indicateAction
            />
            <CardTopRightButton onClick={openRemoveDialog} fill size="medium">
                <TrashIcon fontSize="inherit" />
            </CardTopRightButton>
            <CardHeader title={card.design.designName} style={{ paddingBottom: '0px' }} />
            <DesignCardContent
                productName={productNameTodo}
                productColor={productColorTodo}
                updatedAt={card.design.updatedAt}
            />

            <CardActions style={{ gap: 0 }}>
                <Can I={ResourceActions.Edit}>
                    <Button
                        sx={{ mb: 1.5 }}
                        variant="secondary"
                        fullWidth
                        href={editDesignLink}
                        onClick={() => sendSharedDesignEvent('Link Click', 'Edit Design', cardIndex)}
                    >
                        Edit Design
                    </Button>
                </Can>
                <Button
                    variant={canEdit ? 'link' : 'secondary'}
                    fullWidth
                    href={viewDesignLink}
                    onClick={() => sendSharedDesignEvent('Link Click', 'View Details', cardIndex)}
                >
                    View Details
                </Button>
            </CardActions>
            <DeleteDialog
                id={card.id}
                name={card.design.designName}
                open={removeDialogVisible}
                onClose={closeRemoveDialog}
            />
        </Card>
    );
}
