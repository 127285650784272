import { useMemo } from 'react';

const DollarFormater = Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
});

export function useValueInDollar(value = 0) {
    return useMemo(() => DollarFormater.format(value), [value]);
}
