import { union } from 'fp-ts/Array';
import { Eq } from 'fp-ts/Eq';
import { SharingUser } from '~/adapters/services/collaboration/graphqlTypes';

export function mergeEmailShares(emailShares: SharingUser[], newEmailShares: SharingUser[]): SharingUser[] {
    const getEquality: Eq<SharingUser> = {
        equals: (a: SharingUser, b: SharingUser) => a.email.toLowerCase() === b.email.toLowerCase(),
    };
    // Although union is usually considered a commutative operation, the fp-ts implementation is not.. and that's exactly what we need here!
    // The order of params is essential to keep the SharingUser from newEmailShares, and drop the one from emailShares. Note: this behaviour is not documented in fp-ts
    return union<SharingUser>(getEquality)(newEmailShares, emailShares);
}
