import { Button } from '@customink/pigment-react';
import React from 'react';

interface Props {
    onClick: () => void;
}

const ViewMoreButton: React.FC<Props> = ({ onClick }) => (
    <Button
        data-testid="view-more-button"
        onClick={(e): void => {
            e.stopPropagation();
            e.preventDefault();
            onClick();
        }}
        fullWidth
    >
        View More
    </Button>
);

export default ViewMoreButton;
