import { OrderSummary } from '~/adapters/services/accounts/orders/types';

export enum AccountsPortalOrderState {
    Cancelled = 'cancelled',
    Shipped = 'shipped',
    Delivered = 'delivered',
    InProgress = 'inProgress',
}
export function getAccountsPortalOrderState(order: OrderSummary): AccountsPortalOrderState {
    if (order.cancelled) {
        return AccountsPortalOrderState.Cancelled;
    }
    switch (order.state) {
        case 'shipped':
            return AccountsPortalOrderState.Shipped;
        case 'delivered':
            return AccountsPortalOrderState.Delivered;
        default:
            return AccountsPortalOrderState.InProgress;
    }
}
