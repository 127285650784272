import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { featuresDefinition, SignalerFeatureFlagName } from '~/adapters/signaler/signaler';
import { FeatureRow } from './FeatureRow';

const HeadCell = styled(TableCell)({
    fontWeight: 'bold',
});

export const FlagsTable: React.FC = () => {
    return (
        <>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <HeadCell>Feature</HeadCell>
                            <HeadCell>Available Flags</HeadCell>
                            <HeadCell>Your flag</HeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(featuresDefinition).map(([featureName, { flags }]) => (
                            <FeatureRow
                                featureName={featureName as SignalerFeatureFlagName}
                                possibleValues={Object.keys(flags)}
                                key={featureName}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
