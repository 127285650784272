import { components } from '../schema';

export type SetPasswordRequest = components['schemas']['SetPasswordDto'];

export type ChangePasswordRequest = components['schemas']['ChangePasswordDto'];

export const Auth0Exception = [
    'INVALID_CURRENT_PASSWORD',
    'MULTIPLE_USERS_FOUND',
    'PASSWORD_UNCHANGED',
    'PASSWORD_NOT_SET_YET',
    'PASSWORD_CRITERIA_NOT_MET',
    'USER_NOT_MIGRATED_TO_AUTH0',
] as const;

export type Auth0Error = {
    type?: (typeof Auth0Exception)[number];
    message?: string;
};

export type AuthUserDetailDto = components['schemas']['Auth0Identity'];
