import { useLinks } from '~/contexts/LinkContext';

export const useEditDesignLink = (cid: string) => {
    const { designLabLink } = useLinks();
    return `${designLabLink}?cid=${cid}`;
};

export const useFundraiserLink = (cid: string) => {
    const { fundRaisingStart } = useLinks();
    return `${fundRaisingStart}?cid=${cid}&design_from_ci=true`;
};

export const useGroupOrdersLink = (cid: string) => {
    const { rfeLink } = useLinks();
    return `${rfeLink}/g/${cid}/activity`;
};
