import { FC } from 'react';
import { CushionedBox } from '~/components/Layout/CushionedBox';
import { DesignsPath } from '~/config';
import { ButtonLink } from '~/components/Link/Link';

export const DesignDetailNotFound: FC = () => {
    return (
        <CushionedBox style={{ textAlign: 'center' }}>
            <p>The requested design was not found.</p>
            <ButtonLink to={DesignsPath}>Back to Your Designs</ButtonLink>
        </CushionedBox>
    );
};
