import React from 'react';
import { SectionContent } from '~/components/Layout/SectionContent';
import { Container } from '@customink/pigment-react';
import Upload from './Upload/Upload';
import { UploadBox } from './Upload/UploadBox';

const EmptyArtsList: React.FC = () => (
    <SectionContent>
        <Container maxWidth="md">
            <h2>Upload your own files</h2>
            <p>
                Store your group&#39;s logos, graphics, or drawings <br />
                for easy access the next time you start a project.
            </p>
            <UploadBox>
                <Upload />
            </UploadBox>
        </Container>
    </SectionContent>
);

export default EmptyArtsList;
