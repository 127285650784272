import React, { PointerEvent } from 'react';
import { Modal, Slide } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { Box, IconButton, Stack, XCloseIcon } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import setLocation from '~/adapters/browser/setLocation';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { NavLink } from '~/components/Link/Navlink';
import AccountSelector from '~/components/MultiAccountManagement/AccountSelector';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';
import { MenuItem } from './MenuItem';

const FormGroup = styled(Box)(({ theme }) => ({
    padding: '1.25rem 0',
    borderBottomColor: theme.palette.grey[200],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
}));

const MenuLabel = styled('label')(({ theme }) => ({
    color: theme.palette.grey[700],
    fontSize: theme.typography.fontSize,
    position: 'relative',
    display: 'block',
    paddingLeft: '2rem',
    cursor: 'pointer',
    lineHeight: '1.5rem',
    textTransform: 'uppercase',
}));

const MenuRadioButton = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    display: 'flex',
    width: '1.5rem',
    height: '1.5rem',
    textAlign: 'center',
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[500],
    borderRadius: '50%',
}));

const MenuRadioCircle = styled(Circle)(({ theme }) => ({
    width: '1.0rem',
    height: '1.0rem',
    margin: 'auto',
    color: theme.palette.blue.main,
}));

const ModalLayout = styled(Box)({
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
});

const ModalHeader = styled(Stack)(({ theme }) => ({
    color: 'white',
    backgroundColor: theme.palette.red.main,
}));

const ModalContent = styled(Box)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
}));

interface ItemProps extends MenuItem {
    onClick: EmptyFunction;
}

const Item: React.FC<ItemProps> = ({ onClick, link, label, selected, action, external = false }) => {
    function onAClick(e: PointerEvent): void {
        e.preventDefault();
        setLocation(link);
    }

    const content = (
        <MenuLabel>
            <MenuRadioButton>{selected && <MenuRadioCircle />}</MenuRadioButton>
            {label}
        </MenuLabel>
    );

    return (
        <FormGroup data-js="mobile-nav-modal-options">
            {external ? (
                <a href={link} data-action={action} onPointerDown={onAClick}>
                    {content}
                </a>
            ) : (
                <NavLink to={link} data-action={action} onClick={onClick}>
                    {content}
                </NavLink>
            )}
        </FormGroup>
    );
};

interface NavigationModalProps {
    menu: Array<MenuItem>;
    open: boolean;
    onClose: () => void;
}

const MobileNavigationModal: React.FC<NavigationModalProps> = ({ menu, open, onClose }) => {
    const isTeamAccountsFeatureFlagEnabled = useFeatureFlagEnabled('team_accounts_v0');

    return (
        <Modal open={open} onClose={onClose}>
            <Slide in={open} direction="down">
                <ModalLayout>
                    <ModalHeader direction="row" justifyContent="space-between" alignItems="center" p={1}>
                        <Box width={0} />
                        <Box>Choose a Section</Box>
                        <IconButton onClick={onClose} aria-label="Close" color="inherit">
                            <XCloseIcon />
                        </IconButton>
                    </ModalHeader>
                    <ModalContent>
                        <form>
                            {isTeamAccountsFeatureFlagEnabled && (
                                <Box my={2}>
                                    <AccountSelector />
                                </Box>
                            )}
                            {menu.map(({ label, selected, link, action, external, enabled = true }) => {
                                if (!enabled) {
                                    return null;
                                }
                                return (
                                    <Item
                                        key={label}
                                        label={label}
                                        selected={selected}
                                        link={link}
                                        action={action}
                                        external={external}
                                        onClick={onClose}
                                    />
                                );
                            })}
                        </form>
                    </ModalContent>
                </ModalLayout>
            </Slide>
        </Modal>
    );
};

export default MobileNavigationModal;
