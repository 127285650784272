import { ReactNode } from 'react';
import { AlertColor } from '@mui/material/Alert/Alert';
import { enqueueSnackbar, VariantType } from 'notistack';
import { calculateNotificationTimeout } from './calculateNotificationTimeout';

// MUI Alert severity is fortunately compatible with notistack VariantType, so no need for mapping.
// But VariantType is broader, so this means: narrow down VariantType to AlertColor.
export type NotificationVariant = VariantType & AlertColor;

export type Notification = {
    message: ReactNode;
    variant: NotificationVariant;
};

export const pushNotification = ({ message, variant }: Notification) => {
    enqueueSnackbar(message, {
        variant,
        autoHideDuration: calculateNotificationTimeout(message),
    });
};
export const pushSuccessNotification = (message: ReactNode) => pushNotification({ message, variant: 'success' });
export const pushErrorNotification = (message: ReactNode) => pushNotification({ message, variant: 'error' });
export const pushApiErrorNotification = (message: ReactNode, options = { showTryAgainMessage: true }) =>
    pushErrorNotification(
        <>
            {message}
            <br />
            {options.showTryAgainMessage && 'Please try again later.'}
        </>,
    );
