import { Button, Tooltip } from '@customink/pigment-react';
import { useCreateOrGetTaxExemptionPortalLink } from '~/adapters/services/accounts/taxExemptionsECM/hooks';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import Spinner from '~/components/Spinner';
import * as Sentry from '@sentry/react';
import {
    avalaraTakingTooLongMessage,
    ButtonVariantText,
    CustomerWithBusinessAddress,
    portalErrorNotificationMessage,
} from '~/pages/Settings/tabs/TaxExemptionECM/ExternalPortal/common';
import { pushApiErrorNotification } from '~/adapters/notistack/notistack';

type PortalButtonProps = ButtonVariantText & {
    customer: CustomerWithBusinessAddress;
    onClick?: () => void;
};

/**
 * This component may be rendered only when the customer has a business address.
 *
 * Button to access the portal (aka invite link).
 * The href is fetched from the API, but without indicating the loading state unless user interacts with it, to reduce visual noise.
 * The API response is expected to be fairly quick, so the href is expected to be loaded before user interacts with the button.
 */
export function PortalButton({ text, variant, customer, onClick }: PortalButtonProps) {
    const { isPending, data: portalLink, isError } = useCreateOrGetTaxExemptionPortalLink();
    const [touched, setTouched] = useState(false);

    const isPendingAndTouched = touched && isPending;
    const portalLinkNotReady = portalLink?.status === 'InProgress';
    const href = portalLink?.invitation?.requestLink;

    const handleClick = useCallback(
        (e: MouseEvent) => {
            if (href === undefined) {
                e.preventDefault();
                setTouched(true);
            }
            onClick?.();
        },
        [href],
    );

    useEffect(() => {
        if (touched && href) {
            // the timeout is to prevent a bug in React with Firefox, context: https://stackoverflow.com/questions/76944918/should-not-already-be-working-on-window-open-in-simple-react-app
            setTimeout(() => {
                window.open(href, '_blank');
            }, 0);
        }
    }, [touched, href]);

    useEffect(() => {
        if (isError) {
            pushApiErrorNotification(portalErrorNotificationMessage);
        }
    }, [isError]);

    if (!customer || !customer.businessAddress) {
        // If user doesn't have business address, the portal link query will never succeed.
        // This is a dev alert. If this were to happen in production, show the UI with its error handling.
        if (import.meta.env.MODE === 'production') {
            Sentry.captureException(new Error('PortalButton: Customer must have a business address'));
        } else {
            throw new Error('PortalButton: Customer must have a business address');
        }
    }

    return (
        <Tooltip
            title={(portalLinkNotReady && avalaraTakingTooLongMessage) || (isError && portalErrorNotificationMessage)}
        >
            <span>
                <Button
                    target="_blank"
                    href={href}
                    disabled={isError || isPendingAndTouched || portalLinkNotReady}
                    onClick={handleClick}
                    variant={variant}
                >
                    {isPendingAndTouched ? <Spinner /> : text}
                </Button>
            </span>
        </Tooltip>
    );
}
