import { ComponentProps, forwardRef, Ref, useState } from 'react';
import { InputAdornment } from '@mui/material';
import { TextField, Typography } from '@customink/pigment-react';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';

interface PasswordToggleProps {
    toggle: EmptyFunction;
    show: boolean;
}

function PasswordToggle({ show, toggle }: PasswordToggleProps) {
    return (
        <InputAdornment position="end" onClick={toggle} sx={{ cursor: 'pointer' }}>
            <Typography variant="captionRegular">{show ? 'HIDE' : 'SHOW'}</Typography>
        </InputAdornment>
    );
}

type TextFieldProps = ComponentProps<typeof TextField>;

export const PasswordTextField = forwardRef(function PasswordTextField(
    props: TextFieldProps,
    ref: Ref<HTMLInputElement>,
) {
    const [showPass, setShowPass] = useState(false);
    const toggleShowPass = () => setShowPass(!showPass);

    return (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            inputRef={ref}
            type={showPass ? 'text' : 'password'}
            endAdornment={<PasswordToggle show={showPass} toggle={toggleShowPass} />}
        />
    );
});
