import { FC, useEffect, useState } from 'react';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { useOptionalAuth } from '~/contexts/Auth/AuthContext';
import { Config } from '~/adapters/config/config';
import { REDIRECTING_TO_LOGIN_ERROR } from '~/contexts/Auth/error';
import { useThrowToErrorBoundary } from '~/components/ErrorBoundary';
import { FullLayoutLoader } from '~/components/Layout/FullLayoutLoader';

const TOKEN_ISSUED_AT_THRESHOLD = 5000; // [ms]

/**
 * If user logs out from profiles in a different application, they still might have a valid JWT in accounts-ui's local storage.
 * In that case, this guard makes sure that:
 *  - if sso cookie with profiles is not existing, it redirects them to login.
 *  - if sso cookie with profiles is existing, but is different user, it postpones react render so that all subscriptions are reloaded and user is actually updated. Without this logic, user would be updated only after next render, while getTokens() would be already returning tokens of new user.
 */
export const ProfilesSessionGuard: FC<PWC> = ({ children }) => {
    const auth = useOptionalAuth();

    const throwRenderError = useThrowToErrorBoundary();
    const [isCheckingSession, setIsCheckingSession] = useState(true);

    useEffect(() => {
        (async () => {
            const isAuthenticated = auth?.user?.isAuthenticated; // only check session if user is logged in (meaning user has valid tokens) - this ensures that we don't check session on feedback page with code access
            const isTokenIssuedRecently = auth?.iat !== undefined && Date.now() - auth.iat < TOKEN_ISSUED_AT_THRESHOLD; // we want to check session if the token was issued earlier, if it is fresh, we are sure that user has valid session with profiles and user is up to date
            const isTestMode = Config.MODE === 'test'; // there's no other easy way to opt-out of this check in playwright tests

            const shouldCheckSession = isAuthenticated && !isTokenIssuedRecently && !isTestMode;

            if (!shouldCheckSession) {
                setIsCheckingSession(false);
                return;
            }

            try {
                await auth.loginSilently();
                setIsCheckingSession(false);
            } catch (e) {
                /* eslint-disable-next-line no-console */
                console.warn('ProfilesSessionGuard: User session check failed', e);
                auth.loginWithRedirect();
                throwRenderError(new Error(REDIRECTING_TO_LOGIN_ERROR));
            }
        })();
        // we want this really run only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isCheckingSession) {
        return <FullLayoutLoader devLabel="ProfilesSessionGuard loading" />;
    }

    return <>{children}</>;
};
