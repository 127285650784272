import { trackEvent } from '~/adapters/tracking/tracking';

const ProfilesCategory = 'Profiles';

export function sendChangeNameEvent(label: string): void {
    trackEvent({ category: ProfilesCategory, action: 'Change Name', label });
}

export function sendChangeContactEmail(label: string): void {
    trackEvent({ category: ProfilesCategory, action: 'Change Contact Email', label });
}

export function sendChangeAccountName(label: string): void {
    trackEvent({ category: ProfilesCategory, action: 'Change Account Name', label });
}

export function sendPasswordChanged(label: string): void {
    trackEvent({ category: ProfilesCategory, action: 'Change Password', label });
}
