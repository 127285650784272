import validator from 'validator';

interface UserProps {
    firstName?: string | null;
    lastName?: string | null;
}

type RequiredUserProps<T> = T & NonNullable<UserProps>;

/**
 * In some cases we get email or empty value as a first name and last name from profiles
 * in that case we want to fallback to empty values
 * @see ./transformers.test.ts
 */
export function normalizeUser<T extends UserProps>(user: T): RequiredUserProps<T> {
    return {
        ...user,
        firstName: !user.firstName || validator.isEmail(user.firstName) ? '' : user.firstName,
        lastName: !user.lastName || validator.isEmail(user.lastName) ? '' : user.lastName,
    };
}
