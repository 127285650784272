import { Alert, Box, Button, Dialog, DialogActions, DialogContent, LightBulbIcon } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { useLinks } from '~/contexts/LinkContext';

const ArtsFileFormat = styled('span')(({ theme }) => ({
    padding: '0.2em 0.7em',
    fontSize: theme.typography.fontSize * 0.8,
    borderTop: 1,
    borderRight: 1,
    borderBottom: 1,
    borderLeft: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    '&:first-child': {
        borderLeft: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
    },
}));

interface UploadInfoDialogProps {
    acceptedTypes: string[];
    open: boolean;
    onClose: EmptyFunction;
}

export function UploadInfoDialog({ acceptedTypes, open, onClose }: UploadInfoDialogProps) {
    const { contactLink } = useLinks();

    return (
        <Dialog title="Upload Requirements" open={open} onClose={onClose} width="lg">
            <DialogContent pt={1}>
                Accepted File Type (Max size: 5MB)
                <Box mt={1}>
                    {acceptedTypes.map((type) => (
                        <ArtsFileFormat key={type}>.{type.toUpperCase()}</ArtsFileFormat>
                    ))}
                </Box>
                <Alert severity="info" icon={<LightBulbIcon />} sx={{ mt: 3 }}>
                    Have a different type of file? <a href={contactLink}>Email it to us</a> and we&#39;ll have it ready
                    for you to use within a few hours!
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
