import { Stack } from '@customink/pigment-react';
import { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { PWC } from '~/adapters/typescript/propsWithChildren';

const anchorElId = 'page-controls';

export function PageControlsAnchor() {
    return (
        <Stack
            id={anchorElId}
            direction="row"
            alignItems="center"
            flexGrow={1}
            flexShrink={1}
            // Empirically determined, just a fallback for screen sizes below usual (less than 375px) or if page heading were unexpectedly long
            minWidth={140}
            spacing={1}
            justifyContent="flex-end"
            flexWrap="nowrap"
        />
    );
}

export function PageControlsMount({ children }: PWC) {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    useLayoutEffect(() => {
        const el = document.getElementById(anchorElId);
        if (!el) {
            throw new Error(
                `Failed to find the element with ID '${anchorElId}'. Make sure the <PageControlsAnchor /> component is rendered in the DOM.`,
            );
        }
        setAnchorElement(el);
    }, []);
    if (!anchorElement) {
        return null;
    }
    return ReactDOM.createPortal(children, anchorElement);
}
