import { getEnv } from '~/adapters/config/env';

interface CarouselImageUrlProps {
    compositeId: string;
    productId: string;
    side: string;
    fullSize: boolean;
    updatedAt?: Date;
}
const carouselImageUrl = ({ productId, compositeId, side, fullSize, updatedAt }: CarouselImageUrlProps) => {
    const sizeParams = fullSize ? '' : '&pblegacysize=small&pblegacywm=1';
    const digestParams = updatedAt ? `&digest=${updatedAt.getTime()}` : '';
    return `${getEnv(
        'ACCOUNTS_PROOF_URL',
    )}/mms/images/catalog/colors/${productId}/views/alt/${side}_medium_extended.png?design=${compositeId}&pblegacy=1${sizeParams}${digestParams}`;
};

interface ProductCarouselImageProps {
    compositeId: string;
    productId: string;
    isFrontPrintable: boolean;
    isBackPrintable: boolean;
}

export function productCarouselImages(
    products: ProductCarouselImageProps[],
    fullSize = false,
    updatedAt?: Date,
): string[] {
    return products
        .map((product) => {
            const sides = [];
            if (product.isFrontPrintable) {
                sides.push('front');
            }
            if (product.isBackPrintable) {
                sides.push('back');
            }
            return sides.map((side) =>
                carouselImageUrl({
                    productId: product.productId,
                    compositeId: product.compositeId,
                    side,
                    fullSize,
                    updatedAt,
                }),
            );
        })
        .flat();
}
