type JSONInputOutput = object | string | number | boolean | null;

/**
 * Save data to local storage serialized as JSON
 * @param key
 * @param payload anything except function or undefined
 */
export const saveToLocalStorage = (key: string, payload: JSONInputOutput) => {
    localStorage.setItem(key, JSON.stringify(payload));
};

/**
 * Get parsed JSON data from local storage
 * @param key
 */
export const getFromLocalStorage = <T = JSONInputOutput>(key: string) => {
    const value = localStorage.getItem(key);
    if (!value) {
        return undefined;
    }
    return JSON.parse(value) as T;
};
