import React, { ReactNode, useState } from 'react';
import { Select } from '@customink/pigment-react';
import { useInfiniteArts } from '~/adapters/services/accounts/arts/hooks';
import { useFlattenPaginatedData } from '~/adapters/services/shared/useFlattenPaginatedData';
import EmptyArtsList from '~/pages/Arts/EmptyArtsList';
import { useAccount } from '~/contexts/UserAccount/UserAccountContext';
import { ArtsSortBy } from '~/adapters/services/accounts/arts/rest';
import { PageControlsMount } from '~/components/PageHeader/PageControls';
import { PageSort } from '~/components/PageHeader/PageSort';
import { usePage } from '~/adapters/browser/usePage';
import { Title } from '~/pages/shared/title';
import { TypedMenuItem } from '~/adapters/designSystem/TypedMenuItem';
import { CardListLoader } from '~/components/Layout/CardListLoader';
import { ArtsCardList } from './ArtsCardList';

export interface Props {
    children?: ReactNode;
}

const ARTS_PER_PAGE = 6;

const ArtsSortByMenuItem = TypedMenuItem<ArtsSortBy>;

const Arts: React.FC<Props> = () => {
    const currentAccount = useAccount();

    usePage({ title: Title, reportPath: '/accounts/artlocker' });

    const [sortBy, setSortBy] = useState<ArtsSortBy>('-createdAt');
    const {
        data,
        fetchNextPage,
        isLoading,
        isFetching,
        hasNextPage = false,
    } = useInfiniteArts({ sortBy, accountId: currentAccount.id, perPage: ARTS_PER_PAGE });
    const arts = useFlattenPaginatedData(data, 'items');

    if (isLoading) {
        return <CardListLoader />;
    }

    return arts.length > 0 ? (
        <>
            <ArtsCardList cards={arts} loading={isFetching} onLoadMore={fetchNextPage} hasMorePages={hasNextPage} />
            <PageControlsMount>
                <PageSort>
                    <Select fullWidth value={sortBy} onChange={(ev) => setSortBy(ev.target.value as ArtsSortBy)}>
                        <ArtsSortByMenuItem value="-createdAt">Recently Uploaded</ArtsSortByMenuItem>
                        <ArtsSortByMenuItem value="-updatedAt">Recently Renamed</ArtsSortByMenuItem>
                        <ArtsSortByMenuItem value="+name">Alphabetically</ArtsSortByMenuItem>
                    </Select>
                </PageSort>
            </PageControlsMount>
        </>
    ) : (
        <EmptyArtsList />
    );
};

export default Arts;
