import { FC } from 'react';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { ProtectedUser } from '~/components/Protected/ProtectedUser';
import { UserAccountProvider } from '~/contexts/UserAccount/UserAccountProvider';

type ProtectedWithLayoutProps = PWC<{
    layoutComponent: FC<PWC>;
}>;

export const ProtectedWithLayout: FC<ProtectedWithLayoutProps> = ({ children, layoutComponent: LayoutComponent }) => (
    <ProtectedUser>
        <UserAccountProvider>
            <LayoutComponent>{children}</LayoutComponent>
        </UserAccountProvider>
    </ProtectedUser>
);
