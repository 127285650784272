import { FC, useEffect } from 'react';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { useOptionalAuthUser } from '~/contexts/Auth/AuthContext';
import { useAuth0 } from '@auth0/auth0-react';
import { Config } from '~/adapters/config/config';
import { REDIRECTING_TO_LOGIN_ERROR } from '~/contexts/Auth/error';
import { useThrowToErrorBoundary } from '~/components/ErrorBoundary';

/**
 * If user logs out from auth0 in a different application, they still might have a valid JWT in accounts-ui's local storage.
 * In that case, this guard redirects them to login. <br>
 * Not to be confused with: The case of logout happening during page lifetime is handled by `getTokens` in `Auth0UserProvider`.
 */
export const Auth0SessionGuard: FC<PWC> = ({ children }) => {
    const auth0 = useAuth0();
    const user = useOptionalAuthUser();
    const throwRenderError = useThrowToErrorBoundary();

    useEffect(() => {
        (async () => {
            // only check session if user is logged in (meaning user has valid tokens) - this ensures that we don't check session on feedback page with code access
            if (user?.isAuthenticated && Config.MODE !== 'test') {
                try {
                    await auth0.getAccessTokenSilently({
                        cacheMode: 'off',
                    });
                } catch (e) {
                    /* eslint-disable-next-line no-console */
                    console.warn('Auth0SessionGuard: User session check failed', e);
                    auth0.loginWithRedirect();
                    throwRenderError(new Error(REDIRECTING_TO_LOGIN_ERROR));
                }
            }
        })();
        // we want this really run only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{children}</>;
};
