import { Circle } from '@mui/icons-material';
import { Skeleton, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { UpperCaseChipWithHover } from '~/components/Chip/UpperCaseChip';
import { SharingStatusType } from '../types';

interface Props {
    status: SharingStatusType;
    onClick?: EmptyFunction;
    loading?: boolean;
}

export function SharingStatus({ status, onClick, loading = false }: Props) {
    const theme = useTheme();
    const StatusContentOptions = useMemo(
        () => ({
            private: { color: theme.palette.grey[500], label: 'Not shared' },
            shared: { color: theme.palette.info.main, label: 'Shared' },
            pending: { color: theme.palette.warning.main, label: 'Pending approval' },
            approved: { color: theme.palette.success.main, label: 'Approved' },
            rejected: { color: theme.palette.error.main, label: 'Update needed' },
        }),
        [theme],
    );
    const label = useMemo(() => {
        if (loading) {
            return <Skeleton animation="wave" variant="text" width={100} />;
        }

        return StatusContentOptions[status].label;
    }, [loading, status, StatusContentOptions]);

    return (
        <UpperCaseChipWithHover
            icon={<Circle style={{ color: StatusContentOptions[status].color }} />}
            disabled={loading}
            label={label}
            onClick={onClick}
        />
    );
}
