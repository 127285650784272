import { PropsWithChildren, useEffect, useState } from 'react';
import { useAuth } from '~/contexts/Auth/AuthContext';
import { FullLayoutLoader } from '~/components/Layout/FullLayoutLoader';

export function ProtectedUser({ children }: PropsWithChildren<unknown>) {
    const { user, loginWithRedirect } = useAuth();
    const [isWaitingOnAuthentication, setIsWaitingOnAuthentication] = useState(true);

    useEffect(() => {
        if (user) {
            setIsWaitingOnAuthentication(false);
            return;
        }

        loginWithRedirect();
    }, [user]);

    if (isWaitingOnAuthentication) {
        return <FullLayoutLoader devLabel="ProtectedUser (awaiting auth)" />;
    }

    return <>{children}</>;
}
