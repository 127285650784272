import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog'; // Intentionally importing from MUI and not Pigment, to separate Dialog and DialogTitle
import { useCreateAccount, useInviteAccountUsers } from '~/adapters/services/accounts/accounts/hooks';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { TeamMembersStep } from '~/components/MultiAccountManagement/Wizard/TeamMembersStep';
import { TeamNameStep } from '~/components/MultiAccountManagement/Wizard/TeamNameStep';
import { pushApiErrorNotification } from '~/adapters/notistack/notistack';
import { CreateTeamProvider, useCreateTeamContext } from '~/components/MultiAccountManagement/Wizard/CreateTeamContext';

interface CreateTeamWizardProps {
    open: boolean;
    onClose: EmptyFunction;
}

function CreateTeamWizardInternal({ open, onClose }: CreateTeamWizardProps) {
    const createAccount = useCreateAccount();
    const inviteAccountUsers = useInviteAccountUsers();
    const {
        user: { id: userId, email },
        persistCurrentAccountId,
    } = useUserAccount();

    const { teamName, inviteEmails } = useCreateTeamContext();
    const [step, setStep] = useState(0);

    const handleCreateAccount = useCallback(async () => {
        try {
            // We want to specify the contact email explicitly. Otherwise when impersonating, Inker's email would be used.
            const account = await createAccount.mutateAsync({ name: teamName, userId, contactEmail: email });
            // TODO https://customink.monday.com/boards/5878886849/views/128588597/pulses/6243708247 inviteMessage is not accepted by API
            if (inviteEmails.length > 0) {
                await inviteAccountUsers.mutateAsync({ accountId: account.id, emails: inviteEmails });
            }
            onClose();
            persistCurrentAccountId(account.id);
        } catch (error) {
            pushApiErrorNotification('There was an error creating the team or inviting users.');
        }
    }, [createAccount.mutateAsync, inviteAccountUsers.mutateAsync, userId, email, teamName, inviteEmails]);

    const handleNameSubmit = useCallback(() => {
        setStep(1);
    }, []);

    const handleMembersSubmit = useCallback(() => {
        handleCreateAccount();
    }, [handleCreateAccount]);

    const handleStepBack = useCallback(() => {
        setStep((prevStep) => prevStep - 1);
    }, []);

    return (
        <Dialog open={open} onClose={onClose} fullWidth disableRestoreFocus>
            {step === 0 && <TeamNameStep onSubmit={handleNameSubmit} onClose={onClose} totalSteps={2} />}
            {step === 1 && (
                <TeamMembersStep
                    onSubmit={handleMembersSubmit}
                    onStepBack={handleStepBack}
                    onClose={onClose}
                    totalSteps={2}
                    isPending={createAccount.isPending || inviteAccountUsers.isPending}
                />
            )}
        </Dialog>
    );
}

function CreateTeamWizard({ open, onClose }: CreateTeamWizardProps) {
    return (
        <CreateTeamProvider>
            <CreateTeamWizardInternal open={open} onClose={onClose} />
        </CreateTeamProvider>
    );
}

export default CreateTeamWizard;
