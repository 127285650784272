import { styled } from '@mui/material';
import { Container, Stack } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

const Width = 517;

const VerifyContainer = styled(Container)(({ theme }) => ({
    width: Width,
    padding: `0 ${theme.spacing(2)}`,
    marginTop: '40px',
    color: '#444444',
    [theme.breakpoints.up('lg')]: {
        marginTop: '120px',
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

export function Auth0Layout({ children }: PWC) {
    return (
        <VerifyContainer>
            <Stack spacing={3}>{children}</Stack>
        </VerifyContainer>
    );
}
