import { ApiCallError } from '~/adapters/services/shared/apiCall';
import { useAccountsApiClient } from '~/adapters/services/accounts/useAccountsApiClient';
import { useMemo } from 'react';
import { OffsetPaginationParams, offsetPaginationParams, SortParams } from '~/adapters/services/shared/createParams';
import { PaginatedParams } from '~/adapters/services/shared/types/PaginatedParams';
import { Art } from './types';
import { AccountsApiClient } from '../accountsApiClient';

export interface GetArtResponse {
    currentPage: number;
    totalCount: number;
    totalPages: number;
    hasMorePages: boolean;
    items: Art[];
}

export type ArtsSortBy = `${'+' | '-'}${'name' | 'createdAt' | 'updatedAt'}`;

export interface GetArtProps {
    accountId: number;
    sortBy: ArtsSortBy;
    perPage: number;
}

export interface RenameArtProps {
    uploadId: number;
    name: string;
}

export interface UploadArt {
    accountId: number;
    files: File[];
}

type ArtsQueryParam = OffsetPaginationParams & SortParams;

class ArtsRepository {
    constructor(private readonly client: AccountsApiClient) {}

    public getArts = ({ pageParam = 1, accountId, perPage, sortBy }: PaginatedParams<GetArtProps>) => {
        const query: ArtsQueryParam = {
            sort: sortBy,
            ...offsetPaginationParams({ perPage, page: pageParam }),
        };

        return this.client.getPaginated(
            `/accounts/{accountId}/art-locker/uploads`,
            {
                params: { path: { accountId }, query },
            },
            pageParam,
            perPage,
        );
    };

    public deleteArt = (uploadId: number) => {
        return this.client.delete(`/art-locker/uploads/{uploadId}`, {
            params: { path: { uploadId } },
        });
    };

    public renameArt = ({ uploadId, name }: RenameArtProps) => {
        return this.client.post(`/art-locker/uploads/{uploadId}`, {
            params: { path: { uploadId } },
            body: { name },
        });
    };

    public uploadArts = ({ accountId, files }: UploadArt) => {
        return Promise.all(
            files.map(async (file) => {
                return this.client
                    .post(`/accounts/{accountId}/art-locker/uploads`, {
                        params: { path: { accountId } },
                        // Note: This 'body' part is not sent; it's only for better type safety.
                        body: {
                            file: file.name,
                        },
                        bodySerializer: () => {
                            const formData = new FormData();
                            formData.set('file', file);
                            return formData;
                        },
                    })
                    .catch((error) => {
                        const errorMessage =
                            this.getErrorMessage(error) ??
                            `An error occurred while uploading the file “${file.name}”.<br/>Please try again later.`;
                        throw new Error(errorMessage);
                    });
            }),
        );
    };

    /**
     * transforms error message to get information what was going wrong
     * if the response is not in desired shape, we dont care and simply swallow the parsing error and prints default error
     */
    private getErrorMessage(error?: ApiCallError): string | null {
        return (error?.response?.data as any)?.error ?? null;
    }
}

export const useArtsRepository = () => {
    const client = useAccountsApiClient();

    return useMemo(() => new ArtsRepository(client), [client]);
};
