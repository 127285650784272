import { Grid } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { SharedDesignCard as SharedDesignCardType } from '~/adapters/services/collaboration/types';
import { CollaborationAbilityProvider } from '~/contexts/ability/collaboration/CollaborationAbilityContext';
import { useScrollLoad } from '~/adapters/browser/useScrollLoad';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import LoadMoreButton from '~/components/LoadMoreButton';
import { OldCardThemeProvider } from '~/components/OldCard/OldCardThemeProvider';
import { SharedDesignCard } from './SharedDesignCard';
import { EmptySharedDesignList } from './EmptySharedDesignList';

interface SharedDesignCardListProps {
    cards: Array<SharedDesignCardType>;
    onLoadMore: EmptyFunction;
    loading: boolean;
    hasMorePages?: boolean;
}

export function SharedDesignCardList({ cards, onLoadMore, loading, hasMorePages }: PWC<SharedDesignCardListProps>) {
    const ref = useScrollLoad({
        load: onLoadMore,
        enabled: !loading,
    });

    return (
        <>
            <Grid container ref={ref} spacing={3}>
                <OldCardThemeProvider>
                    {cards.map((card, index) => (
                        <Grid item sm={12} md={6} lg={4} key={card.id}>
                            <CollaborationAbilityProvider resource={card}>
                                <SharedDesignCard card={card} cardIndex={index + 1} />
                            </CollaborationAbilityProvider>
                        </Grid>
                    ))}
                </OldCardThemeProvider>
            </Grid>
            {cards.length === 0 && <EmptySharedDesignList />}
            {hasMorePages && <LoadMoreButton onClick={onLoadMore} loading={loading} />}
        </>
    );
}
