import { Button, Tooltip } from '@customink/pigment-react';
import { ConfirmDeleteDialog } from '~/components/ConfirmDeleteDialog';
import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { useGetAccountUsers, useRemoveAccountUser } from '~/adapters/services/accounts/accounts/hooks';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { DestructiveButton } from '~/components/DestructiveButton';

type LeaveTeamExperienceProps = {
    /**
     * A render prop that renders the CTA that this confirmation belongs to. See ConfirmDeleteDialogProps for more details.
     * @param openConfirmDialog
     * @param disabled whether the leave team button should be disabled
     */
    children: <T extends never>(openConfirmDialog: MouseEventHandler<T>, disabled: boolean) => ReactElement<T>;
};

export function LeaveTeamExperience({ children: renderOpeningCTA }: LeaveTeamExperienceProps) {
    const { mutateAsync: removeUserFromAccount } = useRemoveAccountUser();
    const {
        account: { id: accountId },
        user: { id: userId },
        persistCurrentAccountId,
    } = useUserAccount();
    const handleLeaveTeam = useCallback(async () => {
        return removeUserFromAccount({ accountId, userId }).finally(() => persistCurrentAccountId('personal'));
    }, [removeUserFromAccount, accountId, userId]);
    const { data: accountUsers } = useGetAccountUsers(accountId);

    const isLeaveTeamDisabled = accountUsers?.length === 1;

    const renderOpeningCtaMaybeDisabled = useCallback(
        <T extends never>(openConfirmDialog: MouseEventHandler<T>) =>
            renderOpeningCTA(openConfirmDialog, isLeaveTeamDisabled),
        [renderOpeningCTA, isLeaveTeamDisabled],
    );

    return (
        <Tooltip title={isLeaveTeamDisabled ? 'You cannot leave the team because you are the last member' : null}>
            <span>
                <ConfirmDeleteDialog
                    onDeleteConfirmed={handleLeaveTeam}
                    alertText={null}
                    additionalText="To rejoin the team later, you will need an invite from another team member."
                    confirmButtonLabel="Leave Team"
                >
                    {renderOpeningCtaMaybeDisabled}
                </ConfirmDeleteDialog>
            </span>
        </Tooltip>
    );
}

export function TeamManagement() {
    return (
        <>
            <DestructiveButton sx={{ mr: 1 }}>Delete Team</DestructiveButton>
            <LeaveTeamExperience>
                {(openConfirmDialog, disabled) => (
                    <Button onClick={openConfirmDialog} variant="secondary" disabled={disabled}>
                        Leave Team
                    </Button>
                )}
            </LeaveTeamExperience>
        </>
    );
}
