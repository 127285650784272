import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAuthRedirect } from '~/adapters/browser/session';
import { useAuth } from '~/contexts/Auth/AuthContext';
import { FullLayoutLoader } from '~/components/Layout/FullLayoutLoader';
import { Auth0Layout } from '~/components/Layout/Auth0Layout';
import { Auth0Error } from '~/pages/Auth0Error/Auth0Error';

export function AuthReceiver() {
    const navigate = useNavigate();
    const path = getAuthRedirect();
    const { loginSilently } = useAuth();
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                await loginSilently();
                navigate(path);
            } catch (e) {
                /* eslint-disable-next-line no-console */
                console.warn('Error in auth receiver', e);
                setIsError(true);
            }
        })();
    });

    if (isError) {
        return (
            <Auth0Layout>
                <Auth0Error />
            </Auth0Layout>
        );
    }

    return <FullLayoutLoader devLabel="AuthReceiver" />;
}
