import { Button, PlusIcon, ScreenReadersOnly, Stack, Typography } from '@customink/pigment-react';
import { usePage } from '~/adapters/browser/usePage';
import { InviteMembersDialog } from '~/pages/Settings/tabs/Members/InviteMembersDialog';
import { useState } from 'react';
import { Title } from '../../title';
import { MembersTable } from './MembersTable';

export function MembersTab() {
    usePage({ title: Title, reportPath: '/accounts/members' });

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <>
            <ScreenReadersOnly>
                <h2>Members</h2>
            </ScreenReadersOnly>
            <Stack direction="row" justifyContent="right" spacing={1} mb={3}>
                <Button onClick={() => setIsDialogOpen(true)} variant="secondary" startIcon={<PlusIcon />}>
                    Invite Members
                </Button>
                {isDialogOpen && <InviteMembersDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />}
            </Stack>
            <Typography textAlign="end" component="p" variant="captionSmall" m={1}>
                Development status disclaimer: Role, State and &hellip; are WIP
            </Typography>
            <MembersTable />
        </>
    );
}
