import isEmail from 'validator/es/lib/isEmail';

const emailSeparatorRegex = /[ ,;]+/;

type ErrorType = 'invalid' | 'duplicate' | 'excluded';
type StatusType = 'ok' | ErrorType;
interface ProcessInputSuccessResult {
    status: 'ok';
    newEmails: string[];
    newInputValue: string;
}
interface ProcessInputFailureResult {
    status: ErrorType;
    newEmails: string[];
    newInputValue: string;
    errorMessage: string;
}
const errorMessages: Record<StatusType, string> = {
    ok: '',
    invalid: 'Enter valid email address(es).',
    duplicate: 'Email is already entered',
    excluded: 'You cannot enter your own email',
};

function checkEmailValidity(email: string, emails: string[], excludedEmail: string): StatusType {
    // the very same validation library is used at backend ("class-validator" is used there, which wraps "validator" functions)
    if (!isEmail(email)) {
        return 'invalid';
    }
    const emailsLCase = emails.map((e) => e.toLowerCase());
    if (emailsLCase.includes(email.toLowerCase())) {
        return 'duplicate';
    }
    if (email.toLowerCase() === excludedEmail.toLowerCase()) {
        return 'excluded';
    }
    return 'ok';
}

// this function has to be generic enough to parse not just a single email, but string with multiple delimited emails (from copy+paste)
// see spec file to understand the expected behavior
export function processInputToEmails(
    inputValue: string,
    emails: string[],
    excludedEmail = '',
): ProcessInputSuccessResult | ProcessInputFailureResult {
    let status: StatusType = 'ok'; // only the last error will be displayed (helper text isn't suited to display long text)
    const incorrectEmails: string[] = [];
    const correctEmails: string[] = [];

    const splittedInputValue = inputValue
        .split(emailSeparatorRegex)
        .map((item) => item.trim())
        .filter((item) => item.length);

    splittedInputValue.forEach((item) => {
        const allCurrentEmails = [...emails, ...correctEmails]; // to eliminate duplicates also in the copy-pasted text
        const emailStatus = checkEmailValidity(item, allCurrentEmails, excludedEmail);
        if (emailStatus === 'ok') {
            correctEmails.push(item);
        } else {
            incorrectEmails.push(item);
            status = emailStatus;
        }
    });

    return {
        status,
        newEmails: [...emails, ...correctEmails],
        newInputValue: incorrectEmails.join(', '), // the input value is rebuilt from the array of incorrect emails
        ...(status !== 'ok' && { errorMessage: errorMessages[status] }),
    };
}
