import { PropsWithChildren, ReactNode } from 'react';
import { Container } from '@customink/pigment-react';
import { AppLayout, MainContentLayout, Content } from '~/components/Layout/FullLayoutComponents';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import WelcomeTopMenu from '../WelcomeTopMenu';
import Navigation from '../Navigation';

export interface Props {
    children?: ReactNode;
}

const FullLayoutContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

export function FullLayout({ children }: PropsWithChildren<unknown>) {
    return (
        <FullLayoutContainer maxWidth="xl">
            <AppLayout>
                <WelcomeTopMenu />
                <MainContentLayout>
                    <Navigation />
                    <Content>{children}</Content>
                </MainContentLayout>
            </AppLayout>
        </FullLayoutContainer>
    );
}
