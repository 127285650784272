import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { usePreservedQueryParam } from '~/adapters/browser/usePreservedQueryParam';

export function useRouteTabs<T extends readonly string[]>(...paths: T): [T[number], (path: T[number]) => void] {
    const location = useLocation();
    const navigate = useNavigate();
    const [selected, setSelected] = useState(() => paths.find((path) => path === location.pathname) ?? paths[0]);

    const preservedParams = usePreservedQueryParam();
    const select = useCallback(
        (path: T[number]) => {
            navigate(path + preservedParams);
            setSelected(path);
        },
        [setSelected, preservedParams],
    );

    return [selected, select];
}
