import Signaler from '@customink/signalerjs';

/*
 Rules:
 - Sum of all variants of `flags` should be 1
 - When you are changing rollout values of `flags`, you might consider changing name of feature flag, based on D&A req (for example add_to_cart_notification_v0 -> add_to_cart_notification_v1)
 - you can change value of feature flag in console, e.g. window.signaler.setFeatureFlag('add_to_cart_notification_v0', 'test')
 - docs https://github.com/customink/signalerjs/tree/master
 */
export const featuresDefinition = {
    designs_page_art_proofs_v0: {
        flags: {
            test: 0,
            control: 0,
            off: 1,
        },
    },
    add_to_cart_notification_v0: {
        flags: {
            test: 0,
            control: 0,
            off: 1,
        },
    },
    team_accounts_v0: {
        flags: {
            test: 0,
            control: 0,
            off: 1,
        },
    },
    flags_page: {
        flags: {
            test: 0,
            off: 1,
        },
    },
    art_proof_lab_link_v1: {
        flags: {
            test: 1,
            control: 0,
            off: 0,
        },
        launchDate: '2024 10 21',
    },
    tax_exemption_cert_thumbnail: {
        flags: {
            test: 0,
            off: 1,
        },
    },
    impersonate_fundraising_v2: {
        flags: {
            test: 1,
            off: 0,
        },
    },
    embedded_tax_exemptions_v0: {
        flags: {
            test: 0,
            off: 1,
        },
    },
} as const satisfies Record<string, FeatureDefinition>;

export type FeatureDefinition = {
    // readability note: consistent term for this field would have been `variations` or `possibleValues`, but the signaler.js library prescribes `flags`
    flags: Record<string, number>;
    launchDate?: `${number} ${number} ${number}`; // "YYYY MM DD"
};

export type SignalerFeatureFlagName = keyof typeof featuresDefinition;

export const signaler = new Signaler(featuresDefinition);
window.signaler = signaler;
