import { useCallback } from 'react';
import { useAuth, useOptionalAuthUser } from '~/contexts/Auth/AuthContext';

export function useAuthenticatedCta<T extends unknown[]>(cta: (...params: T) => void): (...params: T) => Promise<void> {
    const user = useOptionalAuthUser();
    const { loginWithPopup } = useAuth();

    return useCallback(
        async (...params: T) => {
            if (!user?.isAuthenticated) {
                await loginWithPopup();
            }
            cta(...params);
        },
        [cta, user],
    );
}
