import { useTheme } from '@mui/material';
import { useCallback, useState } from 'react';

export function CarouselProductImage({
    alt,
    src,
    initialAspectRatio = '1 / 0.95',
    href,
}: {
    alt: string;
    src: string;
    initialAspectRatio?: string;
    href?: string;
}) {
    const [loaded, setLoaded] = useState(false);
    const theme = useTheme();
    const Img = useCallback(
        () => (
            <img
                onLoad={() => setLoaded(true)}
                alt={alt}
                src={src}
                key={src}
                style={{
                    width: 'inherit',
                    aspectRatio: loaded ? 'unset' : initialAspectRatio,
                    background: theme.palette.grey[300],
                    transition: 'height 0.5s',
                    textAlign: 'center', // for the alt text
                    color: theme.palette.grey[500],
                }}
            />
        ),
        [alt, src, loaded, theme.palette.grey[300], theme.palette.grey[500]],
    );
    return href ? (
        <a href={href} style={{ width: 'inherit' }}>
            <Img />
        </a>
    ) : (
        <Img />
    );
}
