import { keepPreviousData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDesignsProxyRepository } from './rest';
import { InfiniteDesignProps } from './types';

const infiniteDesignsKey = 'infinite-designs';

export function useInfiniteDesigns(filter: InfiniteDesignProps) {
    const { getDesigns } = useDesignsProxyRepository();

    return useInfiniteQuery({
        queryKey: [infiniteDesignsKey, filter],
        queryFn: ({ pageParam }) => getDesigns({ pageParam, ...filter }),
        initialPageParam: 1,
        getNextPageParam: (lastData) => (lastData.hasMorePages ? lastData.currentPage + 1 : undefined),
        placeholderData: keepPreviousData,
    });
}

export function useDesignDelete() {
    const { deleteDesign } = useDesignsProxyRepository();
    const client = useQueryClient();

    return useMutation({
        mutationFn: deleteDesign,
        onSuccess: () => client.invalidateQueries({ queryKey: [infiniteDesignsKey] }),
    });
}

export const designSummaryKey = 'design-summary';
export function useDesignSummary(cid: string) {
    const { getDesignSummary } = useDesignsProxyRepository();
    return useQuery({
        queryKey: [designSummaryKey, cid],
        queryFn: () => getDesignSummary(cid),
        retry: 1,
    });
}

export const designQuoteKey = 'design-quote';
export function useDesignQuote(cid: string) {
    const { getDesignQuote } = useDesignsProxyRepository();

    return useQuery({
        queryKey: [designQuoteKey, cid],
        queryFn: () => getDesignQuote(cid),
    });
}

export const productDetailsKey = 'product-details';
export function useProductDetails(cid: string, enabled = true) {
    const { getProductDetails } = useDesignsProxyRepository();

    return useQuery({
        queryKey: [productDetailsKey, cid],
        queryFn: () => getProductDetails(cid),
        enabled,
    });
}
