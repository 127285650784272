import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { IconButton, RowsIcon, useMobileBreakpoint } from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { trackEvent } from '~/adapters/tracking/tracking';

export const ToggleIcon = styled(RowsIcon, {
    shouldForwardProp: (prop) => prop !== 'showColumns',
})<{ showColumns: boolean }>(({ showColumns, theme }) => ({
    transform: showColumns ? 'rotate(90deg)' : 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
}));

type ColumnRowToggleProps = PWC<{
    showColumns: boolean;
    setShowColumns: Dispatch<SetStateAction<boolean>>;
}>;

export const ColumnRowMobileToggle: FC<ColumnRowToggleProps> = ({ showColumns, setShowColumns }) => {
    const handleColumnsSwitchClick = useCallback(() => {
        trackEvent({
            category: 'mobile column change',
            action: 'click',
            label: showColumns ? '2-column' : '1-column',
            event: 'interaction',
        });
        setShowColumns((prev) => !prev);
    }, [showColumns]);
    const isMobile = useMobileBreakpoint();
    if (!isMobile) {
        return null;
    }
    return (
        <IconButton onClick={handleColumnsSwitchClick}>
            <ToggleIcon showColumns={showColumns} />
        </IconButton>
    );
};
