import { useCallback } from 'react';
import setLocation from '~/adapters/browser/setLocation';
import { useRfeRepository } from './rest';

export const useAddToCartOrRedirect = () => {
    const { addDesignsToCart } = useRfeRepository();

    return useCallback(async (cids: string[]) => {
        await addDesignsToCart(cids).then((link) => {
            // Will trigger re-rendering of the cart component
            window.dispatchEvent(new CustomEvent('cartCookieUpdated'));
            // this is not expected; addDesignsToCart only returns the link as an error fallback
            if (link) {
                setLocation(link);
            }
        });
    }, []);
};
