import { useAccount } from '~/contexts/UserAccount/UserAccountContext';
import { LabelValueRow } from '~/pages/Settings/components/LabelValueTable';
import { CopyValueButton } from '~/components/CopyValueButton';

export const AccountIdRow = () => {
    const { id: accountID } = useAccount();
    return (
        <LabelValueRow
            label="Account ID"
            value={<code>{accountID}</code>}
            action={
                <CopyValueButton
                    valueToCopy={String(accountID)}
                    doneChildren="Copied"
                    variant="secondary"
                    sx={{ minWidth: 110 }}
                >
                    Copy
                </CopyValueButton>
            }
        />
    );
};
