import DataLoader from 'dataloader';
import hash from 'object-hash';
import { Query } from '@tanstack/react-query';
import { batchRequests, RequestDocument, Variables } from 'graphql-request';

interface DocumentAndVariables {
    document: RequestDocument;
    variables: Variables;
}

// record of DataLoader instances, each identified by hash of its defining parameters
const loaders: Record<string, DataLoader<any, any>> = {};

const defaultTimeWindow = 5; // milliseconds

export function graphqlLoader(url: string, headers: Headers, timeWindow = defaultTimeWindow) {
    const loaderConfigHash = hash({ url, headers: Object.fromEntries(headers), timeWindow });
    if (loaders[loaderConfigHash]) {
        return loaders[loaderConfigHash];
    }
    const newLoader = new DataLoader<DocumentAndVariables, Query>(
        async (keys: readonly DocumentAndVariables[]): Promise<Query[]> => {
            // for some reason, batchRequests wraps response in {data: response}
            const response = await batchRequests(
                url,
                keys as DocumentAndVariables[], // because batchRequest doesn't want it readonly
                headers,
            );
            return response.map((item) => item.data) as Query[];
        },
        {
            cache: false, // because caching is already done by react-query
            batchScheduleFn: (callback) => setTimeout(callback, timeWindow),
        },
    );
    loaders[loaderConfigHash] = newLoader;
    return newLoader;
}
