import { normalizeUser } from '~/adapters/services/accounts/users/transformers/userTransformer';
import { ApiCall } from '~/adapters/services/shared/apiCall';
import { useProfilesApiCall } from '~/adapters/services/profiles/profilesApiCall';
import { useMemo } from 'react';
import { AdminApiResponse, CopyResetPasswordLink } from './types';

interface ImpersonatedUserDetailProps {
    email: string;
}

interface ResetPasswordLinkProps {
    id: number;
}

class AdminRepository {
    constructor(private readonly profilesApiCall: ApiCall) {}

    public getImpersonatedUserDetail = ({ email }: ImpersonatedUserDetailProps) => {
        return this.profilesApiCall<AdminApiResponse>(`/admin/search.json?q=${encodeURIComponent(email)}`, {
            attachImpersonate: false,
        })
            .then((r) => r.data)
            .then((d) =>
                normalizeUser({
                    firstName: d.first_name,
                    lastName: d.last_name,
                    accountId: d.account_id,
                    email: d.email,
                    id: d.id,
                }),
            )
            .catch((err) => {
                // it is a valid case that account exists, but profile does not, in that case keep the "null" value instead of going on failure path
                if (err.response.status === 404) {
                    return null;
                }
                throw err;
            });
    };

    public copyResetPasswordLink = ({ id }: ResetPasswordLinkProps): Promise<CopyResetPasswordLink> => {
        return this.profilesApiCall<CopyResetPasswordLink>(`/admin/password_edit_link/${id}.json`, {
            method: 'POST',
            attachImpersonate: false,
        }).then((r) => r.data);
    };

    public sendResetPasswordLink = ({ id }: ResetPasswordLinkProps): Promise<void> => {
        return this.profilesApiCall<void>(`/admin/password_send/${id}.json`, {
            method: 'POST',
            attachImpersonate: false,
        }).then((r) => r.data);
    };
}

export const useAdminRepository = () => {
    const profilesApiCall = useProfilesApiCall();

    return useMemo(() => new AdminRepository(profilesApiCall), []);
};
