import { useMemo } from 'react';
import { useInventoryApiCall } from '~/adapters/services/inventory/inventoryApiCall';
import { ApiCall } from '~/adapters/services/shared/apiCall';

// To be defined
interface RedirectResponse {
    redirectUrl: string;
}

// To be defined
interface GetInventoryRedirectProps {
    uuid: string;
}

class InventoryRepository {
    constructor(private readonly inventoryApiCall: ApiCall) {}

    public getInventoryRedirectURL = async ({ uuid }: GetInventoryRedirectProps): Promise<string | null> => {
        try {
            const response = await this.inventoryApiCall<RedirectResponse>(`/auth/impersonate`, {
                method: 'POST',
                params: { uuid },
            });
            return response.data.redirectUrl;
        } catch (e) {
            return null;
        }
    };
}

export const useInventoryRepository = () => {
    const inventoryApiCall = useInventoryApiCall();

    return useMemo(() => new InventoryRepository(inventoryApiCall), []);
};
