import { Alert } from '@customink/pigment-react';
import { Component, ReactNode, useCallback, useState } from 'react';
import { AppWideErrorLayout } from '~/components/Layout/AppWideErrorLayout';
import { PWC } from '../adapters/typescript/propsWithChildren';

type ErrorBoundaryProps = PWC<{
    errorMessage?: ReactNode;
    withLayout?: boolean;
}>;

interface ErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            const Content = (
                // Note that the app-upmost ErrorBoundary is rendered outside PigmentTheme provider and will have unusual/broken styles.
                <Alert severity="error">
                    <>
                        {this.props.errorMessage}. If the problem persists,
                        <a href="https://www.customink.com/contact"> contact us</a>.
                    </>
                </Alert>
            );
            return this.props.withLayout ? <AppWideErrorLayout>{Content}</AppWideErrorLayout> : Content;
        }

        return this.props.children;
    }

    static defaultProps: Partial<ErrorBoundaryProps>;
}

ErrorBoundary.defaultProps = {
    withLayout: false,
    errorMessage: 'An unknown error has occurred, please try again later',
};

/**
 * This helper should be used for throwing error from code which is running outside React render cycle (async, interval etc...)
 */
export function useThrowToErrorBoundary() {
    const [, errorThrow] = useState(null);

    return useCallback(
        (error: unknown) =>
            // this little trick is based on gaeron github comment https://github.com/facebook/react/issues/14981#issuecomment-468460187
            errorThrow(() => {
                throw error;
            }),
        [errorThrow],
    );
}
