import { trackEvent } from '~/adapters/tracking/tracking';

const ProfilesPasswordModalCategory = 'Profiles Password Modal';
const ProfilesNameModalCategory = 'Profiles Name Modal';

export function sendPasswordModalEvent(action: string): void {
    trackEvent({ category: ProfilesPasswordModalCategory, action });
}

export function sendNameModalEvent(action: string): void {
    trackEvent({ category: ProfilesNameModalCategory, action });
}
