import { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

/**
 * In v6, react-router removed useRouteMatch, which supported array of paths, in favor of useMatch, which supports only string.
 * This is a back-fill for that functionality using matchPath, which is used under-the-hood by useMatch.
 */
export const useMatchMultiple = () => {
    const { pathname } = useLocation();
    return useCallback(
        (pathInput: string | string[]): boolean =>
            Array.isArray(pathInput)
                ? pathInput.some((path) => matchPath(path, pathname))
                : Boolean(matchPath(pathInput, pathname)),
        [pathname],
    );
};
