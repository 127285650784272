import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Box } from '@customink/pigment-react';

/**
 * Box that adds empty space around it. Useful in context when you have small contents that need to be
 * highlighted visually.
 * Example: use on empty pages for display of the 'create content' main CTA.
 */
export const CushionedBox = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        textAlign: 'center',
        my: 5,
        [theme.breakpoints.only('lg')]: {
            my: 8,
        },
        [theme.breakpoints.up('xl')]: {
            my: 10,
        },
    }),
);
