import { ChangeEvent, KeyboardEvent, useMemo, useRef } from 'react';
import { TextField, useTheme } from '@mui/material';
import { AlertWarningIcon, Chip } from '@customink/pigment-react';
import { useMeasureTextWidth } from '~/adapters/browser/useMeasureTextWidth';
import { useAuthUser } from '~/contexts/Auth/AuthContext';
import { processInputToEmails } from './processInputToEmails';

interface MultipleEmailInputProps {
    emails: string[];
    setEmails: (values: string[]) => void;
    inputValue: string;
    setInputValue: (value: string) => void;
    errorMessage: string;
    setErrorMessage: (value: string) => void;
    dataAction?: string;
    label?: string;
    autoFocus?: boolean;
}

const isKeySeparator = (key: string): boolean => ['Enter', 'Tab', ',', ';', ' '].includes(key);

const lineHeight = 24; // chip and input have to be scaled to a consistent line height (rendered next to each other) [px]

const ErrorHelperText = ({ message }: { message: string }) => (
    <>
        <AlertWarningIcon fontSize="small" style={{ paddingRight: 5 }} />
        {message}
    </>
);

export function MultipleEmailInput({
    emails,
    setEmails,
    inputValue,
    setInputValue,
    errorMessage,
    setErrorMessage,
    dataAction,
    label = 'Enter Email Address(es)',
    autoFocus = false,
}: MultipleEmailInputProps) {
    const theme = useTheme();
    const { email: myEmail } = useAuthUser();

    const TextFieldRef = useRef<HTMLElement>(null);
    const calculatedInputWidth = useMeasureTextWidth(inputValue, TextFieldRef.current || undefined);

    const applyChanges = (newInputValue: string) => {
        const result = processInputToEmails(newInputValue, emails, myEmail);
        setErrorMessage(result.status === 'ok' ? '' : result.errorMessage);
        setEmails(result.newEmails);
        setInputValue(result.newInputValue);
    };

    const deleteEmail = (email: string) => setEmails(emails.filter((item) => item !== email));

    const breakChipToText = (email: string) => {
        const newInputValue = inputValue ? `${inputValue}, ${email}` : email;
        setInputValue(newInputValue);
        deleteEmail(email);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        // both regex operations are here to prevent flickering when you write a separator at the end with incorrect input
        const separatorAtEndRegex = /[ ,;]$/;
        setInputValue(e.target.value.replace(separatorAtEndRegex, ''));
        if (!separatorAtEndRegex.test(e.target.value)) {
            setErrorMessage('');
        }
    };

    const handleOnClick = (email: string) => () => {
        breakChipToText(email);
        TextFieldRef.current?.focus();
    };

    const handleDelete = (email: string) => () => {
        deleteEmail(email);
        TextFieldRef.current?.focus();
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (isKeySeparator(e.key) && inputValue.length) {
            applyChanges(inputValue);
            e.stopPropagation();
            e.preventDefault();
            return;
        }
        const shouldRemoveEmail = !inputValue.length && emails.length;
        if (e.key === 'Backspace' && shouldRemoveEmail) {
            breakChipToText(emails[emails.length - 1]);
            e.preventDefault();
        }
    };

    const emailChipList = useMemo(
        () =>
            emails.map((emailString) => (
                <Chip
                    key={emailString}
                    tabIndex={-1}
                    label={emailString}
                    onDelete={handleDelete(emailString)}
                    onClick={handleOnClick(emailString)}
                    size="medium"
                    sx={{
                        m: 0.5,
                        height: lineHeight,
                        '&,&:hover': { backgroundColor: theme.palette.grey[300] },
                    }}
                />
            )),
        [emails, inputValue, theme],
    );

    return (
        <TextField
            label={label}
            placeholder={emailChipList.length ? undefined : 'Comma separated'}
            InputProps={{
                startAdornment: emailChipList,
                // style of the whole outlined box, where (chip adornments + text input) freely flow
                sx: { p: 0.5, pr: 1.5, display: 'flex', overflow: 'hidden', flexWrap: 'wrap' },
            }}
            // inputProps are applied to the DOM element while InputProps to the React component
            inputProps={{ 'data-action': dataAction, 'data-testid': dataAction }}
            onChange={handleChange}
            value={inputValue}
            onKeyDown={handleKeyDown}
            fullWidth
            inputRef={TextFieldRef}
            sx={{
                mt: 0.5, // to horizontally align MUI TextField with Pigment Select
                // style of the text input itself (one of the elements that flow within flexbox)
                '& .MuiInputBase-input ': { width: calculatedInputWidth, flexGrow: 1, p: 0.5, minHeight: lineHeight },
            }}
            error={Boolean(errorMessage)}
            helperText={errorMessage && <ErrorHelperText message={errorMessage} />}
            autoFocus={autoFocus}
        />
    );
}
