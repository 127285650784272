import { Config, ConfigType } from '~/adapters/config/config';
import { getConfigFromSession } from '~/adapters/browser/sessionStorage';

/**
 * This should be used for getting current setting of env variable. (For example when calling api)
 * On dev environments it prefers setting from session storage over static config
 * @param key - name of env variable
 */
export function getEnv<T extends keyof ConfigType>(key: T): ConfigType[T] {
    if (import.meta.env.MODE !== 'production') {
        const sessionStorageValue = getConfigFromSession()[key];
        return sessionStorageValue ?? Config[key];
    }

    return Config[key];
}
