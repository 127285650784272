import { useTheme } from '@mui/material';
import { CardMedia, Carousel, useMobileBreakpoint } from '@customink/pigment-react';
import { CATEGORY, trackEvent } from '~/adapters/tracking/tracking';
import { AnalyticsConstants } from '~/adapters/tracking/AnalyticsConstants';
import { CarouselProductImage } from '~/components/Image/CarouselProductImage';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';

interface DesignCardMediaProps {
    cardIndex: number;
    carouselImages: string[];
    onSlideClick?: EmptyFunction;
    indicateAction?: boolean;
    href?: string;
}

function sendCarouselEvent({ action, cardIndex }: { action: string; cardIndex: number }): void {
    const category = `${CATEGORY} saved designs category`;
    trackEvent({ category, action, dimension: { [AnalyticsConstants.cardIndex]: cardIndex } });
}

export function DesignCardMedia({
    cardIndex,
    carouselImages,
    onSlideClick,
    indicateAction,
    href,
}: DesignCardMediaProps) {
    const isMobile = useMobileBreakpoint();
    const slides = carouselImages.map((uri) => (
        <CarouselProductImage alt="Product preview" src={uri} key={uri} href={href} />
    ));
    const theme = useTheme();

    return (
        <CardMedia
            sx={{
                position: 'relative',
                // keen-slider sets the cursor directly on element, so the only way to override it is with !important
                '& .keen-slider__slide': { cursor: indicateAction ? 'pointer !important' : 'initial' },
            }}
        >
            <Carousel
                slides={slides}
                loop={false}
                sliderHeight="auto"
                arrows={!isMobile}
                onPreviousClick={() => sendCarouselEvent({ action: 'view back', cardIndex })}
                onNextClick={() => sendCarouselEvent({ action: 'view next', cardIndex })}
                onSlideClick={onSlideClick}
                sliderStyle={{
                    borderRadius: isMobile ? theme.spacing(1) : undefined,
                    overflow: 'hidden',
                }}
            />
        </CardMedia>
    );
}
