import { Dispatch, FC, MouseEvent, ReactNode } from 'react';
import { Typography, useMobileBreakpoint } from '@customink/pigment-react';
import { MenuItem, ButtonBase } from '@mui/material';

type ResponsiveMenuItemProps = {
    onClick: Dispatch<MouseEvent<HTMLElement>>;
    label: ReactNode;
    description?: ReactNode;
    disabled?: boolean;
};

/**
 * A responsive menu item intended to be used within ResponsiveMenu.
 */
export const ResponsiveMenuItem: FC<ResponsiveMenuItemProps> = ({ onClick, label, description, disabled }) => {
    const isMobile = useMobileBreakpoint();

    return isMobile ? (
        <ButtonBase
            onClick={onClick}
            disabled={disabled}
            sx={{ flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', gap: 1 }}
        >
            <Typography variant="buttonMd" fontWeight={600}>
                {label}
            </Typography>
            <Typography variant="uiTextTiny" lineHeight="100%">
                {description}
            </Typography>
        </ButtonBase>
    ) : (
        <MenuItem onClick={onClick} disabled={disabled}>
            {label}
        </MenuItem>
    );
};
