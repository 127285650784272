export const Config = {
    ACCOUNTS_BASE_CONTEXT: import.meta.env.ACCOUNTS_BASE_CONTEXT,
    ACCOUNTS_STORE_URL: import.meta.env.ACCOUNTS_STORE_URL,
    ACCOUNTS_FUNDRAISING_URL: import.meta.env.ACCOUNTS_FUNDRAISING_URL,
    ACCOUNTS_INVENTORY_URL: import.meta.env.ACCOUNTS_INVENTORY_URL,
    ACCOUNTS_INVENTORY_API_URL: import.meta.env.ACCOUNTS_INVENTORY_API_URL,
    ACCOUNTS_PROFILES_URL: import.meta.env.ACCOUNTS_PROFILES_URL,
    ACCOUNTS_PROOF_URL: import.meta.env.ACCOUNTS_PROOF_URL,
    ACCOUNTS_RFE_URL: import.meta.env.ACCOUNTS_RFE_URL,
    ACCOUNTS_NDX_ADD_PRODUCT_URL: import.meta.env.ACCOUNTS_NDX_ADD_PRODUCT_URL,
    ACCOUNTS_NDX_URL: import.meta.env.ACCOUNTS_NDX_URL,
    ACCOUNTS_LOGOUT_URL: import.meta.env.ACCOUNTS_LOGOUT_URL,
    ACCOUNTS_LOGIN_URL: import.meta.env.ACCOUNTS_LOGIN_URL,
    ACCOUNTS_UPLOAD_SERVICE_URL: import.meta.env.ACCOUNTS_UPLOAD_SERVICE_URL,
    ACCOUNTS_AVALARA_EXEMPTIONS_API_URL: import.meta.env.ACCOUNTS_AVALARA_EXEMPTIONS_API_URL,
    ACCOUNTS_COLLABORATION_URL: import.meta.env.ACCOUNTS_COLLABORATION_URL,
    ACCOUNTS_ACCOUNTS_SERVICE_URL: import.meta.env.ACCOUNTS_ACCOUNTS_SERVICE_URL,
    ACCOUNTS_PROFILES_CLIENT_ID: import.meta.env.ACCOUNTS_PROFILES_CLIENT_ID,
    ACCOUNTS_GA: import.meta.env.ACCOUNTS_GA,
    ACCOUNTS_AUTH0_DOMAIN: import.meta.env.ACCOUNTS_AUTH0_DOMAIN,
    ACCOUNTS_AUTH0_CLIENT_ID: import.meta.env.ACCOUNTS_AUTH0_CLIENT_ID,
    ACCOUNTS_AUTH0_AUDIENCE: import.meta.env.ACCOUNTS_AUTH0_AUDIENCE,
    MODE: import.meta.env.MODE,
    VITE_RELEASE: import.meta.env.VITE_RELEASE,
};

export type ConfigType = typeof Config;
