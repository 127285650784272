import { useState, useEffect } from 'react';

const useScript = (src: string) => {
    const [isLoaded, setLoaded] = useState(false);
    const [isError, setError] = useState(false);

    useEffect(() => {
        const scriptEl = document.createElement('script');
        scriptEl.src = src;
        scriptEl.async = true;

        const onScriptLoad = () => {
            setLoaded(true);
        };

        const onScriptError = () => {
            setError(true);
        };

        scriptEl.addEventListener('load', onScriptLoad);
        scriptEl.addEventListener('error', onScriptError);

        document.body.appendChild(scriptEl);

        return () => {
            scriptEl.removeEventListener('load', onScriptLoad);
            scriptEl.removeEventListener('error', onScriptError);
        };
    }, [src]);

    return { isLoaded, isLoading: !isLoaded, isError };
};

export default useScript;
