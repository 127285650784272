import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { EnabledQueryOption } from '~/adapters/TanStackQuery/types';
import { GetStoresProps, useStoresRepository } from './rest';

export const useInfiniteHealthAndWellnessOrders = (props: GetStoresProps) => {
    const { getHealthAndWellnessOrders } = useStoresRepository();

    return useInfiniteQuery({
        queryKey: ['health-and-wellness', props],
        queryFn: ({ pageParam }) => getHealthAndWellnessOrders({ pageParam, ...props }),
        initialPageParam: 1,
        getNextPageParam: (lastData) => (lastData.hasMorePages ? lastData.currentPage + 1 : undefined),
        placeholderData: keepPreviousData,
    });
};

export const useInfiniteStores = (props: GetStoresProps, { enabled }: EnabledQueryOption = {}) => {
    const { getStores } = useStoresRepository();

    return useInfiniteQuery({
        queryKey: ['items', props],
        queryFn: ({ pageParam }) => getStores({ pageParam, ...props }),
        initialPageParam: 1,
        getNextPageParam: (lastData) => (lastData.hasMorePages ? lastData.currentPage + 1 : undefined),
        placeholderData: keepPreviousData,
        enabled,
    });
};

export const useInfiniteStoresGroupOrders = (props: GetStoresProps) => {
    const { getStoresGroupOrders } = useStoresRepository();

    return useInfiniteQuery({
        queryKey: ['stores-group-orders', props],
        queryFn: ({ pageParam }) => getStoresGroupOrders({ pageParam, ...props }),
        initialPageParam: 1,
        getNextPageParam: (lastData) => (lastData.hasMorePages ? lastData.currentPage + 1 : undefined),
        placeholderData: keepPreviousData,
    });
};

export const useStoreGroupOrdersCount = (props: GetStoresProps, { enabled }: EnabledQueryOption = {}) => {
    const { getStoreGroupOrdersCount } = useStoresRepository();

    return useQuery({
        queryKey: ['store-group-orders-count', props],
        queryFn: () => getStoreGroupOrdersCount(props),
        enabled,
    });
};
