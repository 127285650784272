import { MouseEventHandler } from 'react';
import { Avatar, AvatarGroup, Box } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { AvatarSkeleton } from './AvatarSkeleton';
import { useSharingAPI } from '../SharingAPIContext';

type AvatarListProps = PWC<{
    onClick?: MouseEventHandler<HTMLElement>;
}>;

export function AvatarList({ onClick }: AvatarListProps & PWC) {
    const { emailShares, isLoading } = useSharingAPI();

    const displayedEmails = emailShares.map((user) => user.email);

    if (!isLoading && emailShares.length === 0) {
        return null;
    }

    return (
        <Box onClick={onClick} sx={{ cursor: 'pointer', width: '100%' }}>
            <AvatarGroup max={5}>
                {isLoading
                    ? [1, 2, 3, 4, 5].map((i) => <AvatarSkeleton key={i} />)
                    : displayedEmails.map((email) => <Avatar key={email} data={email} />)}
            </AvatarGroup>
        </Box>
    );
}
