import { Ability, AbilityBuilder } from '@casl/ability';
import { SharingRole } from '~/adapters/services/collaboration/graphqlTypes';

export const Resource = 'Resource';

export enum ResourceActions {
    Reject = 'reject',
    Edit = 'edit',
    Rerequest = 'rerequest',
    Approve = 'approve',
    Buy = 'buy',
    CommentRead = 'comment-read',
    CommentWrite = 'comment-write',
    SaveACopy = 'save-copy',
}

function freeForAllAbility(abilityBuilder: AbilityBuilder<Ability>) {
    abilityBuilder.can(ResourceActions.Buy, Resource);
}

function abilityForCommenter(abilityBuilder: AbilityBuilder<Ability>) {
    freeForAllAbility(abilityBuilder);
    abilityBuilder.can(
        [ResourceActions.CommentRead, ResourceActions.CommentWrite, ResourceActions.SaveACopy],
        Resource,
    );
}

function abilityForOwner(abilityBuilder: AbilityBuilder<Ability>) {
    abilityForCommenter(abilityBuilder);
    abilityBuilder.can(ResourceActions.Edit, Resource);
    abilityBuilder.cannot(ResourceActions.SaveACopy, Resource);
}

function abilityForEditor(abilityBuilder: AbilityBuilder<Ability>) {
    abilityForCommenter(abilityBuilder);
    abilityBuilder.can(ResourceActions.Edit, Resource);
}

export function abilityForReader(abilityBuilder: AbilityBuilder<Ability>) {
    freeForAllAbility(abilityBuilder);
    abilityBuilder.can([ResourceActions.CommentRead, ResourceActions.SaveACopy], Resource);
}

const RolesToAbilityMapper = {
    [SharingRole.Owner]: abilityForOwner,
    [SharingRole.Editor]: abilityForEditor,
    [SharingRole.Commenter]: abilityForCommenter,
    [SharingRole.Reader]: abilityForReader,
};

export function defineAbilityForRole(role: SharingRole) {
    const abilityBuilder = new AbilityBuilder(Ability);
    RolesToAbilityMapper[role](abilityBuilder);
    return abilityBuilder.build();
}
