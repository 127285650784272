/**
 * Creates SHA-1 hash and converts to hexadecimal string - copy from Profiles - https://github.com/customink/profiles/blob/f8d95d489ddd65b0b1ba0b9c86e58986a76c616a/app/services/account_service.rb#LL8C24-L8C59
 * see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
 * @param value
 * @returns for example it converts string 'this-is-some-random-value+1234' to '88c43ae16dafc1bc075b68e56c76413a54fbc1b4'
 */
export const createHexadecimalHash = async (value: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(value);

    return crypto.subtle.digest('SHA-1', data).then((hash) => {
        const hashArray = Array.from(new Uint8Array(hash));
        return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    });
};
