import { Quote } from '~/adapters/services/accounts/designs/types';

/**
 * Calculate price only if there is exactly one product
 * otherwise it returns undefined
 */
export interface SingleProductPerItemPrice {
    itemCount: number;
    pricePerItem: number;
}

export function calculateSingleProductPerItemPrice(quote: Quote): SingleProductPerItemPrice | undefined {
    if (quote.items.length !== 1 || quote.price === 0) {
        return undefined;
    }
    const product = quote.items[0];

    // can happen that totalPrice > 0 but quantity === 0
    if (product.quantity === 0) {
        return undefined;
    }

    return {
        itemCount: product.quantity,
        pricePerItem: product.unitPrice,
    };
}
