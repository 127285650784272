import { useAuth } from '~/contexts/Auth/AuthContext';
import { Design } from '~/adapters/services/accounts/designs/types';
import { ExternalBuyButton } from './ExternalBuyButton';
import { InternalBuyButton } from './InternalBuyButton';

interface PurchaseButtonProps {
    design: Design;
    cardIndex: number;
    handleCloseMenu: () => void;
    showAddToCartSnackbar: (message: string) => void;
}

export default function BuyButton({ design, cardIndex, handleCloseMenu, showAddToCartSnackbar }: PurchaseButtonProps) {
    const { isImpersonatedExperience } = useAuth();

    if (isImpersonatedExperience) {
        return <InternalBuyButton design={design} />;
    }
    return (
        <ExternalBuyButton
            design={design}
            cardIndex={cardIndex}
            handleCloseMenu={handleCloseMenu}
            showAddToCartSnackbar={showAddToCartSnackbar}
        />
    );
}
