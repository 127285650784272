import { Typography } from '@mui/material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia } from '@customink/pigment-react';
import { useMemo } from 'react';
import { Store } from '~/adapters/services/accounts/stores/types';
import { useLinks } from '~/contexts/LinkContext';
import { LinkInBlank } from '~/components/Link/LinkInBlank';

interface StoreCardProps {
    store: Store;
}

export function StoreCard({ store }: StoreCardProps) {
    const { storeGroupOrdersLink } = useLinks();
    const createdOn = useMemo(
        () =>
            new Date(store.createdAt).toLocaleDateString('en-US', {
                month: 'short',
                year: 'numeric',
                day: 'numeric',
            }),
        [store.createdAt],
    );
    return (
        <Card sx={{ position: 'relative', pb: 0.5 }} data-testid="store card">
            {/* We should have fallback for stores which do not have logos */}
            <CardMedia sx={{ pb: 1.5 }}>
                <Box
                    sx={{
                        height: '275px' /* empirically decided value */,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img src={store.logoImageUrl} style={{ maxWidth: '80%', maxHeight: '80%' }} alt="" />
                </Box>
            </CardMedia>
            <CardHeader title={store.name} />
            <CardContent>
                <Typography variant="uiTextDefault" component={Box}>
                    Created on {createdOn}
                </Typography>
                <Typography variant="uiTextSmall" component={Box}>
                    {store.status}
                </Typography>
            </CardContent>
            <CardActions style={{ gap: 0 }}>
                <Button
                    href={`${storeGroupOrdersLink}/${store.urlSlug}`}
                    sx={{ mb: 1.5 }}
                    variant="secondary"
                    fullWidth
                    LinkComponent={LinkInBlank}
                >
                    Go To Store
                </Button>
                <Button variant="link" fullWidth href={`${storeGroupOrdersLink}/${store.urlSlug}/manage`}>
                    Manage
                </Button>
            </CardActions>
        </Card>
    );
}
