import { useCallback, useEffect, useMemo, useState } from 'react';
import { getFromLocalStorage, saveToLocalStorage } from '~/adapters/browser/localStorage';
import { useAccount } from '~/contexts/UserAccount/UserAccountContext';
import { useStoreGroupOrdersCount } from '~/adapters/services/accounts/stores/hooks';
import { useGroupOrdersCount } from '~/adapters/services/accounts/groupOrders/hooks';
import { useShowDesktopNavigation } from '~/components/Navigation/Navigation';
import { useModalStateContext } from '~/contexts/ModalStateContext';
import { Config } from '~/adapters/config/config';

const tutorialLocalStorageKey = 'accounts-view-order-options-tutorial-visited';
const useWasTutorialUsed = () => useMemo(() => getFromLocalStorage<boolean>(tutorialLocalStorageKey), []);

const useHasGroupOrders = (enabled: boolean) => {
    const { id: accountId } = useAccount();
    const {
        data: storeGOFsCount,
        isPending: storeGOFsPending,
        isError: storeGOFsError,
    } = useStoreGroupOrdersCount({ accountId }, { enabled });
    const {
        data: legacyGOFsCount,
        isPending: legacyGOFsPending,
        isError: legacyGOFsError,
    } = useGroupOrdersCount({ accountId }, { enabled });
    if (storeGOFsPending || storeGOFsError || legacyGOFsPending || legacyGOFsError) {
        // do not show Tutorial until data is loaded. Better have it appear with a delay than have it flicker on and off
        return true;
    }
    return storeGOFsCount + legacyGOFsCount > 0;
};

/**
 * Logic around determining if we should show ViewOrderOptionsTutorial, and handling the tutorial state
 * @param cardIndex index of card in DesignCardList
 */
export const useViewOrderOptionsTutorial = (cardIndex: number) => {
    const isFirstCard = cardIndex === 0;
    const isDesktopNavigation = useShowDesktopNavigation();
    const wasTutorialUsed = useWasTutorialUsed();
    const isTestMode = Config.MODE === 'test';

    // the query is one of the conditions, but because it is expensive, query only when all other conditions are met
    const enableGroupOrderQuery = !isTestMode && isFirstCard && isDesktopNavigation && !wasTutorialUsed;
    const hasGroupOrders = useHasGroupOrders(enableGroupOrderQuery);

    const enableTutorial = enableGroupOrderQuery && !hasGroupOrders;
    const [isTutorialOpen, setIsTutorialOpen] = useState(true);
    const isTutorialShown = enableTutorial && isTutorialOpen;

    const { setIsOrderOptionsMenuTutorialOpen: setTutorialOpenInContext } = useModalStateContext();
    useEffect(() => {
        // This hook is called from each card, but Tutorial is shown only for the first card.
        // That's why we *only* want to set the context true when isTutorialShown *becomes* true.
        if (isTutorialShown) {
            setTutorialOpenInContext(true);
        }
    }, [isTutorialShown]);

    const handleTutorialClose = useCallback(() => {
        setIsTutorialOpen(false);
        setTutorialOpenInContext(false);
        saveToLocalStorage(tutorialLocalStorageKey, true);
    }, []);

    // engaging with tutorial will activate a visual cue, which can then be dismissed
    const [isTutorialActivated, setIsTutorialActivated] = useState(false);
    const handleTutorialClickShowMe = useCallback(() => {
        setIsTutorialActivated(true);
        handleTutorialClose();
    }, [handleTutorialClose]);

    const isTutorialOrderOptionsMenuOpen = isFirstCard && isTutorialActivated;
    const handleTutorialOrderOptionsMenuClose = useCallback(() => setIsTutorialActivated(false), []);

    return {
        isTutorialShown,
        handleTutorialClose,
        handleTutorialClickShowMe,
        isTutorialOrderOptionsMenuOpen,
        handleTutorialOrderOptionsMenuClose,
    };
};
