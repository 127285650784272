import { useEffect, useState } from 'react';
import { useSignalmanFeatureFlag } from '~/contexts/FeatureFlagSignalmanContext';
import { NAVIGATION_BLOCKING_TIMEOUT, trackEvent } from './tracking';
import { AnalyticsConstants } from './AnalyticsConstants';

/**
 * Send GA event for auth0_rollout feature flag and return true/false if tracking is completed, so we can proceed.
 * This is used in Auth providers, so they can block rendering until then.
 * TODO delete this after auth0 rollout https://customink.monday.com/boards/3548701582/pulses/4348417807
 */
export const useTrackIdentityProvider = (authIsLoading: boolean, isAuthenticated: boolean) => {
    const [isTrackingCompleted, setIsTrackingCompleted] = useState(false);
    const completeTracking = () => setIsTrackingCompleted(true);

    const signalmanDecision = useSignalmanFeatureFlag('auth0');

    useEffect(() => {
        if (authIsLoading) {
            return;
        }

        // release the render blocking in case the GA is unsuccessful
        setTimeout(completeTracking, NAVIGATION_BLOCKING_TIMEOUT);

        // track the feature flag
        trackEvent({
            category: 'testing',
            action: `nautilus auth0_rollout`,
            label: `nautilus auth0_rollout ${signalmanDecision ? 'test' : 'off'}`,
            dimension: { [AnalyticsConstants.loginState]: isAuthenticated ? 'authenticated' : 'unauthenticated' },
            hitCallback: () => {
                completeTracking();
            },
        });
    }, [signalmanDecision, authIsLoading, isAuthenticated]);

    return isTrackingCompleted;
};
