import { FormEvent, useCallback, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    PencilIcon,
    TextField,
} from '@customink/pigment-react';
import { useArtRename } from '~/adapters/services/accounts/arts/hooks';
import Spinner from '~/components/Spinner';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import './RenameButton.css';

interface RenameButtonProps {
    name: string | null;
    id: number;
}

interface RenameDialogProps extends RenameButtonProps {
    open: boolean;
    onClose: EmptyFunction;
}

function RenameDialog({ name, id, open, onClose }: RenameDialogProps) {
    const { mutate, isPending } = useArtRename();

    const [inputName, setInputName] = useState(name);

    const saveChanges = useCallback(
        () => mutate({ name: inputName ?? '', uploadId: id }, { onSettled: onClose }),
        [inputName, id, mutate, onClose],
    );
    const onSubmit = useCallback(
        (e: FormEvent) => {
            e.stopPropagation();
            e.preventDefault();
            saveChanges();
        },
        [saveChanges],
    );

    return (
        <Dialog title="Rename Upload" open={open} onClose={onClose}>
            <DialogContent pt={2}>
                <form onSubmit={onSubmit}>
                    <TextField
                        name="name"
                        id="name"
                        label="New upload name"
                        autoFocus
                        value={inputName}
                        fullWidth
                        onChange={(ev) => setInputName(ev.target.value)}
                        required
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button variant="link" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={saveChanges} disabled={!inputName}>
                    {isPending ? <Spinner /> : 'Save Changes'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function RenameButton({ name, id }: RenameButtonProps) {
    const [dialogVisible, setDialogVisible] = useState(false);
    const openDialog = useCallback(() => setDialogVisible(true), []);
    const closeDialog = useCallback(() => setDialogVisible(false), []);

    return (
        <div className="rename-button">
            <IconButton className="rename-button-action" onClick={openDialog} aria-label="Rename the upload">
                <PencilIcon />
            </IconButton>
            <RenameDialog name={name} id={id} open={dialogVisible} onClose={closeDialog} />
        </div>
    );
}
