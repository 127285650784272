import { useScrollLoad } from '~/adapters/browser/useScrollLoad';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import LoadMoreButton from '~/components/LoadMoreButton';
import CardWidthBox from '~/components/Layout/CardWidthBox';
import { Store } from '~/adapters/services/accounts/stores/types';
import { StoreCard } from './StoreCard';
import { StoreEmpty } from './StoreEmpty';

interface ScrollListProps {
    hasNextPage: boolean;
    fetchNextPage: EmptyFunction;
    isFetching: boolean;
    stores: Store[];
}

export function StoreList({ stores, isFetching, fetchNextPage, hasNextPage }: ScrollListProps) {
    const ref = useScrollLoad({
        load: fetchNextPage,
        enabled: !isFetching,
    });
    if (!stores.length) {
        return <StoreEmpty />;
    }

    return (
        <div ref={ref} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1.5rem' }}>
            {stores.map((store) => (
                <CardWidthBox key={store.id}>
                    <StoreCard store={store} />
                </CardWidthBox>
            ))}
            {hasNextPage && <LoadMoreButton onClick={fetchNextPage} loading={isFetching} />}
        </div>
    );
}
