import { forwardRef, useCallback, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AppBar, CircularProgress, Container, Toolbar, useTheme } from '@mui/material';
import {
    Button,
    CartIcon,
    IconButton,
    MoneyHandIcon,
    Stack,
    Typography,
    useMobileBreakpoint,
    XCloseIcon,
} from '@customink/pigment-react';
import { VdlUserPlusIcon } from '~/components/Icon/VdlUserPlusIcon';
import { trackEvent } from '~/adapters/tracking/tracking';
import { gaCategoryOld, trackGA4AddToCart } from '~/pages/SavedDesigns/tracking';
import { AnalyticsConstants } from '~/adapters/tracking/AnalyticsConstants';
import { useAddToCartOrRedirect } from '~/adapters/services/rfe/hooks';
import { Design, PriceQuoteResponse, ProductsDetailsResponse } from '~/adapters/services/accounts/designs/types';
import { designQuoteKey, productDetailsKey } from '~/adapters/services/accounts/designs/hooks';
import { useFundraiserLink, useGroupOrdersLink } from '~/pages/SavedDesigns/links';
import setLocation from '~/adapters/browser/setLocation';
import { checkout } from '~/adapters/services/rfe/links';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';
import { PWC } from '~/adapters/typescript/propsWithChildren';

interface SelectedBottomBarProps {
    selectedCids: Set<string>;
    allDesigns: Design[];
    showSnackbar: (message: string) => void;
    clearSelectedCards: EmptyFunction;
}

export const SelectedBottomBar = forwardRef<HTMLDivElement, PWC<SelectedBottomBarProps>>(
    ({ selectedCids, allDesigns, showSnackbar, clearSelectedCards }, ref) => {
        const theme = useTheme();
        const addToCartEnabled = useFeatureFlagEnabled('add_to_cart_notification_v0');
        const cids = useMemo(() => Array.from(selectedCids), [selectedCids]);
        const [addToCartLoading, setAddToCartLoading] = useState(false);
        const mobile = useMobileBreakpoint();
        const addToCartOrRedirect = useAddToCartOrRedirect();
        const client = useQueryClient();

        const groupOrdersLink = useGroupOrdersLink(cids[0] ?? '');
        const fundraiserLink = useFundraiserLink(cids[0] ?? '');

        const handleAddToCart = useCallback(async () => {
            setAddToCartLoading(true);

            trackEvent({
                category: gaCategoryOld,
                action: 'Button Click',
                label: 'Add to Cart Bulk',
                dimension: { [AnalyticsConstants.buyNowBulkCount]: cids.length },
            });

            // track GA4 add_to_cart event foreach selected design, for that we need the design, quote and product details
            // we can't call hooks in a loop, but we can get react queries from cache, because all of the DesignCards were already rendered (quote & product queried for each cid)
            cids.forEach((cid) => {
                const cardIndex = allDesigns.findIndex((design) => design.compositeId === cid);
                const design = allDesigns[cardIndex];

                const quoteData = client.getQueryData<PriceQuoteResponse | undefined>([designQuoteKey, cid]);
                const productDetailsData = client.getQueryData<ProductsDetailsResponse | undefined>([
                    productDetailsKey,
                    cid,
                ]);

                trackGA4AddToCart(design, cardIndex, quoteData, productDetailsData);
            });

            await addToCartOrRedirect(cids);
            setAddToCartLoading(false);
            const designCount = cids.length;
            showSnackbar(
                designCount > 1
                    ? `${designCount} designs were added to your cart.`
                    : '1 design was added to your cart.',
            );
            // Redirect as usual if the feature flag is disabled
            if (!addToCartEnabled) {
                setLocation(checkout());
            }
            clearSelectedCards();
        }, [cids, addToCartOrRedirect, allDesigns]);

        return (
            <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white' }} ref={ref}>
                <Toolbar
                    sx={{ p: 2 }}
                    // somehow, this doesn't work in sx, because of specificity contest with another MUI class
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                    <Container maxWidth="xl">
                        <Stack
                            direction={{ sm: 'column-reverse', md: 'row' }}
                            alignItems="center"
                            flexWrap="wrap"
                            sx={{ color: 'inherit', gap: { sm: 2, md: 1 } }}
                        >
                            {/* The title shall always be first, no matter the flex-direction */}
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    [theme.breakpoints.down('md')]: {
                                        width: '100%',
                                    },
                                    flexGrow: 1,
                                    order: { sm: 999, md: -1 },
                                    color: 'text.primary',
                                }}
                            >
                                <Typography variant="subHeading" fontWeight="bold">
                                    {selectedCids.size} Selected
                                </Typography>
                                <IconButton
                                    size="medium"
                                    sx={{ display: { sm: 'inherit', md: 'none' } }}
                                    onClick={() => clearSelectedCards()}
                                >
                                    <XCloseIcon fontSize="inherit" color="inherit" />
                                </IconButton>
                            </Stack>
                            {cids.length === 1 && (
                                <>
                                    <Button
                                        size="large"
                                        variant="secondary"
                                        fullWidth={mobile}
                                        startIcon={<MoneyHandIcon />}
                                        href={fundraiserLink}
                                    >
                                        Start a Fundraiser
                                    </Button>
                                    <Button
                                        size="large"
                                        variant="secondary"
                                        fullWidth={mobile}
                                        startIcon={<VdlUserPlusIcon />}
                                        href={groupOrdersLink}
                                    >
                                        Start a Group Order
                                    </Button>
                                </>
                            )}
                            <Button
                                size="large"
                                sx={{ minWidth: 160 }}
                                variant="primary"
                                fullWidth={mobile}
                                startIcon={addToCartLoading ? null : <CartIcon />}
                                disabled={addToCartLoading}
                                onClick={handleAddToCart}
                            >
                                {addToCartLoading ? <CircularProgress color="secondary" size={24} /> : 'Add to Cart'}
                            </Button>
                        </Stack>
                    </Container>
                </Toolbar>
            </AppBar>
        );
    },
);
