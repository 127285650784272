import { getEnv } from '~/adapters/config/env';
import { useApiCall } from '~/adapters/services/shared/apiCall';

export const useRfeApiCall = () => {
    const apiCall = useApiCall(getEnv('ACCOUNTS_RFE_URL'), {
        attachImpersonate: false,
        // it is necessary to pass cookies, especially with add_to_cart, to enable the response Set-Cookie: _customink_frontend_session
        withCredentials: true,
    });

    return apiCall;
};
