import { PropsWithChildren } from 'react';
import { Box, Container } from '@customink/pigment-react';

/**
 * Ensures that the content width & x-spacing is consistent with ci-header, provides default top spacing
 * @param children content
 */
export function EmptyLayout({ children }: PropsWithChildren<unknown>) {
    return (
        <Box mt={2} sx={{ mx: { sm: -1, lg: 0 } }}>
            <Container sx={{ maxWidth: { sm: 'lg', xl: 'xl' } }}>{children}</Container>
        </Box>
    );
}
