import { CertificateStatus, ECMCertificate } from '~/adapters/services/accounts/taxExemptionsECM/types';
import { useTheme, Chip } from '@mui/material';
import { Button, Grid, Stack, Tooltip, Typography, Card, CardContent, CardActions } from '@customink/pigment-react';
import { Circle } from '@mui/icons-material';
import { useStatusAdornments } from '~/pages/Settings/tabs/TaxExemptionECM/Certificate/statusTranslate';
import { usStatesAndTerritoriesByCode } from '~/adapters/geography/usStatesAndTerritories';
import { formatDate } from '~/adapters/date/date';
import capitalize from 'lodash.capitalize';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';

interface CertificateManagementProps {
    certificates: Array<ECMCertificate>;
    certStatusFilter: CertificateStatus | 'all';
}

function getExpirationDate(certificate: ECMCertificate) {
    if (certificate.cinkStatus === 'pending' || !certificate.expirationDate) {
        return '';
    }
    if (certificate.expirationDate.getFullYear() === 9999) {
        return 'Does Not Expire';
    }
    return `Expire${certificate.expirationDate < new Date() ? 'd' : 's'} on ${formatDate(certificate.expirationDate)}`;
}

function CertificateWithPreview({ certificate }: { certificate: ECMCertificate }) {
    const statusAdornment = useStatusAdornments(certificate);
    return (
        <Grid item sm={12} md={12} lg={6} container>
            <Card size="small" sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                <a
                    target="_blank"
                    href={certificate.attachmentUrl}
                    style={{
                        minWidth: '150px',
                        maxWidth: '150px',
                        height: '213px',
                        transform: 'translate3d(0px, 0px, 0px)',
                        overflow: 'hidden',
                        marginLeft: '16px',
                        marginBottom: '16px',
                        marginTop: '16px',
                        borderRadius: '4px',
                        border: '1px solid rgb(224, 224, 224)',
                    }}
                    rel="noreferrer"
                >
                    <img
                        src={certificate.thumbnailUrl}
                        alt="certificate"
                        style={{
                            overflowClipMargin: 'content-box',
                            overflow: 'clip',
                            objectFit: 'contain',
                            width: 'inherit',
                            aspectRatio: 'unset',
                            background: 'rgb(224, 224, 224)',
                            height: '100%',
                            cursor: 'pointer',
                            transition: 'height 0.5s',
                            textAlign: 'center',
                            color: 'rgb(158, 158, 158)',
                        }}
                    />
                </a>
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <CardContent sx={{ mt: 0, pt: 2.5, flexGrow: 1 }}>
                        <Stack direction="column" spacing={1.5} sx={{ py: 0, mb: 2 }}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ width: '100%' }}
                            >
                                <Typography
                                    variant="h5"
                                    component="h4"
                                    fontWeight={700}
                                    sx={{ pr: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden' }}
                                >
                                    {usStatesAndTerritoriesByCode[certificate.exposureZoneRegion] ??
                                        certificate.exposureZoneRegion}
                                </Typography>
                                <Tooltip placement="bottom" title={statusAdornment.tooltipMessage ?? ''}>
                                    <Chip
                                        component="span"
                                        icon={<Circle width="50px" style={{ color: statusAdornment.color }} />}
                                        label={capitalize(certificate.cinkStatus)}
                                    />
                                </Tooltip>
                            </Stack>
                        </Stack>
                        {/* TODO @teichmaa update all these Typographies once we have the new tokens https://customink.monday.com/boards/5878886849/views/128588597/pulses/7179095308 */}
                        {certificate.expirationDate && (
                            <Typography paragraph fontWeight={500} variant="uiTextDefault">
                                {getExpirationDate(certificate)}
                            </Typography>
                        )}
                        <Typography
                            variant="uiTextSmall"
                            mb={0}
                            paragraph
                            textTransform="capitalize"
                            color="text.secondary"
                        >
                            {certificate.exemptionReason?.toLowerCase() || 'General Use'}
                        </Typography>
                    </CardContent>
                    <CardActions style={{ flexDirection: 'row' }}>
                        <Button
                            target="_blank"
                            disabled={!certificate.attachmentUrl}
                            href={certificate.attachmentUrl}
                            variant="secondary"
                            size="small"
                        >
                            Download
                        </Button>
                    </CardActions>
                </div>
            </Card>
        </Grid>
    );
}

function Certificate({ certificate }: { certificate: ECMCertificate }) {
    const theme = useTheme();
    const statusAdornment = useStatusAdornments(certificate);
    return (
        <Grid item sm={12} md={6} lg={4} container data-testid="certificate">
            <Card size="small" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <CardContent sx={{ mt: 0, pt: 2.5, flexGrow: 1 }}>
                    <Stack direction="column" spacing={1.5} sx={{ py: 0, mb: 2 }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ width: '100%' }}
                        >
                            <Typography
                                variant="h5"
                                component="h4"
                                fontWeight={700}
                                sx={{ pr: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden' }}
                            >
                                {usStatesAndTerritoriesByCode[certificate.exposureZoneRegion] ??
                                    certificate.exposureZoneRegion}
                            </Typography>
                            <Tooltip placement="bottom" title={statusAdornment.tooltipMessage ?? ''}>
                                <Chip
                                    component="span"
                                    icon={<Circle width="50px" style={{ color: statusAdornment.color }} />}
                                    label={capitalize(certificate.cinkStatus)}
                                />
                            </Tooltip>
                        </Stack>
                    </Stack>
                    {/* TODO @teichmaa update all these Typographies once we have the new tokens https://customink.monday.com/boards/5878886849/views/128588597/pulses/7179095308 */}
                    {certificate.expirationDate && (
                        <Typography paragraph fontWeight={500} variant="uiTextDefault">
                            {getExpirationDate(certificate)}
                        </Typography>
                    )}
                    <Typography
                        variant="uiTextSmall"
                        mb={0}
                        paragraph
                        textTransform="capitalize"
                        style={{ color: theme.palette.text.secondary }}
                    >
                        {certificate.exemptionReason?.toLowerCase() || 'General Use'}
                    </Typography>
                </CardContent>
                <CardActions style={{ flexDirection: 'row' }}>
                    <Button
                        target="_blank"
                        disabled={!certificate.attachmentUrl}
                        href={certificate.attachmentUrl}
                        variant="secondary"
                        size="small"
                    >
                        Download
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
}

export function CertificateManagement({ certificates, certStatusFilter }: CertificateManagementProps) {
    const isCertThumbnailEnabled = useFeatureFlagEnabled('tax_exemption_cert_thumbnail');
    const CertificateComponent = isCertThumbnailEnabled ? CertificateWithPreview : Certificate;
    return (
        <Grid container spacing={2}>
            {certificates.length === 0 && (
                <Grid item container alignItems="center" justifyContent="center">
                    <Typography component="p" variant="uiTextDefault">
                        You have no {certStatusFilter} certificates.
                    </Typography>
                </Grid>
            )}
            {certificates.map((certificate) => (
                <CertificateComponent key={certificate.id} certificate={certificate} />
            ))}
        </Grid>
    );
}
