import { UseFormRegister } from 'react-hook-form';
import { useCallback } from 'react';

/**
 * Returns a function that acts identically to `register` but returns an object with `inputRef` instead of `ref`. <br>
 * Memoized with `useCallback`.
 * @param register react-hook-form `register` function
 */
export function useRegisterInput<T extends Record<string, any>>(register: UseFormRegister<T>) {
    return useCallback(
        (name: Parameters<typeof register>[0], options: Parameters<typeof register>[1]) => {
            const { ref, ...others } = register(name, options);
            return {
                inputRef: ref,
                ...others,
            };
        },
        [register],
    );
}
