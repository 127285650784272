import { Grid, useMobileBreakpoint } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import LoadMoreButton from '~/components/LoadMoreButton';
import { useScrollLoad } from '~/adapters/browser/useScrollLoad';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { DesignCard } from '~/pages/SavedDesigns/DesignCard';
import { Design } from '~/adapters/services/accounts/designs/types';
import { EmptyDesignList } from './EmptyDesignList';

interface DesignCardListProps {
    designLabLink: string;
    designs: Design[];
    onLoadMore: EmptyFunction;
    loading: boolean;
    hasMorePages?: boolean;
    selectedCards: Set<string>;
    setSelectedForOneCard: (cardId: string, selected: boolean) => void;
    showAddToCartSnackbar: (designName: string) => void;
    showMobile2columns: boolean;
}

export function DesignCardList({
    designs,
    onLoadMore,
    loading,
    hasMorePages,
    designLabLink,
    selectedCards,
    setSelectedForOneCard,
    showAddToCartSnackbar,
    showMobile2columns,
}: PWC<DesignCardListProps>) {
    const smGridSize = showMobile2columns ? 6 : 12;

    const isMobile = useMobileBreakpoint();

    const ref = useScrollLoad({
        load: onLoadMore,
        enabled: !loading,
    });

    return (
        <>
            <Grid container ref={ref} spacing={isMobile ? 1 : 3}>
                {designs.map((card, index) => (
                    // display: 'grid' enables flexible height of the card, display: 'flex' would achieve the same, but it breaks keen-slider https://files.slack.com/files-pri/T02M3Q53Z-F04JQ37G093/image.png
                    <Grid sx={{ display: 'grid' }} item sm={smGridSize} md={6} lg={4} key={card.compositeId}>
                        <DesignCard
                            design={card}
                            selected={selectedCards.has(card.compositeId)}
                            setSelected={(selected) => setSelectedForOneCard(card.compositeId, selected)}
                            index={index}
                            isMobile2ColumnsView={showMobile2columns}
                            showAddToCartSnackbar={showAddToCartSnackbar}
                        />
                    </Grid>
                ))}
            </Grid>
            {designs.length === 0 && <EmptyDesignList designLabLink={designLabLink} />}
            {hasMorePages && <LoadMoreButton onClick={onLoadMore} loading={loading} />}
        </>
    );
}
