import { RefreshIcon } from '@customink/pigment-react';
import * as React from 'react';
import styles from './index.module.css';

type SpinnerProps = {
    /**
     * Size in px, passed down as width and height of the svg.
     */
    size?: number;
};
const Spinner: React.FC<SpinnerProps> = ({ size = undefined }) => (
    <RefreshIcon style={{ width: size, height: size }} className={styles.animationSpin} />
);

export default Spinner;
