import { useMemo } from 'react';
import { PageHeader } from '~/components/PageHeader/PageHeader';
import { usePage } from '~/adapters/browser/usePage';
import { SectionContent } from '~/components/Layout/SectionContent';
import { Loader } from '~/components/Loader/loader';
import { SharedWithMeQuery } from '~/adapters/services/collaboration/graphqlTypes';
import { useInfiniteSharedWithMeQuery } from '~/adapters/services/collaboration/collaborationHooks';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { SharedDesignCardList } from './SharedDesignCardList';

const pageToNodes = (page: SharedWithMeQuery) => page.resources.nodes ?? [];

export function SharedDesigns() {
    usePage({ title: 'Shared With You', reportPath: '/accounts/shared_designs' });

    const { data, isLoading, fetchNextPage, isFetching, hasNextPage } = useInfiniteSharedWithMeQuery();
    const dataItems = useMemo(() => {
        return data?.pages.map(pageToNodes).flat() ?? [];
    }, [data]);

    // todo remake as skeletons?
    if (isLoading) {
        return <Loader title="Loading..." devLabel="collaboration API" />;
    }

    return (
        <>
            <PageHeader>Shared With You</PageHeader>
            <ErrorBoundary errorMessage="An error occurred while retrieving your shared designs">
                <SectionContent>
                    <SharedDesignCardList
                        cards={dataItems}
                        loading={isFetching}
                        onLoadMore={fetchNextPage}
                        hasMorePages={hasNextPage}
                    />
                </SectionContent>
            </ErrorBoundary>
        </>
    );
}
