import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { parseFeatureFlags } from '~/adapters/browser/getCookie';
import { trackABTest } from '~/adapters/tracking/tracking';

export const featureFlagsVersions = {
    // we are working with keys in accounts-ui, value is what is in signalman
    // turns out it is necessary to specify the version.. Otherwise it can read old cookies, making it behave unpredictably
    collaboration: 'collaboration_v5',
    auth0: 'auth0_rollout',
    inker_ux_toggle: 'inker_ux_toggle',
};

type FeatureFlagName = keyof typeof featureFlagsVersions;

const flagsTrackedInGA: FeatureFlagName[] = [];

export type FeatureFlags = {
    [K in FeatureFlagName]?: string;
};

export const FeatureFlagSignalmanContext = createContext<FeatureFlags | null>(null);

const isFeatureFlagEnabled = (featureFlagValue?: string): boolean => featureFlagValue === 'test';

export function useSignalmanFeatureFlag(featureFlagName: FeatureFlagName): boolean {
    const featureFlagValues = useContext(FeatureFlagSignalmanContext);
    if (!featureFlagValues) {
        throw new Error('FeatureFlagSignalmanProvider context not provided');
    }
    return isFeatureFlagEnabled(featureFlagValues[featureFlagName]);
}

export function FeatureFlagSignalmanProvider({ children }: PropsWithChildren<unknown>) {
    const featureFlagValues = useMemo<FeatureFlags>(() => parseFeatureFlags(featureFlagsVersions), []);

    useEffect(() => {
        flagsTrackedInGA.forEach((flagName) => {
            const value = featureFlagValues[flagName];
            if (value) {
                trackABTest(flagName, value);
            }
        });
    }, [featureFlagValues]);

    return (
        <FeatureFlagSignalmanContext.Provider value={featureFlagValues}>
            {children}
        </FeatureFlagSignalmanContext.Provider>
    );
}
