import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { SectionContent } from '~/components/Layout/SectionContent';

export function CardListLoader() {
    const theme = useTheme();
    const matchesSmallBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
    const matchesMediumBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));

    const cardCount = useMemo(() => {
        if (matchesSmallBreakpoint) {
            return 1;
        }

        if (matchesMediumBreakpoint) {
            return 2;
        }

        return 3;
    }, [matchesSmallBreakpoint, matchesMediumBreakpoint]);

    return (
        <SectionContent>
            <Grid container spacing={3}>
                {Array.from({ length: cardCount }, (_, i) => i + 1).map((index) => (
                    <Grid sx={{ display: 'grid' }} item sm={12} md={6} lg={4} key={index}>
                        <Skeleton variant="rounded" width="100%" height="425px" style={{ transform: 'none' }} />
                    </Grid>
                ))}
            </Grid>
        </SectionContent>
    );
}
