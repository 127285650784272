import React from 'react';
import { Stack, Tooltip, Typography } from '@customink/pigment-react';
import { Badge } from '@mui/icons-material';
import { useAuthUser } from '~/contexts/Auth/AuthContext';
import { useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { useMediaQuery, useTheme } from '@mui/material';
import { Impersonate } from '../Impersonate/Impersonate';

const WelcomeBackUser: React.FC = () => {
    const { emailDerivedName, isInternal } = useAuthUser();
    const { user: userData } = useUserAccount();

    const welcomeName = userData.firstName || emailDerivedName;

    return (
        <Typography variant="heading3" sx={{ fontWeight: 400 }} id="accounts-greeting">
            Welcome back, {welcomeName}!
            {isInternal && (
                <Typography variant="subHeading" sx={{ pl: 1, verticalAlign: 'bottom' }}>
                    <Tooltip placement="top" title="Internal User">
                        <Badge color="secondary" />
                    </Tooltip>
                </Typography>
            )}
        </Typography>
    );
};

const WelcomeTopMenu: React.FC = () => {
    const theme = useTheme();
    const showDesktopMenu = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <Stack gap={3} sx={{ pl: { lg: 2 } }}>
            {showDesktopMenu && <WelcomeBackUser />}
            <Impersonate />
        </Stack>
    );
};

export default WelcomeTopMenu;
