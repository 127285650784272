import { useEffect, useState } from 'react';
import { Button, CheckIcon, LinkIcon } from '@customink/pigment-react';
import Spinner from '~/components/Spinner';
import { sendSharingDialogEvent } from '../sendSharingDialogEvent';

interface Props {
    copyLink: () => Promise<boolean>;
    disabled?: boolean;
}

// when createResource is called, we need to disable the button (and display spinner as an icon) to prevent further createResource calls
// the user could otherwise repeatedly click the button until the server response arrives, creating multiple invalid calls
const ButtonIcon = ({ disabled, linkCopied }: { disabled: boolean; linkCopied: boolean }) => {
    if (disabled) {
        return <Spinner />;
    }
    return linkCopied ? <CheckIcon /> : <LinkIcon />;
};

export const CopyLinkButton = ({ copyLink, disabled = false }: Props) => {
    const [linkCopied, setLinkCopied] = useState(false);

    useEffect(() => {
        if (linkCopied) {
            setTimeout(() => {
                setLinkCopied(false);
            }, 1000);
        }
    }, [linkCopied]);

    const onClickHandler = async () => {
        sendSharingDialogEvent('Button Click', 'Copy Link (link sharing)');
        const isCopied = await copyLink();
        setLinkCopied(isCopied);
    };

    return (
        <Button
            variant="secondary"
            startIcon={<ButtonIcon disabled={disabled} linkCopied={linkCopied} />}
            onClick={onClickHandler}
            disabled={disabled}
        >
            {linkCopied ? 'Link Copied' : 'Copy link'}
        </Button>
    );
};
