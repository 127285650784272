import { FC } from 'react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import {
    Button,
    IconButton,
    LightBulbIcon,
    Stack,
    TutorialBase,
    Typography,
    XCloseIcon,
} from '@customink/pigment-react';
import { TutorialBaseProps } from '@customink/pigment-react/lib/components/TutorialBase/TutorialBase';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { navigationWidth } from '~/components/Navigation/styles';

const PaddedList = styled('ul')(({ theme }) => ({
    paddingLeft: theme.spacing(2),
}));

type ViewOrderOptionsTutorialProps = Pick<TutorialBaseProps, 'open' | 'anchorElement'> & {
    handleClose: EmptyFunction;
    handleClickShowMe: EmptyFunction;
};

export const ViewOrderOptionsTutorial: FC<ViewOrderOptionsTutorialProps> = ({
    open,
    anchorElement,
    handleClose,
    handleClickShowMe,
}) => (
    <TutorialBase open={open} anchorElement={anchorElement} showBackDrop={false} placement="left-start" sx={{ p: 1 }}>
        <div style={{ width: navigationWidth }}>
            <Stack direction="row" justifyContent="space-between">
                <LightBulbIcon />
                <IconButton onClick={handleClose}>
                    <XCloseIcon fontSize="inherit" />
                </IconButton>
            </Stack>
            <Typography variant="uiTextDefault" style={{ fontWeight: 700 }}>
                Did you know...
            </Typography>
            <PaddedList>
                <li>You can add your designs to the cart for immediate shipment to one or multiple addresses.</li>
                <li>Start a group order to easily collect sizes and payments from your group.</li>
                <li>Raise money and awareness through fundraising with your designs.</li>
            </PaddedList>
            <Stack direction="row" justifyContent="flex-end" gap={1}>
                <Button variant="link" onClick={handleClose} size="small">
                    Got It
                </Button>
                <Button variant="primary" onClick={handleClickShowMe} size="small">
                    Show Me
                </Button>
            </Stack>
        </div>
    </TutorialBase>
);
