export const GA_USER_ID = '_profiles_user_id';

export function saveUserId(userId: string) {
    localStorage.setItem(GA_USER_ID, userId);
}

export function removeUserId() {
    localStorage.removeItem(GA_USER_ID);
}

export function getUserId() {
    return localStorage.getItem(GA_USER_ID);
}
