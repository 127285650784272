import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack } from '@customink/pigment-react';
import { CircularProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import { DesignsPath } from '~/config';
import setLocation from '~/adapters/browser/setLocation';
import { Can } from '~/contexts/ability/collaboration/CollaborationAbilityContext';
import { useAuthenticatedCta } from '~/components/hooks/useAuthenticatedCta';
import { useLinks } from '~/contexts/LinkContext';
import { pushApiErrorNotification, pushSuccessNotification } from '~/adapters/notistack/notistack';
import { usePreservedQueryParamLink } from '~/adapters/browser/usePreservedQueryParamLink';
import { useAddToCartOrRedirect } from '~/adapters/services/rfe/hooks';
import { SharingRole } from '~/adapters/services/collaboration/graphqlTypes';
import { ResourceActions } from '~/contexts/ability/collaboration/collaborationAbility';
import { useCloneResourceForMeMutation } from '~/adapters/services/collaboration/collaborationHooks';
import { checkout } from '~/adapters/services/rfe/links';
import { sendFeedbackPageEvent } from './sendFeedbackPageEvent';

interface FeedbackActionProps {
    cid: string;
    resourceId: string;
    myRole: SharingRole;
}

export function FeedbackAction({ cid, resourceId, myRole }: FeedbackActionProps) {
    const { designLabLink } = useLinks();
    const navigate = useNavigate();
    const addToCartOrRedirect = useAddToCartOrRedirect();
    const designPathLink = usePreservedQueryParamLink(DesignsPath);
    const { mutateAsync: cloneDesignAsync } = useCloneResourceForMeMutation();
    const [cloneWaiting, setCloneWaiting] = useState(false);
    const [buyWaiting, setBuyWaiting] = useState(false);

    const handleCloneClick = useAuthenticatedCta(
        useCallback(async () => {
            setCloneWaiting(true);
            sendFeedbackPageEvent('Button Click', 'Save A Copy');
            try {
                await cloneDesignAsync({ input: { resourceId } });
                navigate(designPathLink);
                pushSuccessNotification('Design has been saved to your account.');
            } catch (error) {
                pushApiErrorNotification('Something went wrong.');
            }
            setCloneWaiting(false);
        }, [cloneDesignAsync, resourceId, designPathLink]),
    );

    const handleEditClick = useAuthenticatedCta(
        useCallback(() => {
            sendFeedbackPageEvent('Link Click', 'Edit Design');
            setLocation(`${designLabLink}?cid=${cid}`);
        }, [cid, designLabLink]),
    );

    const handleAddToCartClick = useAuthenticatedCta(
        useCallback(async () => {
            setBuyWaiting(true);
            const isOwner = myRole === SharingRole.Owner;
            sendFeedbackPageEvent('Link Click', 'Add to Cart');
            if (isOwner) {
                await addToCartOrRedirect([cid]);
                setLocation(checkout());
                return;
            }
            try {
                const response = await cloneDesignAsync({ input: { resourceId } });
                const newCid = response.cloneResourceForMe.compositeId;
                await addToCartOrRedirect([newCid || cid]);
            } catch (error) {
                Sentry.captureException(`Cloning design before adding to cart failed for ${cid}: ${error}`);
                await addToCartOrRedirect([cid]);
            }
            setLocation(checkout());
        }, [addToCartOrRedirect, cloneDesignAsync, cid, resourceId, myRole]),
    );

    return (
        <Stack spacing={2} direction="row">
            <Can I={ResourceActions.Edit}>
                <Button variant="secondary" onClick={handleEditClick}>
                    Edit Design
                </Button>
            </Can>
            <Can I={ResourceActions.SaveACopy}>
                <Button variant="secondary" onClick={handleCloneClick} disabled={cloneWaiting}>
                    {cloneWaiting ? <CircularProgress color="secondary" size={24} /> : 'Save a Copy'}
                </Button>
            </Can>
            <Can I={ResourceActions.Buy}>
                <Button variant="primary" onClick={handleAddToCartClick} disabled={buyWaiting}>
                    {buyWaiting ? <CircularProgress color="secondary" size={24} /> : 'Add to Cart'}
                </Button>
            </Can>
        </Stack>
    );
}
