import { TableCell, TableRow } from '@mui/material';
import { SignalerFeatureFlagName } from '~/adapters/signaler/signaler';
import { PossibleValues } from './PossibleValues';
import { CurrentFlagValue } from './CurrentFlagValue';

interface FeatureProps {
    featureName: SignalerFeatureFlagName;
    possibleValues: string[];
}

export function FeatureRow({ featureName, possibleValues }: FeatureProps) {
    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {featureName}
            </TableCell>
            <TableCell>
                <PossibleValues possibleValues={possibleValues} featureName={featureName} />
            </TableCell>
            <TableCell>
                <CurrentFlagValue featureName={featureName} />
            </TableCell>
        </TableRow>
    );
}
