import { Divider } from '@customink/pigment-react';
import { OrderDetails } from '~/adapters/services/accounts/orders/types';
import { formatDate } from '~/adapters/date/date';
import { InfoText } from '../../../components/InfoText';
import { orderLinkTrackEvent } from '../../../tracking';

export function OrderShippingInfo({ orderDetails }: { orderDetails: OrderDetails }) {
    return (
        <>
            <Divider />
            <InfoText>
                Delivered {orderDetails.schedule.deliveredAt && formatDate(orderDetails.schedule.deliveredAt)}
                {orderDetails.shippingDetail.shipper ? `, ${orderDetails.shippingDetail.shipper}` : null}, Tracking
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={orderDetails.shippingDetail.trackingUrl}
                    onClick={(event) => orderLinkTrackEvent(event, 'tracking link')}
                >
                    #{orderDetails.shippingDetail.trackingNumber}
                </a>
            </InfoText>
        </>
    );
}
