import { Box, Button, Chats1Icon, Stack, useMobileBreakpoint } from '@customink/pigment-react';
import { useCallback } from 'react';
import { addDataLayerEvent } from '~/adapters/tracking/tracking';
import { useOpenLivePerson } from '~/adapters/designSystem/livePerson';
import { useMediaQuery, useTheme } from '@mui/material';

const taxExemptionsServiceNumber = '(855) 256-0225';

export function ChatWithUs() {
    const theme = useTheme();
    const isMdOrSmaller = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMobileBreakpoint();

    const { openLivePersonOrContactPage } = useOpenLivePerson();

    const handleClickHelp = useCallback(() => {
        openLivePersonOrContactPage();
        addDataLayerEvent({
            event: 'click contact',
            click_section: 'tax exempt modal',
            click_link: 'chat',
        });
    }, []);

    const trackContactClick = useCallback(() => {
        addDataLayerEvent({
            event: 'click contact',
            click_section: 'tax exempt modal',
            click_link: '855-256-0225',
        });
    }, []);

    if (isMdOrSmaller) {
        return (
            <Button
                onClick={handleClickHelp}
                startIcon={<Chats1Icon />}
                variant="link"
                fullWidth={isMobile}
                sx={{ ml: { md: 1 } }}
            >
                Chat with a Real Person
            </Button>
        );
    }

    return (
        <Stack
            sx={{ pl: 1 }}
            gap={1}
            direction="row"
            style={{ '--typography-button-medium-size': '16px', '--typography-button-small-size': '14px' }}
        >
            <Box sx={{ p: 1, pb: 0 }} onClick={trackContactClick}>
                <customer-contact service-number={taxExemptionsServiceNumber} />
            </Box>
            <Box onClick={handleClickHelp} sx={{ cursor: 'pointer', p: 1, pb: 0 }}>
                {/* This part might be very confusing. It's a simple yet very hacky way to work with LivePerson. */}
                {/* We use the web component from ci-header to render the dynamic content,  */}
                {/* but we use our own click handler to trigger the chat, to allow for using accounts-specific chat id. */}
                {/* Providing `id` to the web component isn't as easy as it seems, because LivePerson init script is rendered on page load, */}
                {/* and doesn't work with elements dynamically added to the DOM. */}
                <customer-chat />
            </Box>
        </Stack>
    );
}
