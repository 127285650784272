export function getCookie(name: string, cookie = document.cookie) {
    const extName = `${name}=`;
    const decodedCookie = decodeURIComponent(cookie);
    const cookieParts = decodedCookie.split(';');
    const foundCookiePart = cookieParts.find((part) => part.trim().startsWith(extName)) ?? '';
    const [, cookieValue = ''] = foundCookiePart.split('=');
    return cookieValue;
}

type EnabledFeatureFlags<T> = {
    [K in keyof T]?: string;
};

export function parseFeatureFlags<T extends Record<string, string>>(
    featureFlagShape: T,
    cookie = document.cookie,
): EnabledFeatureFlags<T> {
    const cookieRows = cookie.split(';').map((val) => val.trim());
    return Object.entries(featureFlagShape).reduce((acc, [key, value]) => {
        const cookieRow = cookieRows.find((row) => row.startsWith(`feature_${value}=`));
        if (!cookieRow) {
            return acc;
        }
        const [, cookieValue] = cookieRow.split('=');
        return { ...acc, [key]: cookieValue };
    }, {} as EnabledFeatureFlags<T>);
}
