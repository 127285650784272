import { Typography } from '@customink/pigment-react';
import { signaler, SignalerFeatureFlagName } from '~/adapters/signaler/signaler';

interface CurrentFlagValueProps {
    featureName: SignalerFeatureFlagName;
}

export function CurrentFlagValue({ featureName }: CurrentFlagValueProps) {
    const flagValue = signaler.featureFlag(featureName);

    return <Typography variant="uiTextSmall">{flagValue}</Typography>;
}
