import { FC, useCallback, useState } from 'react';
import { Alert, Stack } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { useOptionalAuthUser } from '~/contexts/Auth/AuthContext';
import { useGetAccount, usePersonalAccount } from '~/adapters/services/accounts/accounts/hooks';
import { useUserData } from '~/adapters/services/accounts/users/hooks';
import { FullLayoutLoader } from '~/components/Layout/FullLayoutLoader';
import Cookies from 'js-cookie';
import { useSearchParams } from 'react-router-dom';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';
import { UserAccountContext } from './UserAccountContext';

const SELECTED_ACCOUNT_ID_COOKIE_KEY = 'selectedAccountId';
const DOMAIN = import.meta.env.MODE === 'development' ? '.localhost' : '.customink.com';

export const UserAccountProvider: FC<PWC> = ({ children }) => {
    const isTeamAccountsFeatureEnabled = useFeatureFlagEnabled('team_accounts_v0');
    const [searchParams, setSearchParams] = useSearchParams();
    const overriddenAccountIdInQuery = Number.parseInt(searchParams.get('accountId') ?? 'NaN');

    const [currentTeamAccountId, setCurrentTeamAccountId] = useState(() => {
        if (!isTeamAccountsFeatureEnabled) {
            return undefined;
        }

        if (overriddenAccountIdInQuery) {
            return overriddenAccountIdInQuery;
        }

        const currentTeamAccountIdFromCookieAsString = Cookies.get(SELECTED_ACCOUNT_ID_COOKIE_KEY);
        if (!currentTeamAccountIdFromCookieAsString) {
            return undefined;
        }

        const parsed = Number.parseInt(currentTeamAccountIdFromCookieAsString);
        return Number.isNaN(parsed) ? undefined : parsed;
    });

    const persistCurrentAccountId = useCallback(
        (accountId: number | 'personal') => {
            if (accountId === 'personal') {
                setCurrentTeamAccountId(undefined);
                Cookies.remove(SELECTED_ACCOUNT_ID_COOKIE_KEY, {
                    domain: DOMAIN,
                });
                setSearchParams((params) => {
                    params.delete('accountId');
                    return params;
                });
                return;
            }

            setCurrentTeamAccountId(accountId);
            Cookies.set(SELECTED_ACCOUNT_ID_COOKIE_KEY, accountId.toString(), {
                domain: DOMAIN,
                expires: 1095, // 3 years
            });
            setSearchParams((params) => {
                params.set('accountId', accountId.toString());
                return params;
            });
        },
        [setCurrentTeamAccountId, setSearchParams],
    );

    const isUsingPersistedAccount = Boolean(currentTeamAccountId);

    const { data: personalAccount, isLoading: isPersonalAccountLoading } = usePersonalAccount({
        enabled: !isUsingPersistedAccount,
    });
    const { data: queriedAccount, isLoading: isQueriedAccountLoading } = useGetAccount(currentTeamAccountId, {
        enabled: isUsingPersistedAccount,
    });
    const { data: user, isLoading: isUserLoading } = useUserData();
    const authUser = useOptionalAuthUser();

    // TODO Tonda split this condition and move out of this Provider https://customink.monday.com/boards/5878886849/views/128588597/pulses/6069847021
    if (isPersonalAccountLoading || isUserLoading || isQueriedAccountLoading) {
        return <FullLayoutLoader devLabel="User Account Provider" />;
    }

    // ?? serves to unify null & undefined, to clean up context interface, while react-query necessarily returns null in some cases, see usePersonalAccount
    const account = (isUsingPersistedAccount ? queriedAccount : personalAccount) ?? undefined;
    if (authUser && authUser.impersonated && (!account || !user)) {
        return (
            <Stack direction="row" justifyContent="center">
                <Alert severity="error">
                    Impersonation error
                    <br />
                    No data found for {authUser.email}
                    {isTeamAccountsFeatureEnabled && currentTeamAccountId
                        ? ` for account id ${currentTeamAccountId}`
                        : ''}
                </Alert>
            </Stack>
        );
    }

    return (
        <UserAccountContext.Provider value={{ account, user, persistCurrentAccountId }}>
            {children}
        </UserAccountContext.Provider>
    );
};
