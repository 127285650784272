import { FC } from 'react';
import { Container } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

export const AppWideErrorLayout: FC<PWC> = ({ children }) => (
    <Container
        maxWidth="lg"
        sx={{
            p: {
                xs: 3, // defining xs for compatibility when rendering error outside Pigment
                sm: 3,
                md: 6,
                xl: 8,
            },
        }}
    >
        {children}
    </Container>
);
