import * as Sentry from '@sentry/react';
import { rollbar } from './rollbar.config';

interface SentryOptions {
    extra?: Sentry.Event['extra'];
    tags?: Sentry.Event['tags'];
}

export function sendErrorToRollbar(event: Sentry.Event, hint: Sentry.EventHint): void {
    const orgExp = hint.originalException;
    const { extra, tags } = event;
    const options: SentryOptions = {
        extra,
        tags,
    };

    rollbar.error(orgExp, options);
}
