import { User } from '~/adapters/services/accounts/users/types';
import { useMenu } from '~/components/Menu/useMenu';
import { useGetAccountUsers, useRemoveAccountUser } from '~/adapters/services/accounts/accounts/hooks';
import { useAccount, useUserAccount } from '~/contexts/UserAccount/UserAccountContext';
import { useCallback, useMemo } from 'react';
import {
    Menu,
    MenuItem,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Avatar, Chip, IconButton, MenuDotsHorizontalIcon } from '@customink/pigment-react';
import { ConfirmDeleteDialog } from '~/components/ConfirmDeleteDialog';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import { LeaveTeamExperience } from '~/pages/Settings/tabs/AccountDetails/TeamManagement';

const displayName = (user: User) => user.firstName ?? user.email.split('@')[0];

function MemberRow({ user }: { user: User }) {
    const menu = useMenu();

    const { mutateAsync: removeUserFromAccount } = useRemoveAccountUser();
    const { account, user: currentUser } = useUserAccount();

    const handleRemoveUser = useCallback(async () => {
        return removeUserFromAccount({ accountId: account.id, userId: user.id });
    }, [removeUserFromAccount, account.id, user.id]);

    return (
        <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                <Avatar data={user.email} size="large" sx={{ display: 'inline flex', mr: 1 }} />
                {user.firstName} {user.lastName}
            </TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>Member</TableCell>
            <TableCell>
                <Chip label="Joined" />
            </TableCell>
            <TableCell>
                <IconButton aria-label="More actions" onClick={menu.handleOpen}>
                    <MenuDotsHorizontalIcon />
                </IconButton>
            </TableCell>
            <Menu
                id="account-user-menu"
                anchorEl={menu.anchorEl}
                open={menu.open}
                onClose={menu.handleClose}
                MenuListProps={{
                    'aria-labelledby': 'more-menu',
                }}
            >
                {currentUser.id !== user.id ? (
                    <ConfirmDeleteDialog
                        onDeleteConfirmed={handleRemoveUser}
                        alertText={null}
                        additionalText={
                            <>
                                <em>{displayName(user)}</em> will be removed from {account.name}.<br />
                                You can invite <em>{displayName(user)}</em> back any time later.
                            </>
                        }
                        confirmButtonLabel="Remove"
                    >
                        {(openConfirmDialog) => <MenuItem onClick={openConfirmDialog}>Remove</MenuItem>}
                    </ConfirmDeleteDialog>
                ) : (
                    <LeaveTeamExperience>
                        {(openConfirmDialog, disabled) => (
                            <MenuItem onClick={openConfirmDialog} disabled={disabled}>
                                Leave Team
                            </MenuItem>
                        )}
                    </LeaveTeamExperience>
                )}
            </Menu>
        </TableRow>
    );
}

const StyledTableHeadCell = styled(TableCell)(() => ({
    backgroundColor: designTokens.color.gray.light.value,
    fontWeight: 'bold',
}));

function Header() {
    return (
        <TableHead>
            <TableRow>
                <StyledTableHeadCell>Name</StyledTableHeadCell>
                <StyledTableHeadCell>Email</StyledTableHeadCell>
                <StyledTableHeadCell>Role</StyledTableHeadCell>
                <StyledTableHeadCell colSpan={2}>State</StyledTableHeadCell>
            </TableRow>
        </TableHead>
    );
}

export function MembersTable() {
    const { id } = useAccount();
    const { data: users } = useGetAccountUsers(id);
    const sortedUsers = useMemo(
        () =>
            users
                ?.sort((a, b) => a.email.localeCompare(b.email))
                .sort((a, b) => (a.firstName ?? '').localeCompare(b.firstName ?? '')),
        [users],
    );
    return (
        <TableContainer>
            <Table stickyHeader>
                <Header />
                <TableBody>
                    {sortedUsers?.map((user) => (
                        <MemberRow user={user} key={user.id} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
