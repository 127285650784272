import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Box } from '@customink/pigment-react';

export const inputDesktopWidth = 180; // to unify width of inputs when on desktop view
export const inputMobileWidth = 160; // smaller size for mobile view

/**
 * Bounding Box for a fullWidth input, so it fills whole available space on mobile, but has constant size on desktop
 */
export const ResponsiveFullWidthInputBox = styled(Box)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        maxWidth: inputDesktopWidth,
    },
}));

/**
 * Bounding Box for a fullWidth input, so it shrinks below maximum width on mobile, but has constant size on desktop
 */
export const ResponsiveShrinkingInputBox = styled(Box)(({ theme }) => ({
    maxWidth: inputMobileWidth,
    [theme.breakpoints.up('md')]: {
        width: inputDesktopWidth,
    },
}));

/**
 * Bounding Box for a fullWidth input with constant sizes for desktop & mobile
 */
export const ResponsiveWidthInputBox = styled(Box)(({ theme }) => ({
    width: inputMobileWidth,
    [theme.breakpoints.up('md')]: {
        width: inputDesktopWidth,
    },
}));
