import { Typography } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

export function FeedbackLabel({ children }: PWC) {
    return (
        <Typography variant="uiTextSmall" color="grey.400">
            {children}
        </Typography>
    );
}
