import { ReactNode } from 'react';
import { useTheme } from '@mui/material';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Box, Button, Divider, Grid, Stack } from '@customink/pigment-react';
import { PWC } from '~/adapters/typescript/propsWithChildren';

interface LabelValueProps {
    label: ReactNode;
    value?: ReactNode;
    action?: ReactNode;
}

export function LabelValueTable({ children }: PWC) {
    return (
        <Stack divider={<Divider />} spacing={2}>
            {children}
        </Stack>
    );
}

const RowHeightPlaceholder = () => (
    <Box width={0}>
        <Button sx={{ visibility: 'hidden' }} />
    </Box>
);

const CenteredCell = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
});

export function LabelValueRow({ label, value, action }: LabelValueProps) {
    const theme = useTheme();
    return (
        <Grid container>
            <CenteredCell item sm={12} md={4} sx={{ color: { sm: theme.palette.grey[500], md: 'inherit' } }}>
                {label}
            </CenteredCell>
            <CenteredCell item sm={12} md={8}>
                <Box sx={{ overflowWrap: 'anywhere' }}>{value}</Box>
                <Box sx={{ marginLeft: 'auto', pl: 1, whiteSpace: 'nowrap' }}>{action || <RowHeightPlaceholder />}</Box>
            </CenteredCell>
        </Grid>
    );
}
