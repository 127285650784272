import { useApiCall } from '~/adapters/services/shared/apiCall';
import { getEnv } from '~/adapters/config/env';

export const useProfilesApiCall = () => {
    const apiCall = useApiCall(getEnv('ACCOUNTS_PROFILES_URL'), {
        auth: {
            withAccessToken: true,
        },
        attachImpersonate: true,
    });

    return apiCall;
};
