import { AccountDto, PersonalAccountDto } from '~/adapters/services/accounts/accounts/types';

export function transformAccountDtoToAccount(account: AccountDto) {
    return {
        ...account,
        displayName: account.personal ? 'Personal' : account.name || account.id,
    };
}

export function transformAccountDtoToPersonalAccount(account: PersonalAccountDto) {
    if (!account.personal) {
        throw new Error(`transformAccountDtoToPersonalAccount: account ${account.id} is not personal.`);
    }
    return {
        ...transformAccountDtoToAccount(account),
        personal: true,
    } as const;
}

export type Account = ReturnType<typeof transformAccountDtoToAccount>;

export type PersonalAccount = ReturnType<typeof transformAccountDtoToPersonalAccount>;
