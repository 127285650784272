import { Alert, Stack } from '@customink/pigment-react';
import { Component, ReactNode } from 'react';
import { AppWideErrorLayout } from '~/components/Layout/AppWideErrorLayout';
import { PWC } from '~/adapters/typescript/propsWithChildren';
import { knownAuthErrors } from '~/contexts/Auth/error';
import { Loader } from '~/components/Loader/loader';

type ErrorBoundaryProps = PWC<{
    errorMessage?: ReactNode;
}>;

type ErrorBoundaryState =
    | {
          hasError: false;
          errorMessage: undefined;
      }
    | {
          hasError: true;
          errorMessage: string;
      };

export class AuthErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, errorMessage: undefined };
    }

    static getDerivedStateFromError(error: Error) {
        if (knownAuthErrors.includes(error.message)) {
            return { hasError: true, errorMessage: error.message };
        }
        throw error;
    }

    render() {
        if (this.state.hasError) {
            return (
                <AppWideErrorLayout>
                    <Stack gap={2}>
                        <Alert severity="warning">{this.state.errorMessage}</Alert>
                        <Loader title="" />
                    </Stack>
                </AppWideErrorLayout>
            );
        }

        return this.props.children;
    }
}
