import { createContext, useContext } from 'react';
import { User } from '~/adapters/services/accounts/users/types';
import { Account, PersonalAccount } from '~/contexts/UserAccount/account';

export type UserAccountType = {
    account: PersonalAccount | Account | undefined;
    user: User | undefined;
    persistCurrentAccountId: (accountId: number | 'personal') => void;
};

export const UserAccountContext = createContext<UserAccountType | null>(null);

export const useOptionalUserAccount = () => {
    const userAccountContext = useContext(UserAccountContext);

    if (userAccountContext === null) {
        throw new Error(`You called ${useOptionalUserAccount.name}() outside of CurrentAccountProvider scope.`);
    }

    return userAccountContext;
};

export const useUserAccount = () => {
    const { user, account, persistCurrentAccountId } = useOptionalUserAccount();

    if (!user) {
        throw new Error(`Current user is undefined, you have to use ${useOptionalUserAccount.name} hook instead`);
    }
    if (!account) {
        throw new Error(`Current account is undefined, you have to use ${useOptionalUserAccount.name} hook instead`);
    }

    return { user, account, persistCurrentAccountId };
};

export const useAccount = () => {
    const { account } = useUserAccount();
    return account;
};
