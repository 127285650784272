import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { EnabledQueryOption } from '~/adapters/TanStackQuery/types';
import { GetGroupOrderProps, useGroupOrdersRepository } from './rest';

export const useInfiniteGroupOrders = (props: GetGroupOrderProps, { enabled }: EnabledQueryOption = {}) => {
    const { getGroupOrders } = useGroupOrdersRepository();

    return useInfiniteQuery({
        queryKey: ['group-orders', props],
        queryFn: ({ pageParam }) => getGroupOrders({ pageParam, ...props }),
        initialPageParam: 1,
        getNextPageParam: (lastData) => (lastData.hasMorePages ? lastData.currentPage + 1 : undefined),
        placeholderData: keepPreviousData,
        enabled,
    });
};

export const useGroupOrdersCount = (props: GetGroupOrderProps, { enabled }: EnabledQueryOption = {}) => {
    const { getGroupOrdersCount } = useGroupOrdersRepository();

    return useQuery({
        queryKey: ['group-orders-count', props],
        queryFn: () => getGroupOrdersCount(props),
        enabled,
    });
};
