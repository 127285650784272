/* eslint-disable */
// This file is autogenerated!!!!!
// Do not change it!!!!!!

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    Email: string;
    FgColor: any;
    JSONObject: any;
};

export type ClipArt = Icon & {
    __typename?: 'ClipArt';
    clipartId: Scalars['Int'];
    colors: Array<Color>;
    fgcolor: Scalars['FgColor'];
    grayscale: Scalars['Boolean'];
    height: Scalars['Float'];
    horizontalFlip: Scalars['Boolean'];
    lockRatio: Scalars['Boolean'];
    path: Scalars['String'];
    rotation: Scalars['Float'];
    type: IconType;
    verticalFlip: Scalars['Boolean'];
    viewId: Scalars['Int'];
    x: Scalars['Float'];
    y: Scalars['Float'];
};

export type CloneResourceForMeInput = {
    resourceId: Scalars['ID'];
};

export type CloneResourceForUsersInput = {
    emails: Array<Scalars['Email']>;
    /** Message to be sent with design clone email. */
    message?: InputMaybe<Scalars['String']>;
    referenceId: Scalars['String'];
    referenceType: ReferenceType;
};

export type ClonedDesign = {
    __typename?: 'ClonedDesign';
    compositeId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
    type?: Maybe<Scalars['String']>;
};

export type ClonedResourceUnion = ClonedDesign;

export type Color = {
    __typename?: 'Color';
    original: Original;
    palette: Original;
    user: Original;
};

export type CreateResourceError = {
    __typename?: 'CreateResourceError';
    code: CreateResourceErrorCode;
    message: Scalars['String'];
};

export enum CreateResourceErrorCode {
    DesignNotFound = 'DESIGN_NOT_FOUND',
    NoProductsForDesign = 'NO_PRODUCTS_FOR_DESIGN',
}

export type CreateResourcePayload = {
    __typename?: 'CreateResourcePayload';
    errors: Array<CreateResourceError>;
    resource?: Maybe<ResourceUnion>;
};

export type Design = {
    __typename?: 'Design';
    canUpdate: Scalars['Boolean'];
    compositeId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    designId: Scalars['Int'];
    designIdeaId?: Maybe<Scalars['Int']>;
    designName: Scalars['String'];
    email: Scalars['String'];
    isNdxDesign: Scalars['Boolean'];
    notes: Scalars['String'];
    personalization: Array<Personalization>;
    products: Array<Product>;
    quoteData: QuoteData;
    updatedAt: Scalars['DateTime'];
    views: Array<View>;
};

export type DesignReview = Resource & {
    __typename?: 'DesignReview';
    createdAt: Scalars['DateTime'];
    design: Design;
    designStatus: DesignStatus;
    emailSharing: Array<EmailShare>;
    events: DesignReviewEventConnection;
    /** ID of resource in collaboration service. Use this id when calling collaboration API */
    id: Scalars['ID'];
    linkShare?: Maybe<LinkShare>;
    myRole: SharingRole;
    /** External ID of resource */
    referenceId: Scalars['String'];
    /** Type of resource */
    referenceType: ReferenceType;
};

export type DesignReviewEventsArgs = {
    after?: InputMaybe<Scalars['String']>;
    first: Scalars['Int'];
    orderBy?: InputMaybe<EventOrderByInput>;
};

export enum DesignReviewEditableEventType {
    Approve = 'APPROVE',
    Comment = 'COMMENT',
    Reject = 'REJECT',
    Rerequest = 'REREQUEST',
}

export type DesignReviewEvent = {
    __typename?: 'DesignReviewEvent';
    author: Scalars['String'];
    createdAt: Scalars['DateTime'];
    data?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    type: DesignReviewEventType;
};

export type DesignReviewEventConnection = {
    __typename?: 'DesignReviewEventConnection';
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
    edges?: Maybe<Array<DesignReviewEventEdge>>;
    /** Flattened list of DesignReviewEvent type */
    nodes: Array<DesignReviewEvent>;
    /** Cursors for traditional page based pagination */
    pageCursors: PageCursors;
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
    pageInfo: PageInfo;
    /** Identifies the total count of items in the connection. */
    totalCount: Scalars['Int'];
};

export type DesignReviewEventEdge = {
    __typename?: 'DesignReviewEventEdge';
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
    cursor: Scalars['String'];
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
    node: DesignReviewEvent;
};

export enum DesignReviewEventType {
    Approve = 'APPROVE',
    Comment = 'COMMENT',
    Reject = 'REJECT',
    Rerequest = 'REREQUEST',
    Share = 'SHARE',
}

export type DesignStatus = {
    __typename?: 'DesignStatus';
    id: Scalars['ID'];
    lastView: Scalars['DateTime'];
    status: DesignStatusEnum;
    updatedAt: Scalars['DateTime'];
};

export enum DesignStatusEnum {
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Shared = 'SHARED',
}

export type Effect = {
    __typename?: 'Effect';
    setting: Scalars['String'];
    type: Scalars['String'];
};

export type EmailShare = {
    __typename?: 'EmailShare';
    createdAt: Scalars['DateTime'];
    email: Scalars['Email'];
    id: Scalars['ID'];
    personalAccountId?: Maybe<Scalars['Int']>;
    role: SharingRole;
    updatedAt: Scalars['DateTime'];
};

export type EmailSharesCreateInput = {
    message?: InputMaybe<Scalars['String']>;
    resourceId: Scalars['ID'];
    users: Array<SharingUser>;
};

export type Event = {
    __typename?: 'Event';
    author: Scalars['String'];
    createdAt: Scalars['DateTime'];
    data?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
};

export type EventInput = {
    data?: InputMaybe<Scalars['String']>;
    resourceId: Scalars['ID'];
    type: DesignReviewEditableEventType;
};

export type EventOrderByInput = {
    direction: OrderDirection;
    field: EventOrderField;
};

/** Properties by which event connections can be ordered. Default order is STARTED_AT ASC */
export enum EventOrderField {
    CreatedAt = 'CREATED_AT',
    Id = 'ID',
}

export type Icon = {
    fgcolor: Scalars['FgColor'];
    rotation: Scalars['Float'];
    type: IconType;
    viewId: Scalars['Int'];
    x: Scalars['Float'];
    y: Scalars['Float'];
};

export enum IconType {
    ClipArt = 'CLIP_ART',
    Names = 'NAMES',
    Numbers = 'NUMBERS',
    Text = 'TEXT',
    Upload = 'UPLOAD',
}

export type IconUnion = ClipArt | Names | Numbers | Text | Upload;

export type LinkShare = {
    __typename?: 'LinkShare';
    createdAt: Scalars['DateTime'];
    hash: Scalars['String'];
    id: Scalars['ID'];
    role?: Maybe<SharingRole>;
    updatedAt: Scalars['DateTime'];
};

export type LinkShareUpdateInput = {
    resourceId: Scalars['ID'];
    role?: InputMaybe<SharingRole>;
};

export type Mutation = {
    __typename?: 'Mutation';
    cloneResourceForMe: ClonedResourceUnion;
    cloneResourceForUsers: Array<ClonedResourceUnion>;
    createEvent: Event;
    createResource: CreateResourcePayload;
    unsubscribeFromResource: Scalars['Boolean'];
    updateEmailShares: Array<EmailShare>;
    updateLinkShare: LinkShare;
};

export type MutationCloneResourceForMeArgs = {
    cloneForMeInput: CloneResourceForMeInput;
};

export type MutationCloneResourceForUsersArgs = {
    cloneForUsersInput: CloneResourceForUsersInput;
};

export type MutationCreateEventArgs = {
    eventInput: EventInput;
};

export type MutationCreateResourceArgs = {
    resourceInput: ResourceCreateInput;
};

export type MutationUnsubscribeFromResourceArgs = {
    unsubscribeInput: UnsubscribeFromResourceInput;
};

export type MutationUpdateEmailSharesArgs = {
    emailSharesInput: EmailSharesCreateInput;
};

export type MutationUpdateLinkShareArgs = {
    linkShareInput: LinkShareUpdateInput;
};

export type Names = Icon & {
    __typename?: 'Names';
    align: Scalars['String'];
    extension: Scalars['String'];
    fgcolor: Scalars['FgColor'];
    font: Scalars['String'];
    fontHeight: Scalars['Float'];
    fontName: Scalars['String'];
    rotation: Scalars['Float'];
    type: IconType;
    value: Scalars['String'];
    viewId: Scalars['Int'];
    x: Scalars['Float'];
    y: Scalars['Float'];
};

export type Numbers = Icon & {
    __typename?: 'Numbers';
    align: Scalars['String'];
    extension: Scalars['String'];
    fgcolor: Scalars['FgColor'];
    font: Scalars['String'];
    fontHeight: Scalars['Float'];
    fontName: Scalars['String'];
    rotation: Scalars['Float'];
    type: IconType;
    value: Scalars['String'];
    viewId: Scalars['Int'];
    x: Scalars['Float'];
    y: Scalars['Float'];
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
    Asc = 'ASC',
    Desc = 'DESC',
}

export type Original = {
    __typename?: 'Original';
    blue: Scalars['Int'];
    green: Scalars['Int'];
    red: Scalars['Int'];
    transparent?: Maybe<Scalars['Boolean']>;
};

export type Outline = {
    __typename?: 'Outline';
    clearFill: Scalars['Boolean'];
    color: Scalars['FgColor'];
    width: Scalars['Float'];
};

export type PageCursor = {
    __typename?: 'PageCursor';
    /** Cursor that can be passed to "after" parameter to obtain this page */
    cursor: Scalars['String'];
    /**  Page number, indexed from 1 */
    pageNumber: Scalars['Int'];
};

export type PageCursors = {
    __typename?: 'PageCursors';
    /** Cursor to first page, can be null if we have only single page */
    first?: Maybe<PageCursor>;
    /** Cursor to last page, can be null if we have only single page */
    last?: Maybe<PageCursor>;
    /** Cursor to next page, can be null if there's no next page */
    next?: Maybe<PageCursor>;
    /** Cursor to previous page, can be null if there's no previous page */
    previous?: Maybe<PageCursor>;
};

export type PageInfo = {
    __typename?: 'PageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    startCursor?: Maybe<Scalars['String']>;
};

export type Personalization = {
    __typename?: 'Personalization';
    name: Scalars['String'];
    number: Scalars['String'];
    size: Scalars['String'];
};

export type Product = {
    __typename?: 'Product';
    colorId: Scalars['String'];
    previews: Array<Scalars['String']>;
    styleId: Scalars['String'];
};

export type Query = {
    __typename?: 'Query';
    resource?: Maybe<ResourceUnion>;
    resources: SharedWithMeConnection;
};

export type QueryResourceArgs = {
    resourceInput: ResourceInput;
};

export type QueryResourcesArgs = {
    after?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<ResourcesFilter>;
    first: Scalars['Int'];
    orderBy?: InputMaybe<ResourcesOrderByInput>;
    referenceType: ReferenceType;
};

export type QuoteData = {
    __typename?: 'QuoteData';
    postalCode: Scalars['String'];
    quantities: Scalars['JSONObject'];
    totalPrice: Scalars['Int'];
};

export enum ReferenceType {
    DesignReview = 'DESIGN_REVIEW',
}

export type Resource = {
    createdAt: Scalars['DateTime'];
    emailSharing: Array<EmailShare>;
    /** ID of resource in collaboration service. Use this id when calling collaboration API */
    id: Scalars['ID'];
    linkShare?: Maybe<LinkShare>;
    myRole: SharingRole;
    /** External ID of resource */
    referenceId: Scalars['String'];
    /** Type of resource */
    referenceType: ReferenceType;
};

export type ResourceCreateInput = {
    emailShares?: InputMaybe<Array<SharingUser>>;
    linkShare?: InputMaybe<SharingRole>;
    /** Message to be sent with invitation email. Required when `emailShares` defined. */
    message?: InputMaybe<Scalars['String']>;
    referenceId: Scalars['String'];
    referenceType: ReferenceType;
};

export type ResourceInput = {
    id?: InputMaybe<Scalars['ID']>;
    referenceId?: InputMaybe<Scalars['String']>;
    referenceType?: InputMaybe<ReferenceType>;
};

export type ResourceUnion = DesignReview;

export type ResourceUnionEdge = {
    __typename?: 'ResourceUnionEdge';
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
    cursor: Scalars['String'];
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
    node: ResourceUnion;
};

export type ResourcesFilter = {
    roleNotEq?: InputMaybe<SharingRole>;
};

export type ResourcesOrderByInput = {
    direction: OrderDirection;
    field: ResourcesOrderField;
};

/** Properties by which resources connections can be ordered. Default order is CREATED_AT DESC */
export enum ResourcesOrderField {
    CreatedAt = 'CREATED_AT',
    Id = 'ID',
}

export type SharedWithMeConnection = {
    __typename?: 'SharedWithMeConnection';
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
    edges?: Maybe<Array<ResourceUnionEdge>>;
    /** Flattened list of ResourceUnion type */
    nodes: Array<ResourceUnion>;
    /** Cursors for traditional page based pagination */
    pageCursors: PageCursors;
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
    pageInfo: PageInfo;
    /** Identifies the total count of items in the connection. */
    totalCount: Scalars['Int'];
};

export enum SharingRole {
    Commenter = 'COMMENTER',
    Editor = 'EDITOR',
    Owner = 'OWNER',
    Reader = 'READER',
}

export type SharingUser = {
    email: Scalars['Email'];
    /** Role OWNER is forbidden */
    role: SharingRole;
};

export type Text = Icon & {
    __typename?: 'Text';
    align: Scalars['String'];
    effect: Effect;
    extension: Scalars['String'];
    fgcolor: Scalars['FgColor'];
    fit: Scalars['String'];
    font: Scalars['String'];
    fontHeight: Scalars['Float'];
    fontName: Scalars['String'];
    naturalHeight: Scalars['Float'];
    naturalWidth: Scalars['Float'];
    outline: Outline;
    rotation: Scalars['Float'];
    spacing: Scalars['Float'];
    type: IconType;
    value: Scalars['String'];
    viewId: Scalars['Int'];
    x: Scalars['Float'];
    y: Scalars['Float'];
};

export type UnsubscribeFromResourceInput = {
    resourceId: Scalars['ID'];
};

export type Upload = Icon & {
    __typename?: 'Upload';
    colors: Array<Color>;
    dpi: Scalars['Int'];
    fgcolor: Scalars['FgColor'];
    height: Scalars['Float'];
    horizontalFlip: Scalars['Boolean'];
    imageType: Scalars['String'];
    invalid: Scalars['Boolean'];
    rotation: Scalars['Float'];
    singleColor: Scalars['Boolean'];
    type: IconType;
    url: Scalars['String'];
    verticalFlip: Scalars['Boolean'];
    viewId: Scalars['Int'];
    x: Scalars['Float'];
    y: Scalars['Float'];
};

export type View = {
    __typename?: 'View';
    colorCount?: Maybe<Scalars['Int']>;
    icons: Array<IconUnion>;
    name: Scalars['String'];
    viewId: Scalars['Int'];
};

export type GetDesignReviewQueryVariables = Exact<{
    input: ResourceInput;
    after?: InputMaybe<Scalars['String']>;
}>;

export type GetDesignReviewQuery = {
    __typename?: 'Query';
    resource?: {
        __typename?: 'DesignReview';
        id: string;
        myRole: SharingRole;
        referenceId: string;
        design: {
            __typename?: 'Design';
            designName: string;
            products: Array<{ __typename?: 'Product'; previews: Array<string> }>;
        };
        designStatus: { __typename?: 'DesignStatus'; status: DesignStatusEnum; updatedAt: any };
        events: {
            __typename?: 'DesignReviewEventConnection';
            pageCursors: {
                __typename?: 'PageCursors';
                next?: { __typename?: 'PageCursor'; cursor: string } | null;
                previous?: { __typename?: 'PageCursor'; cursor: string } | null;
            };
            edges?: Array<{
                __typename?: 'DesignReviewEventEdge';
                node: {
                    __typename?: 'DesignReviewEvent';
                    id: string;
                    type: DesignReviewEventType;
                    data?: string | null;
                    createdAt: any;
                    author: string;
                };
            }> | null;
        };
    } | null;
};

export type GetDesignReviewInfoQueryVariables = Exact<{
    input: ResourceInput;
}>;

export type GetDesignReviewInfoQuery = {
    __typename?: 'Query';
    resource?: {
        __typename?: 'DesignReview';
        id: string;
        designStatus: { __typename?: 'DesignStatus'; status: DesignStatusEnum };
        emailSharing: Array<{ __typename?: 'EmailShare'; email: string; role: SharingRole }>;
        linkShare?: { __typename?: 'LinkShare'; id: string; role?: SharingRole | null; hash: string } | null;
    } | null;
};

export type SharedWithMeQueryVariables = Exact<{
    input: ReferenceType;
    after?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<ResourcesFilter>;
}>;

export type SharedWithMeQuery = {
    __typename?: 'Query';
    resources: {
        __typename?: 'SharedWithMeConnection';
        nodes: Array<{
            __typename?: 'DesignReview';
            id: string;
            myRole: SharingRole;
            design: {
                __typename?: 'Design';
                compositeId: string;
                designName: string;
                updatedAt: any;
                products: Array<{ __typename?: 'Product'; previews: Array<string> }>;
            };
        }>;
        pageCursors: {
            __typename?: 'PageCursors';
            next?: { __typename?: 'PageCursor'; cursor: string } | null;
            previous?: { __typename?: 'PageCursor'; cursor: string } | null;
        };
    };
};

export type DesignDetailFragment = {
    __typename?: 'DesignReview';
    id: string;
    myRole: SharingRole;
    design: {
        __typename?: 'Design';
        compositeId: string;
        designName: string;
        updatedAt: any;
        products: Array<{ __typename?: 'Product'; previews: Array<string> }>;
    };
};

export type SharedWithMeCountQueryVariables = Exact<{ [key: string]: never }>;

export type SharedWithMeCountQuery = {
    __typename?: 'Query';
    resources: { __typename?: 'SharedWithMeConnection'; totalCount: number };
};

export type CreateEventMutationVariables = Exact<{
    input: EventInput;
}>;

export type CreateEventMutation = {
    __typename?: 'Mutation';
    createEvent: { __typename?: 'Event'; author: string; data?: string | null };
};

export type CloneResourceForMeMutationVariables = Exact<{
    input: CloneResourceForMeInput;
}>;

export type CloneResourceForMeMutation = {
    __typename?: 'Mutation';
    cloneResourceForMe: { __typename?: 'ClonedDesign'; compositeId?: string | null; id: number };
};

export type CloneResourceForUserMutationVariables = Exact<{
    input: CloneResourceForUsersInput;
}>;

export type CloneResourceForUserMutation = {
    __typename?: 'Mutation';
    cloneResourceForUsers: Array<{ __typename?: 'ClonedDesign'; compositeId?: string | null; id: number }>;
};

export type CreateResourceMutationVariables = Exact<{
    input: ResourceCreateInput;
}>;

export type CreateResourceMutation = {
    __typename?: 'Mutation';
    createResource: {
        __typename?: 'CreateResourcePayload';
        resource?: {
            __typename?: 'DesignReview';
            id: string;
            linkShare?: { __typename?: 'LinkShare'; hash: string } | null;
        } | null;
        errors: Array<{ __typename?: 'CreateResourceError'; code: CreateResourceErrorCode }>;
    };
};

export type UpdateEmailSharesMutationVariables = Exact<{
    input: EmailSharesCreateInput;
}>;

export type UpdateEmailSharesMutation = {
    __typename?: 'Mutation';
    updateEmailShares: Array<{ __typename?: 'EmailShare'; id: string }>;
};

export type UpdateLinkShareMutationVariables = Exact<{
    input: LinkShareUpdateInput;
}>;

export type UpdateLinkShareMutation = {
    __typename?: 'Mutation';
    updateLinkShare: { __typename?: 'LinkShare'; id: string; hash: string };
};

export type UnsubscribeFromResourceMutationVariables = Exact<{
    input: UnsubscribeFromResourceInput;
}>;

export type UnsubscribeFromResourceMutation = { __typename?: 'Mutation'; unsubscribeFromResource: boolean };
