import { createContext, Dispatch, useContext } from 'react';
import { REDIRECTING_TO_LOGIN_ERROR } from '~/contexts/Auth/error';

export interface AuthUser {
    id: string;
    impersonated?: {
        originalEmail: string;
    };
    email: string;
    isInternal: boolean;
    emailDerivedName: string;
    fullName: string;
    isAuthenticated: boolean;
}

export type AuthTokens = { idToken: string; accessToken: string };

export interface AuthContextData {
    user: AuthUser | undefined;
    identityProvider: 'profiles' | 'auth0';
    iat?: number;
    logout: () => void;
    loginWithRedirect: () => Promise<void>;
    loginWithPopup: () => Promise<void>;
    loginSilently: () => Promise<void>;
    /**
     * Retrieves tokens of authenticated user. <br>
     * If user is unauthenticated or exists but has invalid JWT, redirects to login and throws `REDIRECTING_TO_LOGIN_ERROR`.
     */
    getTokens: () => Promise<AuthTokens>;
    isImpersonatedExperience: boolean;
    setImpersonatedExperience: Dispatch<boolean>;
}

export function getTargetUserInfo(isImpersonated: boolean, userEmail: string, impersonatedEmail?: string | null) {
    if (isImpersonated) {
        return {
            email: impersonatedEmail ?? '',
            impersonated: {
                originalEmail: userEmail,
            },
        };
    }
    return {
        email: userEmail,
    };
}

export const AuthContext = createContext<AuthContextData | null>(null);

export function useAuth() {
    const authContext = useContext(AuthContext);
    if (!authContext) {
        throw new Error(`UserContext is null! Hook ${useAuth.name} must be used within a UserProvider`);
    }

    return authContext;
}

export function useOptionalAuthUser() {
    const authContext = useContext(AuthContext);
    if (!authContext) {
        return undefined;
    }

    return authContext.user;
}

export function useOptionalAuth() {
    const authContext = useContext(AuthContext);
    if (!authContext) {
        return undefined;
    }

    return authContext;
}

export function useAuthUser() {
    const auth = useAuth();
    const user = useOptionalAuthUser();

    if (!user) {
        // this may happen when tokens expire and the user is not logged in
        auth.loginWithRedirect();
        throw new Error(REDIRECTING_TO_LOGIN_ERROR);
    }

    return user;
}
