import { useQuery } from '@tanstack/react-query';
import { useProfileRepository } from '~/adapters/services/profiles/profile/rest';

export function useHasPassword() {
    const { getMyUserData } = useProfileRepository();

    return useQuery({
        queryKey: ['has-password-key'],
        queryFn: async () => {
            const response = await getMyUserData();
            return response.has_password;
        },
    });
}
