import { Button, DialogLegacy, DialogLegacyActions, DialogLegacyContent, Typography } from '@customink/pigment-react';
import { useAccount } from '~/contexts/UserAccount/UserAccountContext';
import { FormEvent, useCallback } from 'react';
import { useInviteAccountUsers } from '~/adapters/services/accounts/accounts/hooks';
import Spinner from '~/components/Spinner';
import { MultipleEmailInput } from '~/components/input/MultipleEmailInput';
import { pushApiErrorNotification, pushSuccessNotification } from '~/adapters/notistack/notistack';
import { useMultipleEmailInput } from '~/components/input/useMultipleEmailInput';

type InviteMembersDialogProps = {
    open: boolean;
    onClose: () => void;
};

export function InviteMembersDialog({ open, onClose }: InviteMembersDialogProps) {
    const account = useAccount();

    const { multipleEmailInputProps, parsingResult } = useMultipleEmailInput();
    const { setErrorMessage } = multipleEmailInputProps;

    const { mutateAsync: sendInvites, isPending } = useInviteAccountUsers();

    const handleInviteUsers = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (parsingResult.status !== 'ok') {
                setErrorMessage(parsingResult.errorMessage);
                return;
            }
            if (parsingResult.newEmails.length === 0) {
                setErrorMessage('Please enter at least one email address');
                return;
            }
            sendInvites(
                { accountId: account.id, emails: parsingResult.newEmails },
                {
                    onSuccess: () => {
                        onClose();
                        pushSuccessNotification('The users were invited to the team.');
                    },
                    onError: (error: any) => {
                        if (error?.response?.status >= 400 && error?.response?.status < 500) {
                            setErrorMessage(
                                'Please check that all emails belong to existing users. Dev disclaimer: This behavior is WIP.',
                            );
                        } else {
                            pushApiErrorNotification('No users were added to the team because of an error.');
                            throw error;
                        }
                    },
                },
            );
        },
        [sendInvites, account.id, onClose, parsingResult, setErrorMessage],
    );

    return (
        <DialogLegacy open={open} onClose={onClose} title={`Invite Team Members to ${account.name}`} fullWidth>
            <form onSubmit={handleInviteUsers} id="InviteUsersForm">
                <DialogLegacyContent>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <MultipleEmailInput {...multipleEmailInputProps} label="Invite Team Members" autoFocus />
                    <Typography component="p" variant="captionSmall" my={1}>
                        Development status disclaimer: Inviting is not supported yet. Users will be simply added. All
                        emails must belong to existing users.
                    </Typography>
                </DialogLegacyContent>
                <DialogLegacyActions>
                    <Button type="submit" disabled={isPending}>
                        {isPending ? <Spinner /> : 'Add Team Members'}
                    </Button>
                </DialogLegacyActions>
            </form>
        </DialogLegacy>
    );
}
