import { Box, Chip, Stack } from '@customink/pigment-react';
import { OrderDetails } from '~/adapters/services/accounts/orders/types';
import { OrderLabel } from './OrderLabel';
import { InfoText } from '../../../components/InfoText';

export function OrderItemsInfo({ orderDetails }: { orderDetails: OrderDetails }) {
    return (
        <Stack spacing={2}>
            <div>
                <OrderLabel>Decoration:</OrderLabel>
                <InfoText>
                    {orderDetails.printingDetail.printingMethod.toLowerCase().includes('embroidery')
                        ? 'Embroidery'
                        : 'Printing'}
                </InfoText>
            </div>
            <div>
                {orderDetails.orderItems.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box key={index}>
                        <OrderLabel>Order Item {index + 1}:</OrderLabel>
                        <InfoText>{item.itemName}</InfoText>
                        <Stack direction="row" flexWrap="wrap" gap={1}>
                            {item.orderItemQuantities.map((q) => (
                                <Chip label={`${q.size} ${q.quantity}`} key={`${q.size}-${q.quantity}`} />
                            ))}
                        </Stack>
                    </Box>
                ))}
            </div>
        </Stack>
    );
}
