import React, { ReactNode, useState } from 'react';
import { usePage } from '~/adapters/browser/usePage';
import { useInfiniteOrders } from '~/adapters/services/accounts/orders/hooks';
import { useFlattenPaginatedData } from '~/adapters/services/shared/useFlattenPaginatedData';
import { OrderSortBy } from '~/adapters/services/accounts/orders/types';
import { useInfiniteHealthAndWellnessOrders } from '~/adapters/services/accounts/stores/hooks';
import { Loader } from '~/components/Loader/loader';
import { useAccount } from '~/contexts/UserAccount/UserAccountContext';
import { useLinks } from '~/contexts/LinkContext';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { OrderHistoryList } from './OrderHistoryList/OrderHistoryList';

export interface Props {
    children?: ReactNode;
}

const OrderHistory: React.FC<Props> = () => {
    const links = useLinks();
    const { id: accountId } = useAccount();
    const [sort, setSort] = useState<OrderSortBy>('-placedAt');

    usePage({ title: 'Order History', reportPath: '/accounts/orders' });

    const {
        data: ordersData,
        isFetching: isOrderFetching,
        hasNextPage: hasOrderNextPage,
        fetchNextPage: fetchNextOrderPage,
        isPlaceholderData: isOrderPreviousData,
        isLoading: isOrderLoading,
    } = useInfiniteOrders({ accountId, sort });
    const { data: healthAndWellnessData, isLoading: isHealthAndWellnessLoading } = useInfiniteHealthAndWellnessOrders({
        accountId,
    });
    const orders = useFlattenPaginatedData(ordersData, 'items');
    const healthAndWellnessOrders = useFlattenPaginatedData(healthAndWellnessData, 'items');

    if (isHealthAndWellnessLoading || isOrderLoading) {
        return <Loader title="Loading..." devLabel="order API" />;
    }
    return (
        <ErrorBoundary errorMessage="An error occurred while retrieving your order history">
            <OrderHistoryList
                orders={orders}
                healthAndWellnessOrders={healthAndWellnessOrders}
                designLabLink={links.designLabLink}
                loading={isOrderFetching}
                onLoadMore={fetchNextOrderPage}
                hasMorePages={hasOrderNextPage}
                sort={sort}
                onSortChange={setSort}
                filtersChangedLoading={isOrderFetching && isOrderPreviousData}
            />
        </ErrorBoundary>
    );
};

export default OrderHistory;
