import {
    Alert,
    Button,
    DialogLegacyActions,
    DialogLegacyContent,
    Divider,
    IconButton,
    LinkIcon,
    Stack,
    TextField,
    Typography,
    XCloseIcon,
} from '@customink/pigment-react';
import React, { FormEventHandler, useCallback, useEffect } from 'react';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { DialogTitle } from '@mui/material';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import { useMultipleEmailInput } from '~/components/input/useMultipleEmailInput';
import { MultipleEmailInput } from '~/components/input/MultipleEmailInput';
import { useCreateTeamContext } from '~/components/MultiAccountManagement/Wizard/CreateTeamContext';
import Spinner from '~/components/Spinner';

export interface TeamMembersStepProps {
    onSubmit: () => void;
    onClose: EmptyFunction;
    onStepBack: EmptyFunction;
    totalSteps: number;
    isPending?: boolean;
}

function CloseIcon({ onClose }: { onClose: React.MouseEventHandler }) {
    return (
        <IconButton size="large" onClick={onClose as React.MouseEventHandler} aria-label="Close">
            <XCloseIcon fontSize="inherit" />
        </IconButton>
    );
}

export function TeamMembersStep({ onClose, onSubmit, onStepBack, totalSteps, isPending }: TeamMembersStepProps) {
    const { inviteMessage, setInviteMessage, setInviteEmails, teamName } = useCreateTeamContext();
    const { multipleEmailInputProps, parsingResult } = useMultipleEmailInput();

    const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
        (e) => {
            e.preventDefault();
            onSubmit();
        },
        [onSubmit],
    );

    useEffect(() => {
        // We intentionally don't use `multipleEmailInputProps.emails` but `parsingResult.newEmails`. That's is important for UX: W
        // We want to include the last email the user has typed, even if it is not yet added to the list of emails (which happens when they press Tab, Enter, etc).
        setInviteEmails(parsingResult.newEmails);
    }, [parsingResult.newEmails]);

    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle>
                Invite Team Members to {teamName}
                <CloseIcon onClose={onClose} />
            </DialogTitle>
            <DialogLegacyContent>
                <Stack spacing={3}>
                    <Button startIcon={<LinkIcon />} variant="secondary" fullWidth>
                        Copy Invitation Link
                    </Button>
                    <Divider>OR</Divider>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <MultipleEmailInput {...multipleEmailInputProps} label="Invite Team Members" autoFocus />
                    <TextField
                        id="message"
                        label="Message"
                        placeholder="Join my team on Custom Ink so we can easily create and share designs!"
                        fullWidth
                        value={inviteMessage}
                        onChange={(e) => setInviteMessage(e.target.value)}
                    />
                </Stack>
                <Alert severity="info" sx={{ mt: 0, mb: 3 }}>
                    You can add or remove team members or change their roles and permissions at any time through the
                    &quot;Team Settings&quot; menu.
                </Alert>
            </DialogLegacyContent>
            <DialogLegacyActions>
                <Typography variant="uiTextSmall" sx={{ flexGrow: 1, color: designTokens.color.gray.limestone.value }}>
                    2 of {totalSteps}
                </Typography>
                <Button onClick={onStepBack} variant="secondary">
                    Back
                </Button>
                <Button variant="primary" type="submit" disabled={isPending}>
                    {isPending ? <Spinner /> : 'Next'}
                </Button>
            </DialogLegacyActions>
        </form>
    );
}
