import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@customink/pigment-react';
import isEmail from 'validator/es/lib/isEmail';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { Loader } from '~/components/Loader/loader';
import { useUpdateContactEmail } from '~/adapters/services/accounts/accounts/hooks';
import { useRegisterInput } from '~/adapters/react-hook-form/registerInput';

interface ChangeContactEmailDialogProps {
    open: boolean;
    onClose: EmptyFunction;
    currentContactEmail?: string | null;
    accountId: number;
}

interface ChangeContactEmailForm {
    contactEmail: string;
}

const validateContactEmail = (value: string) => {
    if (value === '') {
        // empty contact email is valid -> it will be converted to null when sending request to api
        return true;
    }
    if (value.length > 255) {
        return 'Contact email is too long';
    }
    return isEmail(value) || 'Invalid email address';
};

export function ChangeContactEmailDialog({
    currentContactEmail,
    accountId,
    onClose,
    open,
}: ChangeContactEmailDialogProps) {
    const { mutate, isPending } = useUpdateContactEmail();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ChangeContactEmailForm>({
        defaultValues: { contactEmail: currentContactEmail ?? '' },
    });
    const registerInput = useRegisterInput(register);

    const onSubmit = useCallback<SubmitHandler<ChangeContactEmailForm>>(
        ({ contactEmail }) => {
            mutate({ contactEmail: contactEmail || null, accountId }, { onSettled: onClose });
        },
        [mutate, accountId, onClose],
    );

    return (
        <Dialog open={open} onClose={onClose} title="Change contact email" width="md" mobileVariant="fullScreen">
            <DialogContent pt={2}>
                <TextField
                    id="contact-email"
                    label="Contact email"
                    fullWidth
                    error={Boolean(errors.contactEmail)}
                    helperText={errors.contactEmail?.message}
                    autoFocus
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...registerInput('contactEmail', { validate: validateContactEmail })}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmit)} disabled={isPending}>
                    {isPending ? <Loader title="Saving..." /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
