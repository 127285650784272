import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    MenuDotsHorizontalIcon,
    ShareIcon,
    Stack,
    useMobileBreakpoint,
} from '@customink/pigment-react';
import { styled } from '@customink/pigment-react/lib/themeCustomink';
import { Typography, useTheme } from '@mui/material';
import { flow } from 'fp-ts/function';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import setLocation from '~/adapters/browser/setLocation';
import { stopPropagation } from '~/adapters/browser/stopPropagation';
import { parseProductNameAndColor } from '~/adapters/services/accounts/designs/transformer';
import { Design } from '~/adapters/services/accounts/designs/types';
import { ReferenceType } from '~/adapters/services/collaboration/graphqlTypes';
import { useGetDesignReviewInfoQuery } from '~/adapters/services/collaboration/collaborationHooks';
import { useAddToCartOrRedirect } from '~/adapters/services/rfe/hooks';
import { productCarouselImages } from '~/adapters/services/mms/links';
import { linkTrackEvent, trackEvent } from '~/adapters/tracking/tracking';
import { DesignCardMedia } from '~/components/DesignCard/DesignCardMedia';
import { useMenu } from '~/components/Menu/useMenu';
import { useSignalmanFeatureFlag } from '~/contexts/FeatureFlagSignalmanContext';
import BuyButton from '~/pages/SavedDesigns/components/BuyButton';
import { checkout } from '~/adapters/services/rfe/links';
import { IconButtonBlack } from '~/components/Menu/IconButtonBlack';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';
import { SharingAPIProvider } from './SharingAndCollab/SharingAPIContext';
import { SharingDialog } from './SharingAndCollab/SharingDialog';
import { AvatarList } from './SharingAndCollab/components/AvatarList';
import { designStatusEnumToSharingStatusType } from './SharingAndCollab/mappers';
import { CardMenu } from './components/CardMenu';
import { DeleteDialog } from './components/DeleteDialog';
import { DesignPrice } from './components/DesignPrice';
import { OrderOptionsButton } from './components/OrderOptionsButton';
import { ViewOrderOptionsTutorial } from './components/ViewOrderOptionsTutorial';
import { useEditDesignLink } from './links';
import { gaCategoryNew, gaCategoryOld, gaCreateDimension } from './tracking';
import { useViewOrderOptionsTutorial } from './useViewOrderOptionsTutorial';

interface DesignCardProps {
    design: Design;
    selected: boolean;
    setSelected: (selected: boolean) => void;
    index: number;
    isMobile2ColumnsView: boolean;
    showAddToCartSnackbar: (message: string) => void;
}

const OverlayChip = styled(Chip)(({ theme }) => {
    return {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2),
    };
});

export function DesignCard({
    design,
    selected,
    setSelected,
    index,
    showAddToCartSnackbar,
    isMobile2ColumnsView,
}: DesignCardProps) {
    const theme = useTheme();
    const isMobile = useMobileBreakpoint();
    const collaboration = useSignalmanFeatureFlag('collaboration');
    const artProofsFFEnabled = useFeatureFlagEnabled('designs_page_art_proofs_v0');
    const isArtProofLabLinkEnabled = useFeatureFlagEnabled('art_proof_lab_link_v1');
    const addToCartOrRedirect = useAddToCartOrRedirect();

    // For legacy purpose show only first product name and color
    const product = useMemo(() => design.products[0], [design]);
    const [productName, productColor] = useMemo(() => parseProductNameAndColor(product.productNameAndColor), [product]);

    const [sharingDialogVisible, setSharingDialogVisible] = useState(false);
    const openSharingDialog = useCallback(
        flow(stopPropagation, () => {
            setSharingDialogVisible(true);
            trackEvent({
                category: gaCategoryNew,
                action: 'Button Click',
                label: 'Share',
                dimension: gaCreateDimension(index),
            });
        }),
        [design],
    );
    const closeSharingDialog = useCallback(() => setSharingDialogVisible(false), []);

    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const openDeleteDialog = useCallback(() => setDeleteDialogVisible(true), []);
    const closeDeleteDialog = useCallback(() => setDeleteDialogVisible(false), []);

    const { data } = useGetDesignReviewInfoQuery(
        {
            input: { referenceId: design.compositeId, referenceType: ReferenceType.DesignReview },
        },
        { enabled: collaboration },
    );
    const sharingStatus = useMemo(
        () => designStatusEnumToSharingStatusType(data?.resource?.designStatus.status),
        [data],
    );

    const { anchorEl: anchorElMenu, handleOpen: handleOpenMenu, handleClose: handleCloseMenu } = useMenu();

    const handleCardMenuClick = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            handleOpenMenu(event);
            trackEvent({
                category: gaCategoryOld,
                action: 'Button Click',
                label: 'Open Card Menu',
            });
        },
        [handleOpenMenu],
    );

    const editDesignLink = useEditDesignLink(design.compositeId);
    const navigateToEdit = useCallback(
        flow(
            stopPropagation,
            (event) =>
                linkTrackEvent(event, {
                    category: gaCategoryOld,
                    action: 'edit design',
                    dimension: gaCreateDimension(index),
                }),
            handleCloseMenu,
        ),
        [design, editDesignLink],
    );

    const navigateToShare = useCallback(() => {
        trackEvent({
            category: gaCategoryNew,
            action: 'Link Click',
            label: 'Share',
            dimension: gaCreateDimension(index),
        });
        setLocation(`${editDesignLink}#/next/saveConfirmation`);
    }, [setLocation, design, editDesignLink, index]);

    const handleSelectAndGA = useCallback(() => {
        setSelected(!selected);
        trackEvent({
            category: gaCategoryOld,
            action: 'Button Click',
            label: selected ? 'Design Card deselect' : 'Design Card select',
        });
    }, [selected]);

    const handleSlideClick = useCallback(async () => {
        trackEvent({
            category: gaCategoryOld,
            action: 'proof image',
            label: isArtProofLabLinkEnabled ? 'redirect to lab' : 'redirect to cart',
            dimension: gaCreateDimension(index),
        });
        if (!isArtProofLabLinkEnabled) {
            await addToCartOrRedirect([design.compositeId]);
            setLocation(checkout());
        }
    }, [addToCartOrRedirect, design.compositeId, index, isArtProofLabLinkEnabled, editDesignLink]);

    const carouselImages = useMemo(() => {
        let artProofsLinks: string[] = [];
        if (design.artProofs.length && artProofsFFEnabled) {
            artProofsLinks = design.artProofs.map((proof) => proof.proofUrl);
        }
        const designLinks = productCarouselImages(
            design.products.map((p) => ({
                ...p,
                compositeId: design.compositeId,
            })),
            false,
            design.updatedAt ? new Date(design.updatedAt) : undefined,
        );
        return [...artProofsLinks, ...designLinks];
    }, [design, artProofsFFEnabled]);

    const [cardElement, setCardElement] = useState<HTMLDivElement | null>(null);

    const {
        isTutorialShown,
        handleTutorialClose,
        handleTutorialClickShowMe,
        isTutorialOrderOptionsMenuOpen,
        handleTutorialOrderOptionsMenuClose,
    } = useViewOrderOptionsTutorial(index);

    return (
        <SharingAPIProvider cid={design.compositeId}>
            <Card
                sx={{
                    display: 'flex', // with display: 'flex', we enforce cards in the same row to have same height. It's a hack, we know, sry 🤕
                    flexDirection: 'column',
                    borderColor: isMobile ? 'transparent' : undefined,
                }}
                selectable
                selected={selected}
                onSelect={handleSelectAndGA}
                ref={setCardElement}
                data-testid="designs card" // used by our playwright tests
            >
                <DesignCardMedia
                    href={isArtProofLabLinkEnabled ? editDesignLink : undefined}
                    cardIndex={index}
                    carouselImages={carouselImages}
                    onSlideClick={handleSlideClick}
                    indicateAction
                />
                {design.mostRecentOrderId && <OverlayChip label="Ordered" data-action="saved designs ordered" />}
                <CardHeader
                    sx={{ [theme.breakpoints.down('md')]: { px: 1, mt: 1 } }}
                    title={
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            width="100%"
                            component={Stack}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                flexWrap: 'wrap',
                                [theme.breakpoints.down('md')]: { fontSize: isMobile2ColumnsView ? '15px' : undefined },
                            }}
                        >
                            {design.designName}
                            <Stack direction="row">
                                <IconButtonBlack
                                    onClick={collaboration ? openSharingDialog : navigateToShare}
                                    data-action="saved designs share" // used by selenium_ui_tests
                                    data-testid="saved designs share" // used by our playwright tests
                                >
                                    <ShareIcon />
                                </IconButtonBlack>
                                <IconButtonBlack
                                    onClick={handleCardMenuClick}
                                    data-action="saved designs card menu" // used by selenium_ui_tests
                                    data-testid="saved designs card menu" // used by our playwright tests
                                >
                                    <MenuDotsHorizontalIcon />
                                </IconButtonBlack>
                            </Stack>
                        </Typography>
                    }
                />
                <CardContent sx={{ flex: '1 0 auto', [theme.breakpoints.down('md')]: { px: 1, mt: 1 } }}>
                    <Stack spacing={2} alignItems="flex-start">
                        <Stack alignItems="flex-start" width="100%">
                            <Typography variant="paragraphSmall">{productName}</Typography>
                            <Typography
                                variant="captionRegular"
                                component={Stack}
                                direction="row"
                                spacing={1}
                                color="grey.700"
                            >
                                {productColor}
                                <DesignPrice cid={design.compositeId} />
                            </Typography>
                        </Stack>
                        {collaboration && <AvatarList onClick={openSharingDialog} />}
                    </Stack>
                </CardContent>
                <CardActions sx={{ gap: 0, [theme.breakpoints.down('md')]: { px: 1, mt: 2 } }}>
                    <Stack spacing={1} width="100%">
                        {isArtProofLabLinkEnabled && (
                            <Button
                                variant="primary"
                                fullWidth
                                onClick={navigateToEdit}
                                href={editDesignLink}
                                data-action="saved designs edit design" // used by selenium_ui_tests
                            >
                                Edit Design
                            </Button>
                        )}
                        <BuyButton
                            design={design}
                            cardIndex={index}
                            handleCloseMenu={handleCloseMenu}
                            showAddToCartSnackbar={showAddToCartSnackbar}
                        />
                        <OrderOptionsButton
                            design={design}
                            isTutorialOrderOptionsMenuOpen={isTutorialOrderOptionsMenuOpen}
                            handleTutorialOrderOptionsMenuClose={handleTutorialOrderOptionsMenuClose}
                        />
                        {!isArtProofLabLinkEnabled && (
                            <Button
                                variant="secondary"
                                fullWidth
                                onClick={navigateToEdit}
                                href={editDesignLink}
                                data-action="saved designs edit design" // used by selenium_ui_tests
                            >
                                Edit Design
                            </Button>
                        )}
                    </Stack>
                </CardActions>
            </Card>
            <CardMenu
                design={design}
                anchorElement={anchorElMenu}
                designSharingStatus={sharingStatus}
                handleClose={handleCloseMenu}
                resourceId={data?.resource?.id}
                onDesignDeleteClick={openDeleteDialog}
            />
            {collaboration && (
                <SharingDialog
                    designName={design.designName}
                    open={sharingDialogVisible}
                    onClose={closeSharingDialog}
                />
            )}
            <DeleteDialog
                name={design.designName}
                cid={design.compositeId}
                open={deleteDialogVisible}
                onClose={closeDeleteDialog}
            />
            {isTutorialShown && (
                <ViewOrderOptionsTutorial
                    open={isTutorialShown}
                    anchorElement={cardElement}
                    handleClose={handleTutorialClose}
                    handleClickShowMe={handleTutorialClickShowMe}
                />
            )}
        </SharingAPIProvider>
    );
}
