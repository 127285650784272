import { useMobileBreakpoint } from '@customink/pigment-react';
import { useCallback } from 'react';

/**
 * See also: the <span> tags with `LP_...` ids in index.html
 */
export const useOpenLivePerson = () => {
    const isMobile = useMobileBreakpoint();

    const openLivePerson = useCallback(() => {
        const element = document
            // these are the wrappers provided by us
            .getElementById(isMobile ? 'LP_DIV_NAUTILUS_1MOBILE' : 'LP_DIV_NAUTILUS_1')
            // this is the content injected by LivePerson
            ?.querySelector('[data-lp-event=click]');
        (element as HTMLSpanElement)?.click();
        return !!element;
    }, [isMobile]);

    const openLivePersonOrContactPage = useCallback(() => {
        if (!openLivePerson()) {
            window.open('https://www.customink.com/contact', '_blank');
        }
    }, [openLivePerson]);

    return { openLivePerson, openLivePersonOrContactPage };
};
