import { DesignStatusEnum, SharingRole } from '~/adapters/services/collaboration/graphqlTypes';
import { LinkSharePermissionOptions, PermissionOptions, SharingStatusType } from './types';

const DesignStatusSharingStatusMapper: Record<DesignStatusEnum, SharingStatusType> = {
    [DesignStatusEnum.Shared]: 'shared',
    [DesignStatusEnum.Approved]: 'approved',
    [DesignStatusEnum.Rejected]: 'rejected',
};

export function designStatusEnumToSharingStatusType(status?: DesignStatusEnum): SharingStatusType {
    const sharingStatus = status && DesignStatusSharingStatusMapper[status];
    return sharingStatus || 'private';
}

export const PermissionMapper: Record<PermissionOptions, SharingRole> = {
    view: SharingRole.Reader,
    comment: SharingRole.Commenter,
    edit: SharingRole.Editor,
};
export function permissionHasRole(role: string): role is PermissionOptions {
    return role in PermissionMapper;
}
export const SharingRoleMapper = (role: SharingRole | null): LinkSharePermissionOptions => {
    if (role === null) {
        return 'noaccess';
    }

    const keys = Object.keys(PermissionMapper) as PermissionOptions[];
    const matchedPermission = keys.find((key) => PermissionMapper[key] === role);
    return matchedPermission ?? 'view'; // fallback if this function was passed Owner or Approver. But these will be handled separately..
};
